import React, {useState, useEffect, useLayoutEffect} from 'react'
import SideBarTables from '../../Components/SideBar/SideBarTables'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query} from "firebase/firestore"
import '../pages.css'
import '../tabelas.css'

import ReactHTMLTableToExcel from "react-html-table-to-excel";

function PagamentoLideres() {
    const [projects, setProjects]=useState([]);
    const [loading, setLoading]=useState(false);
    const [data1, setData1]=useState('')
    const [data2, setData2]=useState('')
    const [analistas, setAnalistas]=useState([])
    const [analistasP, setAnalistasP]=useState([])
        
    const handleClear2=()=>{
      setData1('')
      setData2('')
    }
     useEffect(()=>{
           
      const q = query(projectsCollectionRef);
      setLoading(true);
      const change = onSnapshot(q, snapshot=>{
        setProjects(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
          setLoading(false);
      })
   
      return()=>{change()}
   
   },[]);

   useLayoutEffect(()=>{
    let analistasAndamento =  projects.filter((val)=>{if(val.data.projLideres? val.data.projLideres:''){return val}}).map((i)=>i.data.projLideres.map((i)=>i.name))
        let analistasNomes = analistasAndamento.reduce((list, sub) => list.concat(sub), [])
      
        let analistasemDuplicados = analistasNomes.filter((v1, i1, s1) => {
          return s1.indexOf(v1) === i1;
        });
      
        let filtrarProjetos=(nome)=>{
            
            let listadeprojetos = projects.filter((val)=>{if(val.data.projLideres.map((item)=>item.name).includes(nome)){return val}})

            
            
            let filtrado = listadeprojetos.map((i)=>(
              {
                "projeto":i.data.projName,
                "numero": i.data.projNumber,
                "data":i.data.projStartDate,
                "reais":i.data.projLideres.filter((val)=>{if(val.paymentCurrency.includes("BRL") && val.name.includes(nome)){return val}}).map((item)=>item.paymentValue),
                "dolar":i.data.projLideres.filter((val)=>{if(val.paymentCurrency.includes("USD") && val.name.includes(nome)){return val}}).map((item)=>item.paymentValue)

              }
            ))

            let arrumados = filtrado.sort((e,f)=>{
              if (e.numero > f.numero) {
                return 1;
              }
              if (e.numero < f.numero) {
                return -1;
              }
              return 0;
              })
            
            return arrumados
        }
        
        setAnalistasP(
          analistasemDuplicados.map((item, index)=>(
        {
          "id": index,
          "nome":item,
        "dados":filtrarProjetos(item)
      
      }
      ))
      )

      setAnalistas(analistasP.sort((e,f)=>{
        if (e.nome > f.nome) {
          return 1;
        }
        if (e.nome < f.nome) {
          return -1;
        }
        return 0;
        }))

   },[projects])

   const handleAnalistas = ()=>{
        
        let analistasAndamento =  projects.filter((val)=>{if(val.data.projLideres? val.data.projLideres:''){return val}}).map((i)=>i.data.projLideres.map((i)=>i.name))
        let analistasNomes = analistasAndamento.reduce((list, sub) => list.concat(sub), [])
      
        let analistasemDuplicados = analistasNomes.filter((v1, i1, s1) => {
          return s1.indexOf(v1) === i1;
        });
      
        let filtrarProjetos=(nome)=>{
            
            let listadeprojetos = projects.filter((val)=>{if(val.data.projLideres.map((item)=>item.name).includes(nome)){return val}})

            
            
            let filtrado = listadeprojetos.map((i)=>(
              {
                "projeto":i.data.projName,
                "numero": i.data.projNumber,
                "data":i.data.projStartDate,
                "reais":i.data.projLideres.filter((val)=>{if(val.paymentCurrency.includes("BRL") && val.name.includes(nome)){return val}}).map((item)=>item.paymentValue),
                "dolar":i.data.projLideres.filter((val)=>{if(val.paymentCurrency.includes("USD") && val.name.includes(nome)){return val}}).map((item)=>item.paymentValue)

              }
            ))

            let arrumados = filtrado.sort((e,f)=>{
              if (e.numero > f.numero) {
                return 1;
              }
              if (e.numero < f.numero) {
                return -1;
              }
              return 0;
              })
            
            return arrumados
        }
        
        setAnalistasP(
          analistasemDuplicados.map((item, index)=>(
        {
          "id": index,
          "nome":item,
        "dados":filtrarProjetos(item)
      
      }
      ))
      )

      setAnalistas(analistasP.sort((e,f)=>{
        if (e.nome > f.nome) {
          return 1;
        }
        if (e.nome < f.nome) {
          return -1;
        }
        return 0;
        }))
      setLoading(true)
     
   }


  return (
    <div className='superContainer'>
        <SideBarTables/>
        <div className='pageHeader'>
            <div className="pageTitleLeft" style={{width:'100%'}}>
                <div className="pageTitle"><p>Tabela de Pagamento Lideres De Projeto</p>
            </div>

            <div className="pageTitle">
            
                 <button className="seeClient" onClick={handleAnalistas}>Gerar Tabela de Lideres</button>
            
            </div>

            <div className='pageListSearch' style={{width:'fit-content', justifyContent:'center', alignItems:'center'}}>
              <div>DE: <input type="date" value={data1}  onChange={(e)=>{setData1(e.target.value)}} /></div><div>ATÉ: <input type="date" value={data2}  onChange={(e)=>{setData2(e.target.value)}} /></div>
                    <div className='pageListSearcClear' onClick={handleClear2}><button>X</button></div>
            </div>
                
                <div className="pageTitle">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="seeClientG"
                    table="table-to-xls"
                    filename={`Tabela de Pagamento de Lideres de ${data1!==""?new Date(data1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):""} ate ${data2!==""?new Date(data2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):""}`}
                    sheet="Pagamento Lideres"
                    buttonText="Exportar XLS"
                  />
               </div>
               
            </div>
        </div>
        <div className='pagesContent2'>
        {!loading &&
        <div>Gere a tabela clicando no botão acima</div>
        }
        {loading &&
        <div className="tableContainer">
          <table className='GeneratedTable' id="table-to-xls">
            <tbody>
           {analistas.map((item,index)=>(
            <>
            <tr key={index}>
                <td  colSpan="5" style={{fontWeight:'bold', textAlign:'center'}}>{item.nome}</td>
            </tr>
            <tr>
                <td>Numero Projeto</td>
                <td>Nome Projeto</td>
                <td>Data de Inicio</td>
                <td>Pagamento em USD</td>
                <td>Pagamento em BRL</td>

            </tr>
            {item.dados.filter((val)=>{ if(data1 === '' || data2 === ''){
                      return val 
                  } else if (val.data >= data1 && val.data <= data2){
                      return val
                  }}).map((i)=>(
                <tr>
                    <td>{i.numero}</td>
                    <td>{i.projeto}</td>
                    <td>{new Date(i.data).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</td>
                    <td>{Math.round(i.dolar.reduce((accumulator,value) => accumulator + value,0))}</td>
                    <td>{Math.round(i.reais.reduce((accumulator,value) => accumulator + value,0))}</td>
                </tr>
            ))}
            </>
               
           ))}
           </tbody>
          </table>
          </div>
          }
        </div>
    </div>
  )
}

export default PagamentoLideres