import React, {useState, useEffect} from 'react'
import SideBarTables from '../../Components/SideBar/SideBarTables'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, where} from "firebase/firestore"
import '../pages.css'
import '../tabelas.css'

import ReactHTMLTableToExcel from "react-html-table-to-excel";


function ProjectTable() {
  const hoje = new Date()


    const [projects, setProjects]=useState([]);
    const [loading, setLoading]=useState(false);
    useEffect(()=>{
             
        const q = query(projectsCollectionRef,where ('projStatus1', 'in', ['Em Andamento', 'Em Análise', 'Em Pausa' , 'Entregue',  'Finalizado' ]),orderBy('projNumber', 'desc'));
        setLoading(true);
        const change = onSnapshot(q, snapshot=>{
          setProjects(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
            setLoading(false);
        })
     
        return()=>{change()}
     
     },[]);

    
  return (
    <div className='superContainer'>
        <SideBarTables/>
        <div className='pageHeader'>
            <div className="pageTitleLeft" style={{width:'100%'}}>
                <div className="pageTitle"><p>Tabela de Projetos</p>
                </div>
                <div className="pageTitle">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="seeClientG"
                    table="table-to-xls"
                    filename={`Tabela de Projetos ${new Date(hoje).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`}
                    sheet="Projetos"
                    buttonText="Exportar XLS"
                  />
               </div>
            </div>
        </div>
        <div className='pagesContent2'>
        {loading &&
        <div>Loading...</div>
        }
        {!loading &&
        <div className="tableContainer">
          <table className='GeneratedTable' id="table-to-xls">
            <thead>
              <th>Número</th>
              <th>Ano</th>
              <th>Nome</th>
              <th>Cliente</th>
              <th>País de Contratação</th>
              <th>Jurisdições</th>
              <th>Data Início</th>
              <th>Data Final</th>
              <th>Data intermediária</th>
              <th>Tipo de Intermediária</th>
              <th>Status de Andamento</th>
              <th>Data Entrega</th>
              <th>Comentários</th>
              <th>Moeda de Cobrança</th>
              <th>Valor FInal Total</th>
              <th>N° de Entrevistas</th>
              <th>Tipo de Projeto</th>
              <th>Tipo de CLiente</th>
              <th>Status de Faturamento</th>
              <th>Data Faturamento</th>
              <th>Tipo de Faturamento</th>
              <th>Documento Faturamento</th>
              <th>Data Depósito</th>
              <th>Status Pagamento</th>
              <th>Data Estimada de Pagamento</th>
              <th>Data Efetivada de Pagamento</th>
              <th>Líderes de Projeto</th>
              <th>Analistas</th>
            </thead>
            <tbody>
              {projects.map((item, index)=>(
              <tr key={index}>
                <td>{item.data.projNumber}</td>
                <td>{new Date(item.data.projStartDate).getFullYear()}</td>
                <td>{item.data.projName}</td>
                <td>{item.data.projClient}</td>
                <td>{item.data.projHiringCountry}</td>
                <td>{item.data.projJurisdictions.join('  -  ')}</td>
                <td>{new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</td>
                <td>{new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</td>
                <td>{item.data.projIntermediateDate? new Date(item.data.projIntermediateDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Não Registrado' }</td>
                <td>{item.data.projIntermediateStatus}</td>
                <td>{item.data.projStatus1}</td>
                <td>{item.data.projDeliveryDate? new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Aguardando Entrega'}</td>
                <td>{item.data.projComments}</td>
                <td>{item.data.projCurrency}</td>
                <td>{item.data.projFinalValue}</td>
                <td>{item.data.projSources? item.data.projSources: 'Não Registrado'}</td>
                <td>{item.data.projType=== ''?  'Não Registrado' : item.data.projType=== 'Reputacional'? 'Humint' : item.data.projType=== 'Ivestigação'? 'Documentos' : item.data.projType }</td>
                <td>{item.data.projClientType? item.data.projClientType:'Nao Registrado'}</td>
                <td>{item.data.projeStatus2? item.data.projeStatus2: 'Aguardando Entrega' }</td>
                <td>{item.data.projBillingDate? new Date(item.data.projBillingDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Aguardando Cobrança'}</td>
                <td>{item.data.projClientBillInfo? item.data.projClientBillInfo: 'Nao Registrado' }</td>
                <td>{item.data.projBillingDoc? item.data.projBillingDoc: 'Aguardando Cobrança' }</td>
                <td>{item.data.projBillingPaymentDate? new Date(item.data.projBillingPaymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Aguardando Cobrança'}</td>
                <td>{item.data.projeStatus3? item.data.projeStatus3: 'Aguardando Entrega' }</td>
                <td>{item.data.projPaymentDate? new Date(item.data.projPaymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Aguardando Entrega'}</td>
                <td>{item.data.projPayedDate? new Date(item.data.projPayedDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Aguardando Pagamento'}</td>
                <td>
                {item.data.projLideres.map((i, index)=>(
                  
                  
                  <p key={index}>{i.name} - {i.country} - {i.email} - {i.payment} - {i.paymentCurrency}: ${i.paymentValue? i.paymentValue.toLocaleString('pt-br'): 'Não Registrado' } - {i.paymentStatus? 'Pago': 'A Pagar'} - {i.paymentDate? new Date(i.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'A Pagar'}</p>
                  
                 
                ))}
                </td>
                <td>
                {item.data.projAnalistas.map((i, index)=>(
                  
                  
                  <p key={index}>{i.name} - {i.country} - {i.email} - {i.paymentCurrency}: ${i.payment? Math.round(i.payment).toLocaleString('pt-br'): 'Não Registrado' } - {i.paymentStatus? 'Pago': 'A Pagar'} - {i.paymentDate? new Date(i.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'A Pagar'}</p>
                  
                 
                ))}
                </td>
                
                
              </tr>
              ))}
            </tbody>
          </table>
          </div>
          }
        </div>
    </div>
  )
}

export default ProjectTable