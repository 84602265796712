import React, {useState} from 'react'
import '../pages.css'
import { useNavigate } from 'react-router-dom'
import SideBarUpPages from '../../Components/SideBar/SideBarUpPages'
import {prospectsCollectionRef} from '../../services/fireref'
import { addDoc } from 'firebase/firestore'
import Countries from '../../libs/Countries.json'

function UpProspect() {
  //states para pegr informações
  const [clientName, setClientName]=useState('');
  const [clientCountry, setClientCountry]=useState('');
  const [clientStartYear, setClientStartYear]=useState(new Date().getFullYear().toString());
  const [clientComments, setClientComments]=useState('');
  const [clientWeb, setClientWeb]=useState('')
  // contato states
    
    const [clientContacts, setClientContacts] = useState([])
    const [clientContacID, setClientContacID]=useState('')
    const [clientContactName, setClientContactName] = useState('');
    const [clientContactPhone, setClientContactPhone] = useState('');
    const [clientContactEmail, setClientContactEmail] = useState('');
 // doc states
    
    

 //alertas
    const [alerta, setAlerta]=useState(false);

    const [open, setOpen]=useState(false);
   
     
      
  
  //states upando
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  //upando infos para clientes
  const handleAdd = async(e)=>{
    e.preventDefault();
    setUploading(true);
    await addDoc( prospectsCollectionRef, {
      clientName,
      clientCountry,
      clientStartYear,
      clientComments,
      clientContacts,
      clientWeb
      });
    //navigate('/');

    
    setUploading(false);
    navigate('/prospeccoes')
}
//lidando com contatos
const addContact = (e, name, phone, email)=>{
    e.preventDefault();
    if(clientContactName!=='' && clientContactEmail !=='' && clientContactPhone !== ''){
        setAlerta(false)
        const contact = {
            id: crypto.randomUUID(),
            name,
            email,
            phone
        }
        setClientContacts([...clientContacts, contact])
        setClientContactName('')
        setClientContactPhone('')
        setClientContactEmail('')
    }else{
        setAlerta(true)
    }
    
}


const handleDelete =(e, id)=>{
    e.preventDefault();
    setClientContacts(clientContacts.filter(contact => contact.id !== id))
}


const handleOpen = (e, id, name, phone, email)=>{
    e.preventDefault();
    setClientContacID(id)
    setClientContactName(name)
    setClientContactPhone(phone)
    setClientContactEmail(email)
    setOpen(true)
}



const handleClose =()=>{
    setOpen(false);
    setClientContacID('')
    setClientContactName('')
    setClientContactPhone('')
    setClientContactEmail('')
}


const handleUpdate = (e, clientContacID, clientContactName, clientContactPhone, clientContactEmail)=>{
    e.preventDefault()
    setClientContacts(clientContacts.map(contact=>{
        if(contact.id === clientContacID){
            return{...contact, name:clientContactName, phone:clientContactPhone, email:clientContactEmail}
        }else{
            return contact
        }
    }))
    setOpen(false)
    setClientContacID('')
    setClientContactName('')
    setClientContactPhone('')
    setClientContactEmail('')
}

  return (
    <div className='superContainer'>
      <SideBarUpPages/>
        <div className='pageHeader'>
            <div className="pageTitleCenter">
                <div className="pageTitle">Nova Prospecção</div>
            </div>
        </div>
        <div className="pagesContentup">
          <form className="form900" onSubmit={handleAdd} >
            <div className="linhas">
                <div className='clientName'>
                    <p>Nome:</p>
                    <input type="text" required placeholder='Nome' onChange={(e)=>{setClientName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}}/>
                </div>
                <div className='clientCountry'>
                    <p>País:</p>
                   
                    <select required style={{width:'100%'}} onChange={(e)=>{setClientCountry(e.target.value)}}>
                        <option value="">Selecione</option>
                        {[...Countries].map((item, index)=>(
                        <option value={item.country} key={index} id={item.id}>{item.country}</option>
                        ))}
                    </select>
                   
                </div>
                <div className='clientStat'>
                    <p>Desde:</p>
                    <input type="text"  placeholder='AAAA' defaultValue={clientStartYear} required onChange={(e)=>{setClientStartYear(e.target.value)}}/>
                </div>

                <div className='clientName'>
                    <p>Web Site:</p>
                    <input type="text" placeholder='Web Site' onChange={(e)=>{setClientWeb(e.target.value)}}/>
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Comentários:</p>
                    <textarea id="" rows="6" placeholder='Comentários' onChange={(e)=>{setClientComments(e.target.value)}}></textarea>
                </div>
            </div>
            <div className="linhaCentral">
              <div className="linhasTitle">CONTATOS</div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Nome Contato:</p>
                    <input type="text" placeholder='nome' value={clientContactName} onChange={(e)=>{setClientContactName(e.target.value)}}/>
                </div>
                <div className='clientPhone'>
                    <p>Telefone Contato:</p>
                    <input type="text" placeholder='telefone' value={clientContactPhone} onChange={(e)=>{setClientContactPhone(e.target.value)}}/>
                </div>
                <div className='clientPhone'>
                    <p>Email Contato:</p>
                    <input type="text" placeholder='email' value={clientContactEmail} onChange={(e)=>{setClientContactEmail(e.target.value)}} />
                </div>
                <div className='clientFlex1Button2'><button onClick={(e)=>{addContact(e,clientContactName, clientContactPhone, clientContactEmail)}}>ADICIONAR</button></div>
            </div>
            {alerta &&
            <div className="linhas" style={{color:'#FA9600'}}>Preencha todos os campos para adicionar um contato</div>
            }
            {clientContacts.length>=1 &&  clientContacts.map((item,index) =>(
               <div className="pageListCLientsBLinhas" key={index} id={item.id}>
                        <div className='clientFlex2'>
                            <span>Nome COntato:</span>
                            <p>{item.name}</p>
                        </div>
                        <div className='clientFlex1'>
                            <span>Telefone Contato:</span>
                            <p style={{textTransform:'lowercase'}}>{item.phone}</p>
                        </div>
                        <div className='clientFlex1'>
                            <span>Email Contato:</span>
                            <p style={{textTransform:'lowercase'}}>{item.email}</p>
                        </div>
                        <div className='clientFlex1Button'>
                            <button onClick={(e)=>{handleDelete(e, item.id)}}>APAGAR</button>
                            <button onClick={(e)=>{handleOpen(e, item.id, item.name, item.phone, item.email)}}>EDITAR</button>
                        </div>
                    </div>
            ))}
         
            <input type="submit" value="ADICIONAR PROSPECÇÃO" />
            {uploading &&
                        <span>uploading...</span>
             }
          </form>
        </div>

        {open &&
            <div className="popUp">
                <form className="form900" onSubmit={(e)=>{handleUpdate(e, clientContacID, clientContactName, clientContactPhone, clientContactEmail)}}>
            <div className="linhas">
                <div className='clientName'>
                    <p>Nome Contato:</p>
                    <input type="text" required onChange={(e)=>{setClientContactName(e.target.value)}} defaultValue={clientContactName}/>
                </div>
                <div className='clientName'>
                    <p>Telefone contato:</p>
                    <input type="text" required  onChange={(e)=>{setClientContactPhone(e.target.value)}} defaultValue={clientContactPhone}/>
                </div>
                <div className='clientName'>
                    <p>Email Contato:</p>
                    <input type="text" required onChange={(e)=>{setClientContactEmail(e.target.value)}} defaultValue={clientContactEmail}/>
                </div>
            </div>
            <input className='editValues'  type="submit" value="ATUALIZAR CONTATO" />
            <button className='editValues' onClick={handleClose} >FECHAR</button>
            </form>
            
            </div>
        }
           
      </div>

  )
}

export default UpProspect