import React, {useState} from 'react'
import {useNavigate } from 'react-router-dom'
import SideBarUpPages from '../../Components/SideBar/SideBarUpPages'
import { addDoc, query, onSnapshot, where, orderBy} from "firebase/firestore";
import {clientsCollectionRef, proposalsCollectionRef} from '../../services/fireref'
import Countries from '../../libs/Countries.json'
import '../pages.css'

function UpProposal() {

   // states de loading
    const [loading, setLoading]=useState(false);
    const [uploading, setUploading] = useState(false);
    const navigate = useNavigate();
   //states do projeto
    const [proposalName, setProposalName]=useState('')
    const proposalDate=new Date();
    const [proposalStartDate, setProposalStartDate]=useState('');
    const [propoalHiringCountry, setProposalHiringCountry]=useState('')
    const [proposalComments, setProposalComments]=useState('')
    const [proposalClient, setProposalClient]=useState('')
    const [proposalClientID, setProposalClientID]=useState('')
    const [propoalClientBill, setProposalClientBill]=useState('')
    const [proposalClientBillInfo, setProposalClientBillInfo]=useState('')
    const [novoCliente, setNovoCliente]=useState(true)
    const [clients, setClients]=useState([])


    const OpenClienteNao =(e)=>{
      e.preventDefault()
      setNovoCliente(true)
    }
    const OpenClientExistente =(e)=>{
      e.preventDefault()
      const c = query(clientsCollectionRef,where('clientStatus', '==', 'Ativo'), orderBy('clientName', 'asc'));
      setLoading(true);
    const changeClients = onSnapshot(c, snapshot=>{
      setClients(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
        setLoading(false);
        setNovoCliente(false)
  })

  return()=>{ changeClients()}
    }
    const handleAddClient =(e)=>{
      const index = e.target.selectedIndex;
      const optionElement = e.target.childNodes[index];
      const optionElementId = optionElement.getAttribute('id');
      const optionElementBill = optionElement.getAttribute('billType');
      const optionElementBillInfo = optionElement.getAttribute('billInfo');
    
      setProposalClient(e.target.value);
      setProposalClientID(optionElementId);
      setProposalClientBill(optionElementBill);
      setProposalClientBillInfo(optionElementBillInfo)
    }

   const handleAddProposta =async(e)=>{
    e.preventDefault();
    setUploading(true);
    await addDoc( proposalsCollectionRef, {
      proposalName,
      proposalDate, 
      proposalStartDate,
      propoalHiringCountry,
      proposalComments,
      proposalClient,
      proposalClientID, 
      propoalClientBill, 
      proposalClientBillInfo
      }
    );


    
    setUploading(false);
    navigate('/projetos/propostas')
  }


  return (
    <div className='superContainer'>
        <SideBarUpPages/>
        <div className='pageHeader'>
            <div className="pageTitleCenter">
                <div className="pageTitle">Nova Proposta</div>
            </div>
        </div>
        <div className="pagesContentup">
         
          <form onSubmit={handleAddProposta} className="form600">
              <div className="linhas">
                  <div className="clientName">
                    <p>Nome:</p>
                    <input type="text" required onChange={(e)=>{setProposalName(e.target.value)}} placeholder='Nome' />
                  </div>
              </div>
              <div className="linhas">
                  <div className="clientName">
                    <p>Data :</p>
                    <input type="date" required onChange={(e)=>{setProposalStartDate(e.target.value)}} />
                  </div>
                  <div className="clientName">
                    <p>País de Contratação:</p>
                    <select required onChange={(e)=>{setProposalHiringCountry(e.target.value)}} style={{width:'100%'}}>
                        <option value="">Selecione</option>
                        {[...Countries].map((item, index)=>(
                          <option key={index} value={item.country}>{item.country}</option>
                        ))}
                    </select>
                  </div>
              </div>
              <div className="linhas">
                  <div className="clientName">
                    <p>cLIENTE JÁ CADASTRADO?:</p>
                  </div>
                  <div className="clientName">
                    <button  className='seeClient' onClick={(e)=>{OpenClientExistente(e)}} >SIM</button>
                  </div>
                  <div className="clientName">
                    <button  className='seeClient' onClick={(e)=>{OpenClienteNao(e)}} >NÃO</button>
                  </div>                 
              </div>
              {novoCliente &&
                <div className="linhas">
                  <div className="clientName">
                    <p>Nome do Cliente Não Cadastrado:</p>
                    <input type="text" required onChange={(e)=>{setProposalClient(e.target.value)}} placeholder='Nome' />
                  </div>
                </div>
              }
              {!novoCliente &&

              <div className="linhas">
                   <div className="clientName">
                 <p>Cliente Cadastrado:</p>
                <select required onChange={(e)=>{handleAddClient(e)}} style={{width:'100%'}}>
                  <option value="">Selecione</option>
                  {clients.map((item, index)=>(
                      <option key={index} id={item.id} billType={item.data.clientBillingType} billInfo={item.data.clientBillingInfo} value={item.data.clientName}>{item.data.clientName}</option>
                  ))}
                  
                </select>
                </div>
              </div>
              
              }
              <div className="linhas">
                  <div className="projF1" style={{width:'100%'}}>
                    <p>Comentários:</p>
                    <textarea id="" rows="6" style={{width:'100%'}} placeholder='Comentários' required onChange={(e)=>{setProposalComments(e.target.value)}}></textarea>
                  </div>
            </div>
            <input type="submit" value="ADICIONAR PROPOSTA" />

              {uploading &&
                        <span>uploading...</span>
             }
          </form>
      </div>
  </div>
  )
}

export default UpProposal