import React from 'react'
import { Link } from 'react-router-dom'


function Navigtion() {


  return (

    <div className='SBNav'>
        <div className='SBsectionName'>Geral</div>
        <div className="SBContent">
            <Link to='/'>PROJETOS</Link>
            <Link to='/clientes/ativos'>CLIENTES</Link>
            <Link to='/prospeccoes'>Prospecções</Link>
            <Link to='/analistas'>Analistas</Link>
            <Link to='/fornecedores'>Fornecedores</Link>
            
        </div>
    </div>

  )
}

export default Navigtion