import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../services/AuthContext';
import './login.css'
import Logo from '../../media/logo_branco 2.webp'


function Login() {
  const navigate = useNavigate(); 
  const {signIn, logout} = UserAuth();
  const [email, setEmail]=useState('');
  const [password, setPassword]=useState('');
  const [error, setErrors]=useState('');
  const [notValid, setNotValid]=useState(false);

  const handleLogout = async()=>{
    try{
        await logout();
        navigate('/login')
    }catch(e){
        console.log(e.message)
    }
}

  const handleLogin =async(event)=>{

      event.preventDefault();
      setErrors('');
      try{
          await signIn(email,password);
            if(email !== 'andrezupekan@gmail.com' && email !== 'antonioelias@nexointell.com' && email !== 'luisesnal@nexointell.com'){
                handleLogout();
                navigate("/login");
                setNotValid(true)
            }else{navigate("/"); setNotValid(false)}
          
      }catch(e){
          setErrors(e.message);
        
      }
      
  }




  return (
     <div className="locontainer">
            <div className="loform">
                <div className="loimage">
                    <img src={Logo} alt="logo" width={'100%'} height={'auto'} />
                </div>

                <form onSubmit={handleLogin}>
                    <h3>LOGIN</h3>
                    <p className='label'>e-mail</p>
                    <input type="email" placeholder="email" required onChange={(e)=>setEmail(e.target.value)} style={{backgroundColor:'#fff'}} />

                    <p className='label'>password</p>
                    <input type="password" placeholder="password" required onChange={(e)=>setPassword  (e.target.value)} style={{backgroundColor:'#fff'}}/>
                    <button>Login</button>
                    {error &&
                <div className='loError'>
                    Incorrect username<br/> or password<br/>
                    Try again<br/>
                </div>
                }
                 {notValid &&
                <div className='loError'>
                    ACESS DENIED<br/>
                    You don't have credentials to Sing In on Admin System!<br/>
                </div>
                }
                </form>
                

            </div>
        </div>
  )
}

export default Login