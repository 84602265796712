import React,{useState, useEffect, useRef} from 'react'
import SideBarEmail from '../../Components/SideBar/SideBarEmail'
import {collaboratorsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, where} from "firebase/firestore"
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';



function EmailAnalystBrazil() {
 //states para pegar infos e fazer filtro
 const [collabs, setCollabs]=useState([]);
 const [loading, setLoading]=useState(false);
 const [search, setSearch]=useState('');
 const [serachFilter, setSerachFilter]=useState('Nome');





 //pegando infos cliente
 useEffect(()=>{
       
   const q = query(collaboratorsCollectionRef,where('collabType', '==', 'analista'), where('collabCountry', '==', 'Brasil'), orderBy('collabName', 'asc'));
   setLoading(true);
   const change = onSnapshot(q, snapshot=>{
       setCollabs(snapshot.docs.map(doc=>({
       data:doc.data(),
       id:doc.id
       })))
       setLoading(false);
   })

   return()=>{change()}

 },[]);

 //limpando serch
   const handleClear=()=>{
     setSearch('')
   }

 //setando e desetando enviar para todos
 const [emails, setEmails]=useState([]);
 const [all, setAll]=useState(false)

  const handleAll = ()=>{
    setAll(true)
    setEmails(collabs.map((item) => item.data.collabEmail))
  }
  
  const handleUnAll=()=>{
    setAll(false)
    setEmails([])
  }


const handleEmailChange = (e, item) => {
   setEmails((prevChecked) =>
       e.target.checked
       ? [...prevChecked, item.data.collabEmail]
       : prevChecked.filter((prev) => prev !== item.data.collabEmail)
   );
};

//enviando e-mail

const [sent, setSent]=useState(false);
const [sentMessage, setSentMessage]=useState('');
const [erros, setErro]=useState(false);
const [erroMessage, setErroMessage]=useState('');

const form = useRef();

const sendEmail = (e) => {
   e.preventDefault();

   emailjs.sendForm('service_h089t7s', 'template_wtip3w8', form.current, '6d2CnIbDEyZpCuyyK')
   .then((result) => {
     setSent(true);
     setSentMessage(result.text);
     setEmails([]);
     const inputs = document.querySelectorAll('.formInput');
     inputs.forEach(input => {
       input.value = '';
     });
     setInterval(() => {
       setSent(false)
     }, 3000);
     
 }, (error) => {
     setErro(true)
     setErroMessage(error.text);
 });
};
 return (
  <div className='superContainer'>
  <SideBarEmail/>
  <div className='pageHeader'>
        <div className="pageTitleLeftEmail">
            <div className="pageTitle">Envir email para Analistas - Brasil</div>
            <div className="pagetitleButton">
              <Link to='/email/analistas'>Todos</Link>
              <Link to='/email/analistas/global'>Global</Link>
            </div>

        </div>
        <div className='pageListSearch'>

            <div className='pageListSearchAelect'>
                Filtro: 
                <select  onChange={(e)=>{setSerachFilter(e.target.value)}} >
                <option value="Nome">Nome</option>
                <option value="Categoria">Categoria</option>
                <option value="País">País</option>
                <option value="Desde">Desde</option>
                <option value="Áreas de Atuação">Áreas de Atuação</option>
                </select>
            </div>
            <div className='pageListSearcInput' ><input type="text"  placeholder={`Buscar por ${serachFilter}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} /></div>
            <div className='pageListSearcClear' onClick={handleClear}><button>X</button></div>
        </div>
        <div className='pageListSearch'>
          {!all &&
          <button className='pageListSearchbutton' onClick={handleAll}>Selecionar Todos</button>
          }
          {all &&
          <button className='pageListSearchbutton' onClick={handleUnAll}>Deselecionar Todos</button>
          } 
        </div>

</div>
<div className='pagesContentEmail'>
      {loading &&
      <div className="loading">
          <div className='pageListCLients'>Loading....</div>
      </div>
      
      }
      {collabs.filter((val)=>{
                 if(serachFilter === 'Nome'){
                  if(search === ''){
                    return val
                } else if (val.data.collabName.toLowerCase().includes(search.toLowerCase())){
                    return val
                }
              }
              if(serachFilter === 'Categoria'){
                if(search === ''){
                  return val
              } else if (val.data.collabCategory.toLowerCase().includes(search.toLowerCase())){
                  return val
              }
              }
              if(serachFilter === 'País'){
                if(search === ''){
                  return val
              } else if (val.data.collabCountry.toLowerCase().includes(search.toLowerCase())){
                  return val
              }
              }
              if(serachFilter === 'Desde'){
                if(search === ''){
                  return val
              } else if (val.data.collabStart.toLowerCase().includes(search.toLowerCase())){
                  return val
              }
              }
              if(serachFilter === 'Áreas de Atuação'){
                if(search === ''){
                  return val
              } else if ([val.data.collabJurisdictions].toString().toLowerCase().includes(search.toLowerCase())){
                  return val
              }
              }
              }).map((item, index)=>(
      <div className='pageListEmail' key={index} >
         <input type="checkbox" style={{marginRight:30+'px'}} name={item.data.collabName} value={item.data.collabEmail} onChange={(e) => handleEmailChange(e, item)} checked={emails.includes(item.data.collabEmail)}/>
          <div className='pageListCLientsB listActive'>
                <div className="pageListCLientsA">
                <div className="clientFlex1">
                        <span>Nome:</span>
                        <p><Link to={"/colaborador/"+item.id}>{item.data.collabName}</Link></p>
                      </div>
                      <div className="clientW0">
                        <span>Tipo:</span>
                        <p>{item.data.collabType}</p>
                      </div>
                      <div className="clientW0">
                        <span>Categoria:</span>
                        <p>{item.data.collabCategory}</p>
                      </div>
                      <div className="clientW0">
                        <span>País:</span>
                        <p>{item.data.collabCountry}</p>
                      </div>
                      <div className="clientFlex1">
                        <span>Email:</span>
                        <p style={{textTransform:'lowercase'}}>{item.data.collabEmail}</p>
                      </div>
                      <div className="clientW0">
                        <span>Áreas de Atuação:</span>
                        <p style={{maxWidth: 10+'ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{item.data.collabJurisdictions}</p>
                      </div>
                      <div className="clientW0" style={{width:'fit-content'}}>
                        <span>Desde:</span>
                        <p>{item.data.collabStart}</p>
                      </div>
              </div>
              
          </div>
          
        
      </div>
      ))}
      
      <form ref={form} onSubmit={sendEmail} className='form100' >
          <div className="linhas">
              <div className='clientName'>
                  <p>Assunto:</p>
                  <input type="text" name="subject" className='formInput' required/>
              </div>
          </div>
          <div className="linhas">
              <div className='clientName'>
                  <p>Emails:</p>
                  <input type="text" name="emailsList" required defaultValue={emails.toString()}/>
              </div>
          </div>
          <div className="linhas">
              <div className='clientName'>
                  <p>Título:</p>
                  <input type="text" name="title" className='formInput'/>
              </div>
          </div>
          <div className="linhas">
              <div className='clientName'>
                  <p>Imagem:</p>
                  <input type="text" name="image" className='formInput'/>
              </div>
          </div>
          <div className="linhas">
              <div className='clientName'>
                  <p>Link:</p>
                  <input type="text" name="link" className='formInput'/>
              </div>
          </div>
          <div className="linhas">
              <div className='clientName'>
                  <p>Mensagem:</p>
                  <textarea name="message" className='formInput'  required />
                  
              </div>
          </div>
          <input type="submit" value="ENVIAR" />
          
          {sent &&
        <div className="sentEmail">
          Email enviado com sucesso!<br/>
          Estatus {sentMessage}
        </div>
      }
      {erros &&
         <div className="erroEmail">
          Houve um erro no envio de seu email<br/>
         Estatus {erroMessage}<br/>
         Tente novamente mais tarde
       </div>
      }
      </form>
      <div className="form100">
      <h2 style={{textAlign:'center'}}>Instruções</h2>
          <div>
            <h3>Enviar Imagem</h3>
            <ol>
              <li>Selecione e copie o código abaixo:<br/>
                  &lt;img src="url" width="150px" height="auto" alt="imagem" /&gt;
              </li>
              <li>Cole o código no campo de Imagem</li>
              <li>Torque a palavra url dentro ds aspas pelo link de sua imagem</li>
              <li>Troque a largura da imagem no width pela desejada e mantenha as unidades em px</li>
            </ol>
          </div>
          <div>
            <h3>Enviar Titulo</h3>
            <ol>
              <li>Selecione e copie o código abaixo<br/>
                  &lt;h3 style="color: 3a5373; font-size: 18px; font-family: Arial, Helvetica, sans-serif;"&gt;Title Text&lt;/h3&gt;
              </li>
              <li>Cole no campo de Titulo</li>
              <li>Troque 'Title Text' pelo texto que deseja para o título</li>
            </ol>
          </div>
          <div>
            <h3>Enviar Link</h3>
            <ol>
              <li>Selecione e copie o código abaixo<br/>
              &lt;a href="url" style="color: 3a5373; font-size: 14px; font-family: Arial, Helvetica, sans-serif; text-decoration: none;" target="_blank" rel="noopener noreferrer"&gt;Text Link&lt;/a&gt;
              </li>
              <li>Cole no campo de Link</li>
              <li>Troque a palavra url dentro das aspas pelo link que deseja</li>
              <li>Troque 'Text Link' pelo texto que vai ser visualizado no email, algo como 'See More'</li>
            </ol>
          </div>
          
      </div>
      
     
  </div>
  
</div>


 )
}

export default EmailAnalystBrazil