import React, {useState, useEffect, useLayoutEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SideBarUpPages from '../../Components/SideBar/SideBarUpPages'
import Countries from '../../libs/Countries.json'
import '../pages.css'

//importando collections
import {projectsCollectionRef, clientsCollectionRef, collaboratorsCollectionRef} from '../../services/fireref'
import { db } from '../../libs/firebase'
import { addDoc, onSnapshot, query, doc, getDoc, where, orderBy } from "firebase/firestore";


function UpProjects() {
  // states de loading
  const [loading, setLoading]=useState(false);
  const [uploading, setUploading] = useState(false);
   const navigate = useNavigate();
  //states do projeto
  const [rates, setRates]=useState([]);
  const [clients, setClients]=useState([])
  const [proejcts, setProjects]=useState([])
  const [projName, setProjName]=useState('')
  const [projNumber, setProjNumber]=useState(0);
  const [projStartDate, setProjStartDate]=useState('');
  const [projIntermediateDate, setProjIntermediateDate]=useState('')
  const [projIntermediateStatus, setProjIntermediateStatus]=useState('')
  const [projDeadLine, setProjDeadLine]=useState('')
  const [projStatus1, setProjStatus1]=useState('')
  const projeStatus2=''
  const projeStatus3=''
  const [projHiringCountry, setProjHiringCountry]=useState('')
  const [projJurisdictions, setProjJurisdictions]=useState([])
  const [projComments, setProjComments]=useState('')
  const [juridicoes, setJurisdicoes]=useState('')

  //states de calculadora
  const [projCurrency, setProjeCurrency]=useState('');
  const [projSources, setProjSources]=useState(0);
  const [projClientType, setProjClientType]=useState('');
  const [projType, setProjType]=useState('');
  const [projFinalValue, setProjFinalValue]=useState(0);

   

  //transformando para numeros



 

  //calculando projeto
  

 
  // pegando lista de projetos para setar numero e clientes

useEffect(()=>{

  const q = query(projectsCollectionRef);
  const c = query(clientsCollectionRef,where('clientStatus', '==', 'Ativo'), orderBy('clientName', 'asc'));
  setLoading(true);
  const changeProj = onSnapshot(q, snapshot=>{
    setProjects(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))

  })
  const changeClients = onSnapshot(c, snapshot=>{
    setClients(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))
      setLoading(false);
  })

  return()=>{changeProj(); changeClients()}

},[]);

useLayoutEffect(()=>{
  setProjNumber(proejcts.length+4000)


},[proejcts.length]);

//pegando doc de preços

const docRefdown = doc(db, "rates", "N4vpgUqlpsDNzBXwNS4H");

const pegandoDoc = async()=>{

  try {
    const docSnap = await getDoc(docRefdown);
    if(docSnap.exists()) {
      setRates(docSnap.data());

    } else {
        alert("Document does not exist")
    }
  }
  catch(error) {
   alert(error)
  }
  return(pegandoDoc());

}

useEffect(()=>{
  pegandoDoc();
});


//Pegando cliente e ID dele

const [projClient, setProjClient]=useState('')
const [projClientID, setProjClientID]=useState('')
const [projClientBill, setProjClientBill]=useState('')
const [projClientBillInfo, setProjClientBillInfo]=useState('')



const handleAddClient =(e)=>{
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementId = optionElement.getAttribute('id');
  const optionElementBill = optionElement.getAttribute('billtype');
  const optionElementBillInfo = optionElement.getAttribute('billinfo');

  setProjClient(e.target.value);
  setProjClientID(optionElementId);
  setProjClientBillInfo(optionElementBill);
  setProjClientBill(optionElementBillInfo)
}

//pegando lider de projeto

const [projLider, setProjLider]=useState([])
const [proLiderCountry, setProjLiderCountry]=useState('')
const [projSelectedLider, setProjSelectedLider]=useState('')
const [projSelectedLiderID, setProjSelectedLiderID]=useState('')
const [projSelectedLiderEmail, setProjSelectedLiderEmail]=useState('')
const [projLideres, setProjLideres]=useState([])
const [projLiderPayementCurrency, setprojLiderPaymentCurrency]=useState('')
const [projliderPayment, setProjliderPayment]=useState('')
const [projLiderPaymentValue, setProjLiderPaymentValue]=useState(0)
const [alertaLider, setAlertaLider]=useState(false)


useEffect(()=>{
  const q = query(collaboratorsCollectionRef,
      where('collabCategory', '==', 'Líder de Projeto'),
     orderBy('collabName', 'asc'));

  const change = onSnapshot(q, snapshot=>{
    setProjLider(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))
  })

  return()=>{change()}
},[])

const handlLiderItem = (e)=>{
  e.preventDefault()
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementId = optionElement.getAttribute('id');
  const optionElementEmail = optionElement.getAttribute('email');
  const optionElementCountry = optionElement.getAttribute('country');
  setProjSelectedLider(e.target.value)
  setProjSelectedLiderID(optionElementId)
  setProjSelectedLiderEmail(optionElementEmail)
  setProjLiderCountry(optionElementCountry)
}

const handleLiderPaymentValue =(e)=>{
  e.preventDefault()
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementPg = optionElement.getAttribute('pagamento');
  setProjliderPayment(e.target.value)
  setProjLiderPaymentValue(optionElementPg)
  
}

const now = new Date().toISOString().slice(0, 10)

const handleAddLider = (e)=>{
  e.preventDefault()
  if(proLiderCountry!=='' && projSelectedLider !=='' && projSelectedLiderID !== '' && projliderPayment !== '' && projLiderPayementCurrency !==''){
    setAlertaLider(false)
    const lider = {
        id: crypto.randomUUID(),
        link:projSelectedLiderID,
        name: projSelectedLider,
        country:proLiderCountry,
        payment: projliderPayment,
        paymentValue:projLiderPaymentValue,
        paymentCurrency:projLiderPayementCurrency,
        email:projSelectedLiderEmail,
        paymentStatus: projliderPayment==='Nível 0'? true: false,
        paymentDate: projliderPayment==='Nível 0'? now: ''
    }

    setProjLideres([...projLideres, lider])
    setProjSelectedLider('')
    setProjLiderCountry('')
    const selected2 = document.querySelector('#pagamentoLider');
    selected2.value = '';
    const selected3 = document.querySelector('#moedaLider');
    selected3.value = '';
    setProjSelectedLiderID('')
    setProjliderPayment('')
}else{
    setAlertaLider(true)
}
}
const handleDeleteLider =(e, id)=>{
  e.preventDefault();
  setProjLideres(projLideres.filter(doc => doc.id !== id))
}

//pegando analistas
const [loadingAnalista, setLoadingAnalista]=useState(false)
const [projAnalista, setProjAnalista]=useState([])
const [projAnalistaCountry, setProjAnalistaCountry]=useState('')
const [projAnalistaCateg, setProjAnalistaCateg]=useState('')
const [projAnalistaPaymentCurrency, setprojAnalistaPaymentCurrency]=useState('')
const [projAnalistavalue, setProjAnalistaValue]=useState(0)
const [projSelectedAnalista, setProjSelectedAnalista]=useState('')
const [projSelectedAnalistaID, setProjSelectedAnalistaID]=useState('')
const [projSelectedAnalistaEmail, setProjSelectedAnalistaEmail]=useState('')
const [projAnalistas, setProjAnalistas]=useState([])
const [alertaAnalista, setAlertaAnalista]=useState(false)




const handleSerachAnalista= (e)=>{
  e.preventDefault()
  setLoadingAnalista(true)
  const q = query(collaboratorsCollectionRef,
      where('collabCategory', '==', `${projAnalistaCateg}`),
     orderBy('collabName', 'asc'));

  const change = onSnapshot(q, snapshot=>{
    setProjAnalista(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))
      setLoadingAnalista(false)
  })

  return()=>{change()}
}


const handleAnanlistaItem = (e)=>{
  e.preventDefault()
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementId = optionElement.getAttribute('id');
  const optionElementEmail = optionElement.getAttribute('email');
  const optionElementCountry = optionElement.getAttribute('country');
  setProjSelectedAnalista(e.target.value)
  setProjSelectedAnalistaID(optionElementId)
  setProjSelectedAnalistaEmail(optionElementEmail)
  setProjAnalistaCountry(optionElementCountry)
  console.log(projSelectedAnalista, projSelectedAnalistaID, projAnalistaCateg, projAnalistaCountry, projAnalistaCateg)
}

const handleAddAnalista = (e)=>{
  e.preventDefault()
  if( projSelectedAnalista !=='' && projSelectedAnalistaID !== '' && projAnalistaCateg !== ''  &&  projAnalistavalue !=='' && projAnalistaPaymentCurrency!=='' && projAnalistaCountry !== ''){
    setAlertaAnalista(false)
    const analista = {
        id: crypto.randomUUID() ,
        link:projSelectedAnalistaID,
        name: projSelectedAnalista,
        category:projAnalistaCateg,
        country:projAnalistaCountry,
        email:projSelectedAnalistaEmail,
        paymentCurrency:projAnalistaPaymentCurrency,
        payment: projAnalistavalue,
        paymentStatus: false
    }
    setProjAnalistas([...projAnalistas, analista])
    setProjAnalista([])
    setProjSelectedAnalista('')
    setProjSelectedAnalistaID('')
    setProjAnalistaValue(0)
    setprojAnalistaPaymentCurrency('')
    setProjAnalistaCountry('')
    setProjAnalistaCateg('')
    
    const selected5 = document.querySelector('#moedaAnalista');
    selected5.value = '';
    const selected4 = document.querySelector('#categoriaAnalista');
    selected4.value = '';
    const input2 =document.querySelector('#valorAnalista');
    input2.value=''
    const input3 =document.querySelector('#moedaAnalista');
    input3.defaultValue=''
}else{
  setAlertaAnalista(true)
}
}
const handleDeleteAnalista =(e, id)=>{
  e.preventDefault();
  setProjAnalistas(projAnalistas.filter(doc => doc.id !== id))
}

const [proj2Currency, setProj2Currency]=useState('')
const [projCovertedValue, setProjCovertedValue]=useState(0)

useLayoutEffect(()=>{setProj2Currency(projCurrency === 'BRL'? "USD": "BRL"); console.log(projJurisdictions)},[projCurrency])

const handleAddJusrisd = (e)=>{
  setProjJurisdictions([...projJurisdictions, juridicoes])
  e.preventDefault()
 
}

  //upando projeto
  const handleAddProj =async(e)=>{
    e.preventDefault();
    setUploading(true);
    await addDoc( projectsCollectionRef, {
      projName,
      projNumber,
      projStartDate,
      projIntermediateDate,
      projIntermediateStatus,
      projDeadLine,
      projStatus1,
      projeStatus2,
      projeStatus3,
      projClient,
      projClientID,
      projClientBill,
      projClientBillInfo,
      projHiringCountry,
      projJurisdictions,
      projComments,
      projCurrency,
      projSources,
      projClientType,
      projType,
      projFinalValue,
      projLideres,
      projAnalistas,
      projDeadLineAlert:false,
      projDeadLineAlert2:false,
      projIntStatus:false,
      projCovertedValue,
      proj2Currency
      }
      );


    
    setUploading(false);
    navigate('/')
  }
  
  return (
    <div className='superContainer'>
        <SideBarUpPages/>
        <div className='pageHeader'>
            <div className="pageTitleCenter">
                <div className="pageTitle">Novo Projeto</div>
            </div>
        </div>
        <div className="pagesContentup">
          {loading &&
            <div className="formpProj">Loading...</div>
          }
          {!loading &&
          <form onSubmit={handleAddProj} className="formpProj">
            <div className="linhaCentral">
              <div className="proejctTitle">
                INFORMAÇÕES DO PROJETO
              </div>
            </div>
            <div className="linhaProjeto">
              <div className="projFit">
                <p>Nº Projeto:</p>
                <span>{projNumber}</span>
              </div>
              <div className="projF1">
                <p>Nome:</p>
                <input type="text" required onChange={(e)=>{setProjName(e.target.value)}} placeholder='Nome' />
              </div>
              <div className="projF1">
                <p>Data :</p>
                <input type="date" required onChange={(e)=>{setProjStartDate(e.target.value)}} />
              </div>
              <div className="projF1">
                <p>E. Intermediária:</p>
                <select required onChange={(e)=>{setProjIntermediateStatus(e.target.value)}}>
                  <option value="">Selecione</option>
                  <option value="Sem">Sem Entrega Intermediária</option>
                  <option value="Interna">Interna</option>
                  <option value="Externa">Externa</option>
                </select>
              </div>
              <div className="projF1">
                <p>Data E. Intermediária:</p>
                <input type="date" required={projIntermediateStatus==='Sem'? false:true} onChange={(e)=>{setProjIntermediateDate(e.target.value)}} />
              </div>
              <div className="projF1">
                <p>Data E. Final:</p>
                <input type="date" required onChange={(e)=>{setProjDeadLine(e.target.value)}} />
              </div>
              <div className="projF1">
                <p>Status Inicial:</p>
                <select required onChange={(e)=>{setProjStatus1(e.target.value)}}>
                  <option value="">Selecione</option>
                  <option value="Em Andamento">Em Andamento</option>
                  <option value="Em Análise">Em Análise</option>
                  <option value="Em Pausa">Em Pausa</option>
                </select>
              </div>
              <div className="projF1">
                <p>Cliente:</p>
                <select required onChange={(e)=>{handleAddClient(e)}}>
                  <option value="">Selecione</option>
                  {clients.map((item, index)=>(
                      <option key={index} id={item.id} billtype={item.data.clientBillingType} billinfo={item.data.clientBillingInfo} value={item.data.clientName}>{item.data.clientName}</option>
                  ))}
                  
                </select>
              </div>
              <div className="projF1">
                <p>País de Contratação:</p>
                <select required onChange={(e)=>{setProjHiringCountry(e.target.value)}}>
                    <option value="">Selecione</option>
                    {[...Countries].map((item, index)=>(
                      <option key={index} value={item.country}>{item.country}</option>
                    ))}
                </select>
              </div>
              <div className="projF1">
                <p>Jurisdições:</p>
                <div style={{display:'flex'}}>
                  <select required onChange={(e)=>{setJurisdicoes(e.target.value)}}>
                    <option value="">Selecione</option>
                    {[...Countries].map((item, index)=>(
                      <option key={index} value={item.country}>{item.country}</option>
                    ))}
                </select>
                <button onClick={(e)=>{handleAddJusrisd(e)}}>+</button>
                </div>
                <p>
                <div style={{whiteSpace:'wrap'}}>{projJurisdictions.join('  -  ')}</div>
                </p>
              </div>
            </div>
            <div className="linhas">
              <div className="projF1" style={{width:'100%'}}>
                <p>Comentários:</p>
                <textarea id="" rows="6" style={{width:'100%'}} placeholder='Comentários' required onChange={(e)=>{setProjComments(e.target.value)}}></textarea>
              </div>
            </div>
            <div className="linhaCentral">
              <div className="proejctTitle">
                VALORES
              </div>
            </div>
            <div className="linhas">
                <div className="projF1">
                    <p>Moeda Faturamento:</p>
                    <select required onChange={(e)=>{setProjeCurrency(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value="USD">USD</option>
                      <option value="BRL">BRL</option>
                    </select>
                </div>
                <div className="projF1">
                <p>Valor Faturamento:</p>
                <input type="number"  onChange={(e)=>{setProjFinalValue(parseFloat(e.target.value))}} required />
                </div>
                <div className="projF1">
                <p>Valor Convertido Para {proj2Currency}:</p>
                <input type="number"  onChange={(e)=>{setProjCovertedValue(parseFloat(e.target.value))}} required />
                </div>
                
                <div className="projF1">
                    <p>Nº de Entrevistas:</p>
                    <select onChange={(e)=>{setProjSources(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Tipo do Cliente:</p>
                    <select onChange={(e)=>{setProjClientType(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value='Consultoria Local'>Consultoria Local</option>
                      <option value='Consultoria Global'>Consultoria Global</option>
                      <option value='Cliente Final Local'>Cliente Final Local</option>
                      <option value='Cliente Final Global'>Cliente Final Global</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Tipo do Projeto:</p>
                    <select onChange={(e)=>{setProjType(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value='Humint'>Humint</option>
                      <option value='Documentos'>Documentos</option>
                      <option value='Híbrido'>Híbrido</option>
                    </select>
                </div>
                
               
            </div>

              <div className="linhaCentral">
                <div className="proejctTitle">EQUIPE DE PROJETO</div>
              </div>
              <div className="linhaCalculado">
                <div className="projF1-2">
                    <div className="linhaCentral">Líder de projeto</div>
                    
                    <div className="linhaCalculado">
                    <div className="projF1">
                      <p>Nome:</p>
                      <select onChange={(e)=>{handlLiderItem(e)}}>
                        {
                          <>
                          <option value="">Selecione</option>
                            {projLider.map((item,index)=>(
                              <option key={index} id={item.id} email={item.data.collabEmail} country={item.data.collabCountry} value={item.data.collabName}>{item.data.collabName} - {item.data.collabCountry} </option>
                            ))}
                            </>
                           }
                      </select>
                      </div>
                      <div className="projF1">
                          <p>Moeda:</p>
                          <select  onChange={(e)=>{setprojLiderPaymentCurrency(e.target.value)}} id='moedaLider'  >
                            <option value="">Selecione</option>
                            <option value="USD">USD</option>
                            <option value="BRL">BRL</option>
                          </select>
                        </div>
                      
                        <div className="projF1">
                          <p>Pagamento:</p>
                          <select onChange={(e)=>{handleLiderPaymentValue(e)}} id='pagamentoLider'>
                            <option value="">Selecione</option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? 0 : 0} value="Nível 0">Nível 0</option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel1 : rates.brlNivel1} value="Nível 1">Nível 1 - USD:{rates.usdNivel1} - BRL:{rates.brlNivel1} </option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel2 : rates.brlNivel2}  value="Nível 2">Nível 2 - USD:{rates.usdNivel2} - BRL:{rates.brlNivel2}</option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel3 : rates.brlNivel3} value="Nível 3">Nível 3 - USD:{rates.usdNivel3} - BRL:{rates.brlNivel3}</option>
                          </select>
                        </div>
                        </div>
                        <div className="linhaCalculado">
                        <div className="projF1" style={{justifyContent:'flex-end'}}>
                        <button className='seeClient' onClick={handleAddLider}>Adicionar</button>
                        </div>
                      </div>
                      {alertaLider &&
                        <div className="linhaCentral">Preencha todos os campos para adicionar um Líder de Projeto</div>
                      }

                      
                      {projLideres.length>=1 &&  projLideres.map((item,index) =>(
                        <div className="linhaCalculado" key={index} id={item.id}>
                          <div className='clientFlex1'>
                              <span>Nome:</span>
                              <p><Link to={"/colaborador/"+item.link}>{item.name}</Link></p>
                          </div>
                          <div className='clientFlex1'>
                              <span>País:</span>
                              <p>{item.country}</p>
                          </div>
                          <div className='clientFlex1'>
                              <span>Pagamento:</span>
                              <p>{item.payment}</p>
                          </div>
                          <div className='clientFlex1Button'>
                              <button onClick={(e)=>{handleDeleteLider(e, item.id)}}>APAGAR</button>
                          </div>
                    </div>
                      ))}

                    
                </div>
                <div className="projF1-2">
                    <div className="linhaCentral">Analistas</div>
                    <div className="linhaCalculado">
                    <div className="projF1">
                      <p>Categoria:</p>
                      <select onChange={(e)=>{setProjAnalistaCateg(e.target.value)}} id='categoriaAnalista'>
                        <option value="">Selecione</option>
                        <option value="Pesquisador">Pesquisador</option>
                        <option value="Pesquisador de Documentos">Pesquisador de Documentos</option>
                        <option value="Segurança">Segurança</option>
                        <option value="Tradutor/Revisor">Tradutor/Revisor</option>
                      </select>
                    </div>
                    <div className="projF2" style={{justifyContent:'flex-end'}}>
                      <button className='seeClient' onClick={(e)=>{handleSerachAnalista(e)}}>
                        BUSCAR
                      </button>
                    </div>
                    <div className="projF1">
                        <p>Nome:<span style={{color:'#FA9600'}}>{loadingAnalista? 'AGUARDE! FAZENDO A BUSCA':''}</span></p>
                        <select onChange={(e)=>{handleAnanlistaItem(e)}}>
                          {projAnalistaCateg && projAnalistaCateg ?
                            <>
                            <option value="">Selecione</option>
                              {projAnalista.map((item,index)=>(
                                <option key={index} id={item.id} country={item.data.collabCountry} email={item.data.collabEmail}  value={item.data.collabName}>{item.data.collabName}-{item.data.collabCountry}</option>
                              ))}
                              </>
                              :  <option value="">Selecione uma categoria a busca</option>}
                        </select>
                        </div>
                    
                    </div>
                    <div className="linhaCalculado">
                      
                      </div>
                      <div className="linhaCalculado">
                      <div className="projF1">
                      <p>Moeda:</p>
                          <select onChange={(e)=>{setprojAnalistaPaymentCurrency(e.target.value)}} id='moedaAnalista'>
                            <option value="">Selecione</option>
                            <option value="USD">USD</option>
                            <option value="BRL">BRL</option>
                          </select>
                        </div>
                        <div className="projF1">
                          <p>Valor:</p>
                         <span style={{display:'flex'}}> {projAnalistaPaymentCurrency? projAnalistaPaymentCurrency: '-'}:<input style={{width:'100%'}} type="number" id='valorAnalista' onChange={(e)=>{setProjAnalistaValue(parseFloat(e.target.value))}} defaultValue={projAnalistavalue?  `${Math.round(projAnalistavalue).toLocaleString('pt-br')}`: ''} /></span>
    
                        </div>
                      </div>
                      <div className="linhaCalculado">
                        <div className="projF1" style={{justifyContent:'flex-end'}}>
                        <button className='seeClient' onClick={handleAddAnalista}>Adicionar</button>
                        </div>
                      </div>
                      
                      {alertaAnalista &&
                        <div className="linhaCentral">Preencha todos os campos para adicionar um Analista</div>
                      }

                    
                      {projAnalistas.length>=1 &&  projAnalistas.map((item,index) =>(
                        <div className="linhaCalculado" key={index} id={item.id}>
                          <div className='clientFlex1'>
                              <span>Nome:</span>
                              <p><Link to={"/colaborador/"+item.link}>{item.name}</Link></p>
                          </div>
                          <div className='clientFlex1'>
                              <span>Categoria:</span>
                              <p>{item.category}</p>
                          </div>
                          <div className='clientFlex1'>
                              <span>País:</span>
                              <p>{item.country}</p>
                          </div>
                          <div className='clientFlex1'>
                              <span>Pagamento:</span>
                              <p>{item.paymentCurrency}: {item.payment.toLocaleString('pt-br')}</p>
                          </div>
                          <div className='clientFlex1Button'>
                              <button onClick={(e)=>{handleDeleteAnalista(e, item.id)}}>APAGAR</button>
                          </div>
                    </div>
                      ))}
                      
                </div>
              </div>
              <input type="submit" value="ADICIONAR PROJETO" />

              {uploading &&
                        <span>uploading...</span>
             }
            
          </form>
          }
        </div>
        
    </div>

  )
}
export default UpProjects