import React, {useState, useEffect} from 'react'
import SideBarTables from '../../Components/SideBar/SideBarTables'
import {collaboratorsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, where} from "firebase/firestore"
import '../pages.css'
import '../tabelas.css'
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function AnalistsTable() {
  const hoje = new Date()

  const [colaborador, setColaborador]=useState([]);
  const [loading, setLoading]=useState(false);
  useEffect(()=>{
           
      const q = query(collaboratorsCollectionRef,where ('collabType', '==', 'analista'), orderBy('collabName', 'asc'));
      setLoading(true);
      const change = onSnapshot(q, snapshot=>{
        setColaborador(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
          setLoading(false);
      })
   
      return()=>{change()}
   
   },[]);

  return (
    <div className='superContainer'>
        <SideBarTables/>
        <div className='pageHeader'>
            <div className="pageTitleLeft" style={{width:'100%'}}>
                <div className="pageTitle"><p>Tabela de Analistas</p>
                </div>
                <div className="pageTitle">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="seeClientG"
                    table="table-to-xls"
                    filename={`Tabela de Analistas ${new Date(hoje).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`}
                    sheet="Analistas"
                    buttonText="Exportar XLS"
                  />
               </div>
            </div>
        </div>
        
        <div className='pagesContent2'>
        {loading &&
        <div>Loading...</div>
        }
        {!loading &&
        <div className="tableContainer">
          <table className='GeneratedTable' id="table-to-xls">
            <thead>
              <th>Nome</th>
              <th>Tipo</th>
              <th>Categoria</th>
              <th>País</th>
              <th>Profissão</th>
              <th>Desde</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Referência/Origem</th>
              <th>Comentários</th>
              <th>Dados Pagamento</th>
              <th>Tem NDA?</th>
              <th>Data de expiração</th>
              <th>Link do Documento</th>
              <th>Tem Contrato?</th>
              <th>Data de expiração</th>
              <th>Link do Documento</th>
            </thead>
            <tbody>
              {colaborador.map((item, index)=>(
              <tr key={index}>
                <td>{item.data.collabName}</td>
                <td>{item.data.collabType}</td>
                <td>{item.data.collabCategory}</td>
                <td>{item.data.collabCountry}</td>
                <td>{item.data.collabJurisdictions? item.data.collabJurisdictions.join(' - '):''}</td>
                <td>{item.data.collabStart}</td>
                <td>{item.data.collabEmail}</td>
                <td>{item.data.collabPhone? item.data.collabPhone: 'Não Registrado' }</td>
                <td>{item.data.collabRecomendation}</td>
                <td>{item.data.collabComments}</td>
                <td>{item.data.collabAccount}</td>
                <td>{item.data.collabNDA? 'SIM': 'NÃO'}</td>
                <td>{item.data.collabNDADate? item.data.collabNDADate.toDate().toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10):''}</td>
                <td>{item.data.collabNDALink}</td>
                <td>{item.data.collabContract? 'SIM': 'NÃO'}</td>
                <td>{item.data.collabContractDate? item.data.collabContractDate.toDate().toLocaleString('pt-BR', {timeZone: 'UTC'}).substr(0, 10):''}</td>
                <td>{item.data.collabContractLink}</td>
              
              </tr>
              ))}
            </tbody>
          </table>
          </div>
          }
        </div>
    </div>
  )
}

export default AnalistsTable