import React from 'react'
import {UserAuth} from '../../services/AuthContext'
import {useNavigate} from 'react-router-dom';


function Logout() {
    const navigate = useNavigate();
    const{logout} = UserAuth();
    const handleLogout = async()=>{
        try{
            await logout();
            navigate('/login')
        }catch(e){
            console.log(e.message)
        }
    }

  return (

    <div className='SBLogout'>
        <button onClick={handleLogout}>logout</button>
    </div>
    
  )
}

export default Logout