import React,{useState, useEffect, useLayoutEffect} from 'react'
import SideBarProjects from '../../Components/SideBar/SideBarProjects'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, doc, where, updateDoc} from "firebase/firestore"
import { db } from '../../libs/firebase';
import { Link } from 'react-router-dom';
import Open from '../../media/open.webp'

function Recived() {
        //abrindo acordeon
   const [open, setopen]=useState(false)
   const handleOpen = (id)=>{
     setopen(id)
   }
 
   const hancleClose = ()=>{
     setopen(!open)
   }
 

   //states para pegar infos e fazer filtro
   const [projects, setProjects]=useState([]);
   const [loading, setLoading]=useState(false);
   const [search, setSearch]=useState('');
   const [serachFilter, setSerachFilter]=useState('Busca Múltipla');
   const [reais, setReais]=useState([]);
   const [dolar, setDolar]=useState([])


 //pegando infos projeto
 useEffect(()=>{
       
   const q = query(projectsCollectionRef,where('projeStatus2', '==', 'Recebido'), orderBy('projNumber', 'asc'));
   setLoading(true);
   const change = onSnapshot(q, snapshot=>{
     setProjects(snapshot.docs.map(doc=>({
       data:doc.data(),
       id:doc.id
       })))
       setLoading(false);
   })

   return()=>{change()}

},[]);

useLayoutEffect(()=>{
  let reaislist = projects.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  length = reaislist.length;
  var reaisNumeros = [];
  for (var i = 0; i < length; i++)
  reaisNumeros.push(Number(reaislist[i]))

  let dolarlist =projects.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthD = dolarlist.length;
  var dolarNumeros = [];
  for (var a = 0; a < lengthD; a++)
  dolarNumeros.push(Number(dolarlist[a]))

  setReais(reaisNumeros)
  setDolar(dolarNumeros)

},[projects])

//limpando serch
 const handleClear=()=>{
   setSearch('')
 }

  //mudando estatus do projeto

 
 const handleChangeStatus2 = async (e, id)=>{
  e.preventDefault()
     const analystDoc = doc(db, 'projects', id)
     const newField = ({projeStatus2: 'A Receber', projBillingPaymentDate:''})
     await updateDoc(analystDoc , newField)
 }

 const [openDelete, setOpenDelete]=useState(false)
 const [deleting, setDeleting]=useState(false)
  const handleOpenDelete=()=>{
   setOpenDelete(true)
}

const handleCloseDelete =()=>{
 setOpenDelete(false)
}

const handleDeleProject =async(e, id)=>{
 e.preventDefault()
 const analystDoc = doc(db, 'projects', id)
 const newField = ({projeStatus2: 'Finalizado'})
 await updateDoc(analystDoc , newField)
setOpenDelete(false)
 } 
 const [data1, setData1]=useState('')
 const [data2, setData2]=useState('')
   
   const handleClear2=()=>{
     setData1('')
     setData2('')
   }

  return (
    <div className='superContainer'>
        <SideBarProjects/>
        <div className='pageHeader'>
              <div className="pageTitleLeft" style={{width:'100%'}}>
                  <div className="pageTitle">PROJETOS: DEPOSITADOS - valores totais - BRL: ${isNaN(reais.reduce((acc, curr) => acc + curr, 0))? 0: reais.reduce((acc, curr) => acc + curr, 0).toLocaleString('pt-BR')} - USD: ${isNaN(dolar.reduce((acc, curr) => acc + curr, 0))? 0: dolar.reduce((acc, curr) => acc + curr, 0).toLocaleString('pt-BR')}</div>
              </div>
              <div className='pageListSearch' style={{width:'100%'}}>

                <div className='pageListSearchAelect'>
                    FIltro: 
                    <select  onChange={(e)=>{setSerachFilter(e.target.value)}} >
                    <option value="Busca Múltipla">Busca Múltipla</option>
                      <option value="Nome">Nome</option>
                      <option value="Numero">Numero</option>
                      <option value="Ano">Ano</option>
                      <option value="Data Início">Data Início</option>
                      <option value="Data Final">Data Final</option>
                      <option value="Data Entrega">Data Entrega</option>
                      <option value="Cliente">Cliente</option>
                      <option value="País de Contratação">País de Contratação</option>
                      <option value="Jurisdições">Jurisdições</option>
                    </select>
                </div>
                {serachFilter !== 'Data Início' && serachFilter !== 'Data Final' && serachFilter !== 'Data Entrega' ? 
                    <>
                    <div className='pageListSearcInput' ><input type="text"  placeholder={`Buca por ${serachFilter}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} /></div>
                    <div className='pageListSearcClear' onClick={handleClear}><button>X</button></div>
                    
                    {serachFilter === 'Busca Múltipla'? 
                    <div  className='pageListSearcInput2'>Busca em Comentátios, País de Contratação, Jurisdições, Analistas e Lideres de Projeto</div>
                    :''
                    }
                    </>
                    :
                    <>
                    <div>DE: <input type="date" value={data1}  onChange={(e)=>{setData1(e.target.value)}} /></div><div>ATÉ: <input type="date" value={data2}  onChange={(e)=>{setData2(e.target.value)}} /></div>
                    <div className='pageListSearcClear' onClick={handleClear2}><button>X</button></div>
                    </>
                  }
              </div>
      </div>
            <div className='pagesContent'>
              {loading &&
              <div className="loading">
                  <div className='pageListProjects'>Loading....</div>
              </div>
              
              }
               {projects.filter((val)=>{
                 if(serachFilter === 'Busca Múltipla'){
                  if(search === ''){
                    return val 
                } else if (val.data.projHiringCountry.toLowerCase().includes(search.toLowerCase()) || [val.data.projJurisdictions].toString().toLowerCase().includes(search.toLowerCase())){
                    return val
                }
              }
              if(serachFilter === 'Data Entrega'){
                if(data1 === '' || data2 ===''){
                  return val 
              } else if (val.data.projDeliveryDate>= data1 && val.data.projDeliveryDate <= data2){
                  return val
              }
              }
              if(serachFilter === 'Data Final'){
                if(data1 === '' || data2 ===''){
                  return val 
              } else if (val.data.projDeadLine>= data1 && val.data.projDeadLine <= data2){
                  return val
              }
              }
                 if(serachFilter === 'Data Início'){
                  if(data1 === '' || data2 === ''){
                    return val 
                } else if (val.data.projStartDate >= data1 && val.data.projStartDate <= data2){
                    return val
                }
              }
              if(serachFilter === 'Ano'){
                if(search === ''){
                  return val 
              } else if (new Date(val.data.projStartDate).getFullYear().toString().includes(search.toString())){
                  return val
              }
            }
                       if(serachFilter === 'Nome'){
                            if(search === ''){
                              return val
                          } else if (val.data.projName.toLowerCase().includes(search.toLowerCase())){
                              return val
                          }
                        }
                        if(serachFilter === 'Numero'){
                          if(search === ''){
                            return val
                        } else if (val.data.projNumber.toString().includes(search)){
                            return val
                        }
                        }
                        if(serachFilter === 'Cliente'){
                          if(search === ''){
                            return val
                        } else if (val.data.projClient.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'País de Contratação'){
                              if(search === ''){
                                return val
                            } else if (val.data.projHiringCountry.toLowerCase().includes(search.toLowerCase())){
                                return val
                            }
                        }
                        if(serachFilter === 'Jurisdições'){
                          if(search === ''){
                            return val
                        } else if ([val.data.projJurisdictions].toString().toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                      }).map((item, index)=>(
                    <div className='pageListProjects' key={index} >
                        <div className='pageListCLientsA'>
                            <div className="clientFit">
                              <span>Ano:</span>
                              <p>{new Date(item.data.projStartDate).getFullYear()}</p>
                            </div>
                            <div className="clientFit">
                              <span>Numero:</span>
                              <p style={{textAlign:'center', width:'100%'}}>{item.data.projNumber}</p>
                            </div>
                            <div className="clientFlex1" style={{overflow:'hidden'}}>
                              <span>Nome:</span>
                              <p style={{whiteSpace:'nowrap'}}>{item.data.projName}</p>
                            </div>
                            <div className="clientFlex1" style={{overflow:'hidden'}}>
                              <span>Cliente:</span>
                              <p style={{whiteSpace:'nowrap'}}><Link to={'/cliente/'+item.data.projClientID}>{item.data.projClient}</Link></p>
                            </div>
                            <div className="clientFit">
                              <span>Data Início:</span>
                              <p>{new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                            </div>
                            <div className="clientFit">
                              <span>Data Final:</span>
                              <p>{new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                            </div>
                            <div className="clientFit">
                              <span>Data Entrega:</span>
                              <p>{new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                            </div>
                            <div className="clientFit">
                              <span>Status de Andamento:</span>
                              <p>{item.data.projStatus1}</p>
                            </div>
                            
                            <div className='openClientContent'>
                              {open === item.id? 
                              <button className='closeClient' onClick={hancleClose}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                              :
                              <button className='openClient' onClick={()=>(handleOpen(item.id))}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                              }
                            </div>
                      </div>
                      <div className={open === item.id? 'pageListCLientsB listActive' : 'pageListCLientsB' }>
                      <div className='pageListCLientsBLinhas'>
                            <div className="clientFlex1">
                              <span>Status de Faturamento:</span>
                              <p>{item.data.projeStatus2}</p>
                            </div>
      
                            <div className="clientFlex1">
                              <span>Data Cobrança:</span>
                              <p>{new Date(item.data.projBillingDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                            </div>
                            <div className="clientFlex1">
                              <span>Data Recebimento:</span>
                              <p>{new Date(item.data.projBillingPaymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                            </div>
                      </div>
                        <div className='pageListCLientsBLinhas'>
                            <div className="clientFlex1">
                                <span>País de contratação:</span>
                                <p>{item.data.projHiringCountry}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Juridições:</span>
                                <p>{item.data.projJurisdictions.join('  -  ')}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Valor Final Total:</span>
                                <p>{item.data.projCurrency}: ${Math.round(item.data.projFinalValue).toLocaleString('pt-br')}</p>
                            </div>
                            
                        </div>
                        <div className='pageListCLientsBLinhas'>
                              <div className='clientFlexTitle'>Detalhes de Faturamento</div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                            <div className='clientFlex1'>
                                <div className='analistasFlex1'>
                                    <div className='pageListCLientsBLinhas'>
                                        <div className="clientFlex1">
                                            <span>Tipo de Faturamento:</span>
                                            <p>{item.data.projClientBillInfo}</p>
                                        </div>
                                    </div>
                                    <div className='pageListCLientsBLinhas'>
                                        <div className="clientFlex1">
                                            <span>{item.data.projClientBillInfo} N°:</span>
                                            <p>{item.data.projBillingDoc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='clientFlex1'>
                                <div className='analistasFlex1'>
                                        <div className='pageListCLientsBLinhas'>
                                            <div className="clientFlex1">
                                            <span>Informações de cobrança:</span>
                                            <pre className='clientFlexBoxPre'  style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.projClientBill}</pre>
                                            </div>
                                        </div>
                                </div>
                            </div>

                        </div>
                       
                        <button style={{fontSize:15+'px'}} className='seeClient' onClick={(e)=>{handleChangeStatus2(e, item.id)}}>VOLTAR PARA A RECEBER</button>
                        
                        <Link to={"/projeto/"+item.id} className='seeClient'>VER Projeto</Link>
                        <button className='editValues' onClick={handleOpenDelete}>FINALIZAR ANDAMENTO DO PROJETO</button>
                          {openDelete &&
                          <div className="popUpDelete">
                          <div className="deleteAviso">
                              <div>Você realmente quer finalizar o processo de faturamento desse Projeto? <br/>Ele só será visto em todos e<br/> em Finalizado após serem finalizados os processos de andamento e pagamento</div>
                              <div className='deleteButtons'>
                                  <button onClick={handleCloseDelete} className='deleteNao'>❌ Não</button>
                                  <button onClick={(e)=>{handleDeleProject(e, item.id)}} className='deleteSim'>✔️ Sim</button>
                              </div> 
                               
                              {deleting &&
                              <div>Apagando Cliente</div>
                              }
                            </div>
                          
                        </div>
                          } 
                       
                      </div>
                        
                    </div>
              ))}
       </div>
     
  </div>
  )
}

export default Recived