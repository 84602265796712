import React from 'react'
import './unfound.css'
import Logo from '../../media/logo_branco 2.webp'
import { Link, useNavigate } from 'react-router-dom'

function Unfound() {
    const navigate = useNavigate()
  return (
    <div className="unContainer">
        <div className="unContent">
            <div className="unLogo"><img src={Logo} alt="Logo" width={'100%'} height={'auto'}/></div>
            <div className="unInfo">
                <div className="unIcon">
                    <div className="speech">404</div>
                </div>
                <div className="unText">
                    <div className="untext-text">
                    Pagina não Encontrada<br/>
                    Volta para a <Link  onClick={navigate(-1)} className='unLink'>Página Anterior</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Unfound