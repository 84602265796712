import React, {useState, useEffect} from 'react'
import SideBarTables from '../../Components/SideBar/SideBarTables'
import {clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query} from "firebase/firestore"
import '../pages.css'
import '../tabelas.css'
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function ClientsTable() {

  const hoje = new Date()
    const [clientes, setClientes]=useState([]);
    const [loading, setLoading]=useState(false);
    useEffect(()=>{
             
        const q = query(clientsCollectionRef,orderBy('clientName', 'asc'));
        setLoading(true);
        const change = onSnapshot(q, snapshot=>{
          setClientes(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
            setLoading(false);
        })
     
        return()=>{change()}
     
     },[]);


  return (
    <div className='superContainer'>
        <SideBarTables/>
        <div className='pageHeader'>
            <div className="pageTitleLeft" style={{width:'100%'}}>
                <div className="pageTitle"><p>Tabela de Clientes</p>
                </div>
                <div className="pageTitle">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="seeClientG"
                    table="table-to-xls"
                    filename={`Tabela de Clientes ${new Date(hoje).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`}
                    sheet="Clientes"
                    buttonText="Exportar XLS"
                  />
                
               </div>
            </div>
        </div>
        <div className='pagesContent2'>
        {loading &&
        <div>Loading...</div>
        }
        {!loading &&
        <div className="tableContainer">
          <table className='GeneratedTable'  id="table-to-xls">
            <thead>
              <th>Nome</th>
              <th>País</th>
              <th>Status</th>
              <th>Desde</th>
              <th>Web Site</th>
              <th>Contatos</th>
              <th>Documentos</th>
              <th>Comentários</th>
              <th>Faturamaneto</th>
              <th>Detalhes Faturamento</th>
            </thead>
            <tbody>
              {clientes.map((item, index)=>(
              <tr key={index}>
                <td>{item.data.clientName}</td>
                <td>{item.data.clientCountry}</td>
                <td>{item.data.clientStatus}</td>
                <td>{item.data.clientStartYear}</td>
                <td>{item.data.clientWeb? item.data.clientWeb :'Não Registrado'}</td>
                <td>
                {item.data.clientContacts? item.data.clientContacts.map((i, index)=>(
                  <p key={index}>{i.name} - {i.phone} - {i.email}</p> 
                ))
                : 'Não Registrado'
              }
                </td>
                <td>
                {item.data.clientDocs? item.data.clientDocs.map((i, index)=>(
                  
                  <p key={index}>{i.name} - {i.link}</p>
                ))
                : 'Não regstrado'
              }
                </td>
                <td>{item.data.clientComments}</td>
                <td>{item.data.clientBillingType}</td>
                <td>{item.data.clientBillingInfo}</td>
              </tr>
              ))}
            </tbody>
          </table>
          </div>
          }
        </div>
    </div>
  )
}

export default ClientsTable