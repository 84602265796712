import React,  { useEffect, useState } from 'react';
import './partials.css'

function Clock() {

    //time zones
    const times = [
        {name: 'Lisboa', timeZ:'Europe/Lisbon'},
        {name:'Buenos Aires', timeZ:'America/Argentina/Buenos_Aires'},
        {name: 'São Paulo', timeZ:'America/Sao_Paulo'},
        {name: 'Rio de Janeiro', timeZ:'America/Sao_Paulo'},
        {name: 'Santiago', timeZ:'America/Santiago'},
        {name: 'Bogotá', timeZ:'America/Bogota'},
        {name:'San José', timeZ:'America/Costa_Rica'},
        {name:'Quito', timeZ:'America/Bogota'},
        {name:"Ciudad de Mexico", timeZ:'America/Mexico_City'},
        {name:"Asunción", timeZ:'America/Asuncion'},
        {name:"Lima", timeZ:'America/Lima'},
        {name:"Madrid", timeZ:'Europe/Madrid'},
        {name:"New York", timeZ:'America/New_York'},
        {name:"Los Angeles", timeZ:'America/Los_Angeles'},
        {name:"Caracas", timeZ:'America/Caracas'}
    ];

    //states para visualização

    const [clockZone, setClockZone]=useState('Europe/Lisbon');
    const [time, setTime]=useState(new Date());
   
    //efeito para atualizar
    useEffect(()=>{
        const timer = setInterval(()=>{
            setTime(new Date())
        },60000);
        return()=>{
            clearInterval(timer);
        }
    },[]);

    //função para visualizar horario formatada
    const fomatedTime = time.toLocaleTimeString('en-US', {
        timeZone:clockZone,
        hour: '2-digit',
        minute: '2-digit',
    })


 


  return (

    <div className='SBclock'>
        <div className='SBsectionName'>FUSOS HORÁRIOS</div>
        <div className="SBClockContent">
            <div className='SBclockTimeDisplay'>{fomatedTime}</div>
                <select className='SBclockCityDisplay' name="clock" id="clock" onChange={e => setClockZone(e.target.value)}>
                    {times.map((item,index)=>(
                        <option key={index} value={item.timeZ}>{item.name}</option>
                    ))}
                </select>
            </div>
    </div>

  )
}

export default Clock