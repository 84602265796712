import React, {useState, useEffect} from 'react'
import {  useParams} from 'react-router-dom';
import { db } from '../../libs/firebase';
import { doc, getDoc,  query, onSnapshot, orderBy} from "firebase/firestore"
import SideBarTables from '../../Components/SideBar/SideBarTables'
import {projectsCollectionRef} from '../../services/fireref';
import '../pages.css'
import '../tabelas.css'
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function ToPayTable() {
  
  const { id } = useParams();
     const [collab, setCollab]=useState([]);
     const docRefdown = doc(db, 'collaborators', id);
     const [projects, setProjects]=useState([]);
    const [loading, setLoading]=useState(false);
    const hoje = new Date()
 
     const pegandoDoc = async()=>{
       try {
         const docSnap = await getDoc(docRefdown);
         if(docSnap.exists()) {
           setCollab(docSnap.data());
         } else {
             alert("Document does not exist")
         }
       }
       catch(error) {
        alert(error)
       }
       return(pegandoDoc());
     }
     useEffect(()=>{
       pegandoDoc();
     });
     useEffect(()=>{
              
      const p = query(projectsCollectionRef,orderBy('projNumber', 'desc'));
      setLoading(true);
      const change = onSnapshot(p, snapshot=>{
        setProjects(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
          setLoading(false);
      })
    
      return()=>{change()}
    
    },[]);
  return (
    <div className='superContainer'>
        <SideBarTables/>
        <div className='pageHeader'>
            <div className="pageTitleLeft" style={{width:'100%'}}>
                <div className="pageTitle"><p>Tabela de Projetos A Pagar {collab.collabName} - Categoria: {collab.collabCategory}</p>
                </div>
                <div className="pageTitle">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="seeClientG"
                    table="table-to-xls"
                    filename={`Projetos A Pagar Colaborador ${collab.collabName} - ${new Date(hoje).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`}
                    sheet="A pPagar"
                    buttonText="Exportar XLS"
                  />
                
               </div>
            </div>
        </div>
        {loading &&
        <div className='pagesContent2'>Loading....</div>
        }
        {!loading &&
        <div className='pagesContent2'>
        {collab.collabType === 'analista' && collab.collabCategory!== 'Líder de Projeto' &&
          <div className="tableContainer">
               <table className='GeneratedTable' id="table-to-xls">
                  <thead>
                    <th>Nome Projeto</th>
                    <th>Status do projeto</th>
                    <th>Data Inicio</th>
                    <th>Data Final</th>
                    <th>Data Entrega</th>
                    <th>Valor BRL</th>
                    <th>Valor USD</th>
                  </thead>
                  <tbody>
                  {projects.filter((val)=>{
                            if(val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()) && val.data.projAnalistas.map((item)=>item.paymentStatus).includes(false):'' 
                            ){
                              return val
                            }
                          }).map((item, index)=>(item.data.projAnalistas.filter((val)=>{if(
                              val.link === id && val.paymentStatus === false
                          ){return val}}).map((its)=>(
                            <tr key={index}>
                              
                              <td>{item.data.projName}</td>
                              <td>{item.data.projStatus1}</td>
                              <td>{item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</td>
                              <td>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</td>
                              <td>{item.data.projDeliveryDate? new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</td>
                              <td>{its.paymentCurrency === 'BRL'? Math.round(its.payment):''}</td>
                              <td>{its.paymentCurrency === 'USD'? Math.round(its.payment):''}</td>
                              
                            </tr>
                            ))))}
                  </tbody>
              </table>
          </div>

        }
        {/* ------------  LIDER DE PROJETO*/}
        {collab.collabType === 'analista' && collab.collabCategory=== 'Líder de Projeto' &&
           <div className="tableContainer">
           <table className='GeneratedTable' id="table-to-xls">
              <thead>
                <th>Nome Projeto</th>
                <th>Status do projeto</th>
                <th>Data Inicio</th>
                <th>Data Final</th>
                <th>Data Entrega</th>
                <th>Valor BRL</th>
                <th>Valor USD</th>
              </thead>
              <tbody>
              {projects.filter((val)=>{
                        if(val.data.projLideres? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()) && val.data.projLideres.map((item)=>item.paymentStatus).includes(false):'' 
                        ){
                          return val
                        }
                      }).map((item, index)=>(item.data.projLideres.filter((val)=>{if(
                          val.link === id && val.paymentStatus === false
                      ){return val}}).map((its)=>(
                        <tr key={index}>
                          
                          <td>{item.data.projName}</td>
                          <td>{item.data.projStatus1}</td>
                          <td>{item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</td>
                          <td>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</td>
                          <td>{item.data.projDeliveryDate? new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</td>
                          <td>{its.paymentCurrency === 'BRL'? Math.round(its.paymentValue):''}</td>
                          <td>{its.paymentCurrency === 'USD'? Math.round(its.paymentValue):''}</td>
                          
                        </tr>
                        ))))}
              </tbody>
          </table>
      </div>

        }
        </div>
        }
    </div>
  )
}

export default ToPayTable