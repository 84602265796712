import React from 'react'
import { Link } from 'react-router-dom'


function Create() {


  return (

    <div className='SBNav'>
        <div className='SBsectionName'>criar</div>
        <div className="SBContent">
            <Link to='/novoprojeto'>novo PROJETO</Link>
            <Link to='/novaproposta'>nova proposta</Link>
            <Link to='/novocliente'>novo cliente</Link>
            <Link to='/novaprospeccao'>nova Prospecção</Link>
            <Link to='/novocolaborador'>novo colaborador</Link>
        </div>

    </div>

  )
}

export default Create