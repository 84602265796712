import React, {useState, useEffect} from 'react'
import {  useParams} from 'react-router-dom';
import { db } from '../../libs/firebase';
import { doc, getDoc,  query, onSnapshot, orderBy, where} from "firebase/firestore"
import SideBarTables from '../../Components/SideBar/SideBarTables'
import {projectsCollectionRef} from '../../services/fireref';
import '../pages.css'
import '../tabelas.css'
import ReactHTMLTableToExcel from "react-html-table-to-excel";


function ClientsProjectsTable() {

  const { id } = useParams();
     const [client, setClient]=useState([]);
     const docRefdown = doc(db, 'clients', id);
     const [projects, setProjects]=useState([]);
    const [loading, setLoading]=useState(false);
    const hoje = new Date()
 
     const pegandoDoc = async()=>{
       try {
         const docSnap = await getDoc(docRefdown);
         if(docSnap.exists()) {
            setClient(docSnap.data());
         } else {
             alert("Document does not exist")
         }
       }
       catch(error) {
        alert(error)
       }
       return(pegandoDoc());
     }
     useEffect(()=>{
       pegandoDoc();
     });
     useEffect(()=>{
        
        const q = query(projectsCollectionRef,where('projClientID', '==', `${id}`),where ('projStatus1', 'in', ['Em Andamento', 'Em Análise', 'Em Pausa' , 'Entregue',  'Finalizado' ]), orderBy('projNumber', 'desc'));
        
        const change = onSnapshot(q, snapshot=>{
          setProjects(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
           
        })
    
        return()=>{change()}
    
    },[]);
  return (
    <div className='superContainer'>
        <SideBarTables/>
        <div className='pageHeader'>
            <div className="pageTitleLeft" style={{width:'100%'}}>
                <div className="pageTitle"><p>Projetos Cliente {client.clientName}</p>
                </div>
                <div className="pageTitle">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="seeClientG"
                    table="table-to-xls"
                    filename={`Projetos Cliente ${client.clientName} - ${new Date(hoje).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`}
                    sheet="Projetos Cliente"
                    buttonText="Exportar XLS"
                  />
               </div>
            </div>
        </div>
        {loading &&
        <div className='pagesContent2'>Loading....</div>
        }
        {!loading &&
        <div className='pagesContent2'>
       
          <div className="tableContainer">
               <table className='GeneratedTable' id="table-to-xls">
                  <thead>
                    <th>Nome Projeto</th>
                    <th>Status do projeto</th>
                    <th>Data Inicio</th>
                    <th>Data Final</th>
                    <th>Data Entrega</th>
                    <th>Valor BRL</th>
                    <th>Valor USD</th>
                    <th>Valor EUR</th>
                  </thead>
                  <tbody>
                  {projects.map((item, index)=>(
                            <tr key={index}>
                              
                              <td>{item.data.projName}</td>
                              <td>{item.data.projStatus1}</td>
                              <td>{item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</td>
                              <td>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</td>
                              <td>{item.data.projDeliveryDate? new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</td>
                              <td>{item.data.projCurrency === 'BRL'? Math.round(item.data.projFinalValue):''}</td>
                              <td>{item.data.projCurrency === 'USD'? Math.round(item.data.projFinalValue):''}</td>
                              <td>{item.data.projCurrency === 'EUR'? Math.round(item.data.projFinalValue):''}</td>
                            </tr>
                        ))}
                  </tbody>
              </table>
          </div>
          </div>

        }
      
        </div>

  )
}

export default ClientsProjectsTable