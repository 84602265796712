import React,{useState, useEffect, useLayoutEffect} from 'react'
import SideBarProjects from '../../Components/SideBar/SideBarProjects'
//importando collections
import { clientsCollectionRef, collaboratorsCollectionRef} from '../../services/fireref'
import { db } from '../../libs/firebase'
import {  onSnapshot, query, doc, getDoc, where, orderBy , updateDoc} from "firebase/firestore";
import { Link, useParams} from 'react-router-dom';
import Countries from '../../libs/Countries.json'

function Project() {

    //pegando doc
    
    const [loading, setLoading]=useState(false)
    const { id } = useParams();
    const [project, setProject]=useState([]);
    const docRefdown = doc(db, 'projects', id);

    const pegandoDoc = async()=>{
      try {
        const docSnap = await getDoc(docRefdown);
        if(docSnap.exists()) {
            setProject(docSnap.data());
        } else {
            alert("Esse Projeto Não Existe")
        }
      }
      catch(error) {
       alert(error)
      }
      return(pegandoDoc());
    }
    useEffect(()=>{
      pegandoDoc();
      setLoading(false)
    },[]);


    // editando informações
    const [openInfo, setOpenInfo]=useState(false)
    const [clients, setClients]=useState([])
    const [projName, setProjName]=useState('')
    const [projClient, setProjClient]=useState('')
    const [projHiringCountry, setProjHiringCountry]=useState('')
    const [projJurisdictions, setProjJurisdictions]=useState([])
    const [projStartDate, setProjStartDate]=useState('');
    const [projIntermediateDate, setProjIntermediateDate]=useState('')
    const [projIntermediateStatus, setProjIntermediateStatus]=useState('')
    const [projDeadLine, setProjDeadLine]=useState('')
    const [projComments, setProjComments]=useState('')


   const handleEditInfo =(e)=>{
    e.preventDefault()
    setProjName(project.projName)
    setProjClient(project.projClient)
    setProjHiringCountry(project.projHiringCountry)
    setProjJurisdictions(project.projJurisdictions)
    setProjStartDate(project.projStartDate)
    setProjIntermediateDate(project.projIntermediateDate)
    setProjIntermediateStatus(project.projIntermediateStatus)
    setProjDeadLine(project.projDeadLine)
    setProjComments(project.projComments)
    setProjStartDate(project.projStartDate)
    setProjIntermediateDate(project.projIntermediateDate)
    setProjIntermediateStatus(project.projIntermediateStatus)
    setProjDeadLine(project.projDeadLine)
    setProjClientID(project.projClientID)
    setProjClientBill(project.projClientBill)
    setProjClientBillInfo(project.projClientBillInfo)
    setOpenInfo(true)

    const c = query(clientsCollectionRef);
    const changeClients = onSnapshot(c, snapshot=>{
      setClients(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
        setLoading(false);
    })
  
    return()=>{changeClients()}

   }
    const handleCloseInfo =(e)=>{
      e.preventDefault()
      setProjName('')
    setProjClient('')
    setProjHiringCountry('')
    setProjJurisdictions([])
    setProjStartDate('')
    setProjIntermediateDate('')
    setProjIntermediateStatus('')
    setProjDeadLine('')
    setProjComments('')
    setProjStartDate('')
    setProjIntermediateDate('')
    setProjIntermediateStatus('')
    setProjDeadLine('')
    setProjClientID('')
    setProjClientBill('')
    setProjClientBillInfo('')
      setOpenInfo(false)
    }
    const [projClientID, setProjClientID]=useState('')
  const [projClientBill, setProjClientBill]=useState('')
  const [projClientBillInfo, setProjClientBillInfo]=useState('')



  const handleAddClient =(e)=>{
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementId = optionElement.getAttribute('id');
  const optionElementBill = optionElement.getAttribute('billType');
  const optionElementBillInfo = optionElement.getAttribute('billInfo');

  setProjClient(e.target.value);
  setProjClientID(optionElementId);
  setProjClientBillInfo(optionElementBill);
  setProjClientBill(optionElementBillInfo)
}

  const [uploading, setUploading] = useState(false);
  const [updated, setUpdated]=useState(false);
  const [erros, setErros]=useState(false);
  const [erro, setErro]=useState('');
const handleUpdateInfo = async (e)=>{
  e.preventDefault();
  setUploading(true);
  const docRef = doc(db, "projects", id);
  const data ={
    projName,
      projStartDate,
      projIntermediateDate,
      projIntermediateStatus,
      projDeadLine,
      projClient,
      projClientID,
      projClientBill,
      projClientBillInfo,
      projHiringCountry,
      projJurisdictions,
      projComments,
  };
  setUploading(false);
  await updateDoc(docRef, data, { merge:true })
  .then(docRef => { setUpdated(true) })
  .catch(error => { setErros(true); setErro(error); })
 
  setUpdated(false);
  setProjName('')
    setProjClient('')
    setProjHiringCountry('')
    setProjJurisdictions([])
    setProjStartDate('')
    setProjIntermediateDate('')
    setProjIntermediateStatus('')
    setProjDeadLine('')
    setProjComments('')
    setProjStartDate('')
    setProjIntermediateDate('')
    setProjIntermediateStatus('')
    setProjDeadLine('')
    setProjClientID('')
    setProjClientBill('')
    setProjClientBillInfo('')
      setOpenInfo(false)
  
}
//edit lider
const [openLider, setOpenLider]=useState(false)
const [projLideres, setProjLideres]=useState([])

const handleEditLider =(e)=>{
  e.preventDefault()
  setProjLideres(project.projLideres)
  setOpenLider(true)
}

const handleCloseLider =(e)=>{
  e.preventDefault()
  setOpenLider(false)
  setProjLideres([])
}
const [projLider, setProjLider]=useState([])
const [proLiderCountry, setProjLiderCountry]=useState('')
const [projSelectedLider, setProjSelectedLider]=useState('')
const [projSelectedLiderID, setProjSelectedLiderID]=useState('')
const [projSelectedLiderEmail, setProjSelectedLiderEmail]=useState('')
const [projliderPayment, setProjliderPayment]=useState('')
const [projLiderPayementCurrency, setprojLiderPaymentCurrency]=useState('')
const [projLiderPaymentValue, setProjLiderPaymentValue]=useState(0)
const [alertaLider, setAlertaLider]=useState(false)



useEffect(()=>{
  const q = query(collaboratorsCollectionRef,
      where('collabCategory', '==', 'Líder de Projeto'),
     orderBy('collabName', 'asc'));

  const change = onSnapshot(q, snapshot=>{
    setProjLider(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))
  })

  return()=>{change()}
},[])

const handleLiderPaymentValue =(e)=>{
  e.preventDefault()
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementPg = optionElement.getAttribute('pagamento');
  setProjliderPayment(e.target.value)
  setProjLiderPaymentValue(optionElementPg)
}



const handlLiderItem = (e)=>{
  e.preventDefault()
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementId = optionElement.getAttribute('id');
  const optionElementEmail = optionElement.getAttribute('email');
  const optionElementCountry = optionElement.getAttribute('country');
  setProjSelectedLider(e.target.value)
  setProjSelectedLiderID(optionElementId)
  setProjSelectedLiderEmail(optionElementEmail)
  setProjLiderCountry(optionElementCountry)
  
}
const now = new Date().toISOString().slice(0, 10)

const handleAddLider = (e)=>{
  e.preventDefault()
  if(proLiderCountry!=='' && projSelectedLider !=='' && projSelectedLiderID !== '' && projliderPayment !== ''){
    setAlertaLider(false)
    const lider = {
      id: crypto.randomUUID(),
      link:projSelectedLiderID,
      name: projSelectedLider,
      country:proLiderCountry,
      payment: projliderPayment,
      paymentValue:projLiderPaymentValue,
      paymentCurrency:projLiderPayementCurrency,
      email:projSelectedLiderEmail,
      paymentStatus: projLiderPaymentValue==='Nível 0'? true: false,
      paymentDate: projliderPayment==='Nível 0'? now: ''
    }
    setProjLideres([...projLideres, lider])

    setProjSelectedLider('')
    setProjLiderCountry('')
    
    const selected0 = document.querySelector('#lidernome');
    selected0.value = '';
    const selected = document.querySelector('#moedaLider');
    selected.value = '';
    const selected2 = document.querySelector('#pagamentoLider');
    selected2.value = '';
    setProjSelectedLiderID('')
    setProjliderPayment('')
}else{
    setAlertaLider(true)
}
}
const handleDeleteLider =(e, id)=>{
  e.preventDefault();
  setProjLideres(projLideres.filter(doc => doc.id !== id))
}
const[liderId, setLiderId]=useState('')
const [liderName, setliderName]=useState('')
const [liderPayment, setLiderPayment]=useState('')
const [liderPaymentDate, setLiderPaymentDate]=useState('')
const [openLider2, setopenLider2]=useState(false)

const handleEditLider2=(e, id, name, payment, paymentValue, paymentCurrency, paymentDate)=>{
  e.preventDefault()
  setLiderId(id)
  setliderName(name)
  setLiderPayment(payment)
  setProjLiderPaymentValue(paymentValue)
  setprojLiderPaymentCurrency(paymentCurrency)
  setLiderPaymentDate(paymentDate? paymentDate:'')
  setopenLider2(true)
}
const handleCloseLider2=(e)=>{
  e.preventDefault()
  setLiderId('')
  setliderName('')
  setLiderPayment('')
  setProjLiderPaymentValue(0)
  setopenLider2(false)
}
const handleLiderPaymentValue2 =(e)=>{
  e.preventDefault()
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementPg = optionElement.getAttribute('pagamento');
  setLiderPayment(e.target.value)
  setProjLiderPaymentValue(optionElementPg)
}
const updateLider=(e, id)=>{
  e.preventDefault()
  setProjLideres(projLideres.map(contact=>{
      if(contact.id === id){
          return{...contact, payment:liderPayment, paymentValue:projLiderPaymentValue, paymentCurrency:projLiderPayementCurrency, paymentDate:liderPaymentDate}
      }else{
          return contact
      }
  }))
  setopenLider2(false)
  setLiderId('')
  setliderName('')
  setLiderPayment('')
  setProjLiderPaymentValue(0)
}
const handleUpdateLider= async (e)=>{
  e.preventDefault();
  setUploading(true);
  const docRef = doc(db, "projects", id);
  const data ={
    projLideres
  };
  setUploading(false);
  await updateDoc(docRef, data, { merge:true })
  .then(docRef => { setUpdated(true) })
  .catch(error => { setErros(true); setErro(error); })
  setOpenLider(false)
  setUpdated(false);
  setProjLideres([])
  
  
}
const handleAddAnalista = (e)=>{
  e.preventDefault()
  if(projAnalistaPaymentCurrency!== '' && projAnalistaCountry!=='' && projSelectedAnalista !=='' && projSelectedAnalistaID !== '' && projAnalistaCateg !== '' &&  projAnalistavalue !==''){
    setAlertaAnalista(false)
    const analista = {
        id: crypto.randomUUID() ,
        link:projSelectedAnalistaID,
        name: projSelectedAnalista,
        category:projAnalistaCateg,
        country:projAnalistaCountry,
        email:projSelectedAnalistaEmail,
        paymentCurrency:projAnalistaPaymentCurrency,
        payment: projAnalistavalue,
        paymentStatus: projliderPayment==='Nível 0'? true: false
    }
    setProjAnalistas([...projAnalistas, analista])
    setProjAnalista([])
    setProjSelectedAnalista('')
    setProjSelectedAnalistaID('')
    setProjAnalistaValue(0)
  
    setProjAnalistaCountry('')
    setProjAnalistaCateg('')
    
    const selected10 = document.querySelector('#moedaAnalista');
    selected10.value = '';
    const selected9 = document.querySelector('#analistavalor');
    selected9.value = '';
    const selected4 = document.querySelector('#categoriaAnalista');
    selected4.value = '';
    
}else{
  setAlertaAnalista(true)
}
}
const handleDeleteAnalista =(e, id)=>{
  e.preventDefault();
  setProjAnalistas(projAnalistas.filter(doc => doc.id !== id))
}

///////////edit analistas
const [loadingAnalista, setLoadingAnalista]=useState(false)
const [projAnalista, setProjAnalista]=useState([])
const [projAnalistaCountry, setProjAnalistaCountry]=useState('')
const [projAnalistaCateg, setProjAnalistaCateg]=useState('')
const [projAnalistavalue, setProjAnalistaValue]=useState(0)
const [projSelectedAnalista, setProjSelectedAnalista]=useState('')
const [projSelectedAnalistaID, setProjSelectedAnalistaID]=useState('')
const [projSelectedAnalistaEmail, setProjSelectedAnalistaEmail]=useState('')
const [projAnalistas, setProjAnalistas]=useState([])
const [alertaAnalista, setAlertaAnalista]=useState(false)
const [openAnalistas, setOpenAnalistas]=useState(false)
const [openAnalista, setOpenAnalista]=useState(false)
const [projAnalistaPaymentCurrency, setprojAnalistaPaymentCurrency]=useState('')


const handleEditAnalistas =(e)=>{
  e.preventDefault()
  setOpenAnalistas(true)
  setProjAnalistas(project.projAnalistas)
}

const handleCloseAnalista =(e)=>{
  e.preventDefault()
  setOpenAnalistas(false)
  setProjAnalistas([])
}

const searchAnalista = (e)=>{
  e.preventDefault()
  setLoadingAnalista(true)
  const q = query(collaboratorsCollectionRef,
      where('collabCategory', '==', `${projAnalistaCateg}`),

     orderBy('collabName', 'asc'));

  const change = onSnapshot(q, snapshot=>{
    setProjAnalista(snapshot.docs.map(doc=>({
      data:doc.data(),
      id:doc.id
      })))
      setLoadingAnalista(false)
  })

  return()=>{change()}
}
const handleAnanlistaItem = (e)=>{
  e.preventDefault()
  const index = e.target.selectedIndex;
  const optionElement = e.target.childNodes[index];
  const optionElementId = optionElement.getAttribute('id');
  const optionElementEmail = optionElement.getAttribute('email');
  const optionElementCountry = optionElement.getAttribute('country');
  setProjSelectedAnalista(e.target.value)
  setProjSelectedAnalistaID(optionElementId)
  setProjSelectedAnalistaEmail(optionElementEmail)
  setProjAnalistaCountry(optionElementCountry)
}

const[analistaId, setAnalistaId]=useState('')
const [analistaName, setAnalistaName]=useState('')
const [analistaPayment, setAnalistapayment]=useState(0)
const [analistaPaymentDate, setAnalistaPaymentDate]=useState('')
const [analistaCurrency, setAnalistaCurrency]=useState('')
const handleEditAnalista2=(e, id, name, payment,currency, paymentDate)=>{
  e.preventDefault()
  setAnalistaId(id)
  setAnalistaName(name)
  setAnalistapayment(payment)
  setAnalistaCurrency(currency)
  setAnalistaPaymentDate(paymentDate? paymentDate : '')
  setOpenAnalista(true)
}

const handlecloseAnalista2=(e)=>{
  e.preventDefault()
  setOpenAnalista(false)
}



const updateAnalista=(e, id, newpayment, analistaPaymentDate, currency)=>{
  e.preventDefault()
  setProjAnalistas(projAnalistas.map(contact=>{
      if(contact.id === id){
          return{...contact, payment:newpayment, paymentDate:analistaPaymentDate, paymentCurrency:currency }
      }else{
          return contact
      }
  }))
  setOpenAnalista(false)
  setLiderId('')
  setAnalistaName('')
  setAnalistaPaymentDate('')
  setAnalistapayment(0)
}

const handleUpdateAnalistas= async (e)=>{
  e.preventDefault();
  setUploading(true);
  const docRef = doc(db, "projects", id);
  const data ={
    projAnalistas
  };
  setUploading(false);
  await updateDoc(docRef, data, { merge:true })
  .then(docRef => { setUpdated(true) })
  .catch(error => { setErros(true); setErro(error); })
 
  setUpdated(false);
  setProjAnalistas([])
 setOpenAnalistas(false)
  
}

/////////////CALCULADORAAAA


const [projCurrency, setProjeCurrency]=useState('');

  const [projSources, setProjSources]=useState(0);
  const [projClientType, setProjClientType]=useState('');
  const [projType, setProjType]=useState('');
  const [resultados, setResultados]=useState(false)
  const [projFinalValue, setProjFinalValue]=useState(0);
  const [proj2Currency, setProj2Currency]=useState('')
const [projCovertedValue, setProjCovertedValue]=useState(0) 

useLayoutEffect(()=>{setProj2Currency(projCurrency === 'BRL'? "USD": "BRL")},[projCurrency])
  
const handleEditCalc =(e)=>{
  e.preventDefault()
  
  setProjeCurrency(project.projCurrency? project.projCurrency:'')
  
  setProjSources(project.projSources? project.projSources:0)
 
  setProjClientType(project.projClientType? project.projClientType:'')
  setProjType(project.projType? project.projType:'')

  setProjFinalValue(project.projFinalValue? project.projFinalValue:0)
  setProjCovertedValue(project.projCovertedValue? project.projCovertedValue:0)
  setProj2Currency(project.projCurrency === 'BRL'? "USD": "BRL")
  setResultados(true)
}



const colseRECalc=(e)=>{
  e.preventDefault()
  setResultados(false)
}

//transformando para numeros


//setando maincurrency e secondary e determinando valor de entrevista pelo numero

const docRefdown2 = doc(db, "rates", "N4vpgUqlpsDNzBXwNS4H");

const pegandoDoc2 = async()=>{

  try {
    const docSnap = await getDoc(docRefdown2);
    if(docSnap.exists()) {
      setRates(docSnap.data());

    } else {
        alert("Document does not exist")
    }
  }
  catch(error) {
   alert(error)
  }
  return(pegandoDoc2());

}

useEffect(()=>{
  pegandoDoc2();
});
const [rates, setRates]=useState([]);

  //setando maincurrency e secondary e determinando valor de entrevista pelo numero
  
const handleUpdateCalc= async (e)=>{
  e.preventDefault();
  setUploading(true);
  const docRef = doc(db, "projects", id);
  const data ={
    projCurrency,
    projSources,
    projClientType,
    projType,
    projFinalValue,
    proj2Currency,
    projCovertedValue

  };
  setUploading(false);
  await updateDoc(docRef, data, { merge:true })
  .then(docRef => { setUpdated(true) })
  .catch(error => { setErros(true); setErro(error); })
 
  setUpdated(false);
  setProjeCurrency('')
  setProjSources('')
  setProjClientType('')
  setProjType('')
  setProjFinalValue('')
  setProjCovertedValue('') 
  setProj2Currency('')
  setResultados(false)
}



  return (
    <div className='superContainer'>
        <SideBarProjects/>
        {loading &&
          <div>Carregando..</div>
        }
        <div className='pageHeader'>
              <div className="pageTitleLeft" style={{width:'100%'}}>
                  <div className="pageTitle">PROJETO:&nbsp;&nbsp;&nbsp;<span>{project.projName}</span></div>
              </div>
      </div>
      {!loading &&
      <div className="pagesContentup">
 
        <div className='pageListProjects'>
              {project.projClientID===''? 
              <div className="linhaCentral">
              <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px', backgroundColor:'#710606', color:'#fff'}}>CLIENTE NÃO CADASTRADO!</div>
            </div>
            :''
            }
              {project.projFinalValue? 
              ''
            :<div className="linhaCentral">
            <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px',backgroundColor:'#710606', color:'#fff'}}>CÁCULO DE PROJETO NÃO CADASTRADO!</div>
            </div>
            }
            {project.projLideres && project.projLideres.length>0?
              ''
              :<div className="linhaCentral">
              <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px',backgroundColor:'#710606', color:'#fff'}}>LÍDERES DE PROJETO NÃO CADASTRADOS!</div>
              </div>
            }
            {project.projAnalistas && project.projAnalistas.length>0?
              ''
              :<div className="linhaCentral">
              <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px',backgroundColor:'#710606', color:'#fff'}}>ANALISTAS NÃO CADASTRADOS!</div>
              </div>
            }
              <div className="linhaCentral">
                <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px'}}>INFORMAÇÕES GERAIS</div>
              </div>
                <div className='pageListCLientsA'>
                    <div className="clientFit">
                        <span>Ano:</span>
                        <p>{project.projStartDate? new Date(project.projStartDate).getFullYear():''}</p>
                    </div>
                    <div className="clientFit">
                        <span>Numero:</span>
                        <p style={{textAlign:'center', width:'100%'}}>{project.projNumber? project.projNumber:''}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Nome:</span>
                        <p>{project.projName? project.projName:''}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Cliente:</span>
                        <p>{project.projClient? <Link to={'/cliente/'+project.projClientID}>{project.projClient}</Link>:''}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>País de contratação:</span>
                        <p>{project.projHiringCountry? project.projHiringCountry:''}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Juridições:</span>
                        <p>{project.projJurisdictions? project.projJurisdictions.join('  -  '):''}</p>
                    </div>
                    
                    
                
                </div>
                <div className={'pageListCLientsB listActive'}>
                      <div className='pageListCLientsBLinhas'>
                            <div className="clientFlex1">
                                    <span>Data Início:</span>
                                    <p>{project.projStartDate? new Date(project.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                </div>
                            <div className="clientFlex1">
                                <span>Data Intermediária:</span>
                                <p>{project.projIntermediateDate? new Date(project.projIntermediateDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Sem Intemediária'}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Intermediária:</span>
                                <p>{project.projIntermediateStatus==='Sem'? 'Sem Intemediária': project.projIntermediateStatus}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Data Final:</span>
                                <p>{project.projDeadLine? new Date(project.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Status Andamento:</span>
                                <p>{project.projStatus1? project.projStatus1:''}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Data Entrega:</span>
                                <p>{project.projDeliveryDate? new Date(project.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</p>
                            </div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                            <div className='clientFlex1' style={{border:0}}>
                              <div className='clientFlexTitle'>Comentários</div>
                              <div className='clientFlexBox' style={{height:'100%'}}>
                                <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{project.projComments?project.projComments:''}</pre>
                              </div>
                            </div>
                        </div>
                        <div className="linhaCentral">
                          <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px'}}>INFORMAÇÕES DE CALCULO</div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                           
                            <div className="clientFlex1">
                                <span>Valor Final:</span>
                                <p>{project.projCurrency? project.projCurrency:''}: ${project.projFinalValue? Math.round(project.projFinalValue).toLocaleString('pt-br'):''}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Valor Convertido:</span>
                                <p>{project.proj2Currency? project.proj2Currency:''}: ${project.projCovertedValue? Math.round(project.projCovertedValue).toLocaleString('pt-br'):''}</p>
                            </div>
                           
                            <div className="clientFlex1">
                                <span>Entrevistas:</span>
                                <p>{project.projSources? project.projSources: 'Não Registrado'}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Tipo de Projeto:</span>
                                <p>{project.projType=== ''?  'Não Registrado' : project.projType=== 'Reputacional'? 'Humint' : project.projType=== 'Ivestigação'? 'Documentos' : project.projType }</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Tipo de Cliente:</span>
                                <p>{project.projClientType? project.projClientType: 'Não Registrado'}</p>
                            </div>
                            
                        </div>
                        <div className="linhaCentral">
                          <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px'}}>INFORMAÇÕES DE FATURAMENTO</div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                            <div className="clientFlex1">
                                <span>Status Faturamento:</span>
                                <p>{project.projeStatus2? project.projeStatus2==='Recebido'? 'Depositado' : project.projeStatus2 : 'Aguardando Entrega' }</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Data Faturamento:</span>
                                <p>{project.projBillingDate? new Date(project.projBillingDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Cobrança' }</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Documento de Faturamento:</span>
                                <p>{project.projBillingDoc? `${project.projClientBillInfo}: ${project.projBillingDoc}` : 'Aguardando Cobrança' }</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Data Depósito:</span>
                                <p>{project.projBillingPaymentDate? new Date(project.projBillingPaymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Depósito' }</p>
                            </div>
                        </div>
                        <div className="linhaCentral">
                            <div className="linhasTitle"  style={{width:'fit-content'}}>INFORMAÇÕES DE PAGAMENTO</div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                            <div className="clientFlex1">
                                <span>Status Pagamento:</span>
                                <p>{project.projeStatus3? project.projeStatus3 : 'Aguardando Entrega' }</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Data Pagamento Estimada:</span>
                                <p>{project.projPaymentDate? new Date(project.projPaymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Data Pagamento Efetivada:</span>
                                <p>{project.projPayedDate? new Date(project.projPayedDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</p>
                            </div>
                        </div>
                        
                        <div className='pageListCLientsBLinhas'>
                            <div className='clientFlex1' style={{border:0}}>
                                <div className='clientFlexTitle'>lider de projeto</div>
                                <div className='analistasFlex1'>
                                  {project.projLideres && project.projLideres.length>0? project.projLideres.map((item, index)=>(
                                  <div className='pageListCLientsBLinhas' key={index}>
                                    <div className="clientFlex1">
                                      <span>Lider de projeto:</span>
                                      <p><Link to={'/colaborador/'+item.link}>{item.name}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>País:</span>
                                      <p>{item.country}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Email:</span>
                                      <p  style={{textTransform:'lowercase'}}>{item.email}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Pagamento:</span>
                                      <p >{item.payment}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Valor:</span>
                                      <p >{item.paymentCurrency}: ${item.paymentValue? item.paymentValue.toLocaleString('pt-br'): 'Não Registrado'}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Status Pagamento:</span>
                                      <p >{item.paymentStatus? 'Pago': 'A Pagar'}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Data Pagamento:</span>
                                      <p >{item.paymentDate? new Date(item.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'A Pagar'}</p>
                                    </div>
                                   </div>
                                  )) :
                                  <div className='pageListCLientsBLinhas'>
                                    <div className="clientFlex1">
                                      <span>Lider de projeto:</span>
                                      <p>Não Registrado</p>
                                    </div>
                                    </div>}
                                </div>
                            </div>
                            </div>
                            <div className='pageListCLientsBLinhas'>
                            <div className='clientFlex1' style={{border:0}}>
                                <div className='clientFlexTitle'>Analistas</div>
                                <div className='analistasFlex1'>
                                  {project.projAnalistas && project.projAnalistas.length>0? project.projAnalistas.map((item, index)=>(
                                    <div className='pageListCLientsBLinhas'  key={index}>
                                    <div className="clientFlex1">
                                      <span>{item.category}</span>
                                      <p><Link to={'/colaborador/'+item.link}>{item.name}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>País:</span>
                                      <p>{item.country}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Email:</span>
                                      <p style={{textTransform:'lowercase'}}>{item.email}</p>
                                    </div>
                                   
                                    <div className="clientFlex1">
                                      <span>Valor Pagamento:</span>
                                      <p >{item.paymentCurrency}: ${Math.round(item.payment).toLocaleString('pt-br')}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Status Pagamento:</span>
                                      <p >{item.paymentStatus? 'Pago': 'A Pagar'}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Data Pagamento:</span>
                                      <p >{item.paymentDate? new Date(item.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'A Pagar'}</p>
                                    </div>
                                    </div>
                                    
                                  )) :
                                  <div className='pageListCLientsBLinhas'>
                                  <div className="clientFlex1">
                                    <span>Analista:</span>
                                    <p>Não Registrado</p>
                                  </div>
                                  </div>
                                    }
                                </div>
                            </div>
                        </div>
                       
                        <button className='seeClient' onClick={(e)=>{handleEditInfo(e)}}>EDITAR INFORMAÇÕES</button>
                        <button className='seeClient' onClick={(e)=>{handleEditCalc(e)}}>EDITAR CÁLCULO</button>
                        <button className='seeClient' onClick={(e)=>{handleEditLider(e)}}>EDITAR LIDER DE PROJETO</button>
                        <button className='seeClient' onClick={(e)=>{handleEditAnalistas(e)}}>EDITAR ANALISTAS</button>         
                      </div>
        </div>

      </div>
      }
      {openInfo &&

        <div className="popUp2">
            <form className="form900" onSubmit={handleUpdateInfo}>
            <div className="linhas">
                  <div className='clientName'>
                      <p>Nome:</p>
                      <input type="text" onChange={(e)=>{setProjName(e.target.value)}} defaultValue={projName}/>
                  </div>
                  <div className="clientName">
                  <p>Cliente: {projClient}</p>
                  <select  onChange={(e)=>{handleAddClient(e)}}>
                    <option value="">Selecione</option>
                    {clients.map((item, index)=>(
                        <option key={index} id={item.id} billType={item.data.clientBillingType} billInfo={item.data.clientBillingInfo} value={item.data.clientName}>{item.data.clientName}</option>
                    ))}
                    
                  </select>
                </div>
              </div>
              <div className="linhas">
                <div className="clientName">
                  <p>País de Contratação: {projHiringCountry}</p>
                  <select  onChange={(e)=>{setProjHiringCountry(e.target.value)}}>
                      <option value="">Selecione</option>
                      {[...Countries].map((item, index)=>(
                        <option key={index} value={item.country}>{item.country}</option>
                      ))}
                  </select>
                </div>
                  <div className="clientName">
                  <p>Jurisdições:</p>
                  <input type="text"   onChange={(e)=>{setProjJurisdictions(e.target.value.split(','))}} defaultValue={projJurisdictions} />
                </div>
            </div>
            
            <div className="linhas">
              <div className="projF1">
                <p>Data Inicio:{new Date(projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                <input type="date"  onChange={(e)=>{setProjStartDate(e.target.value)}} />
              </div>
              <div className="projF1">
                <p>Data Entrega:{new Date(projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                <input type="date"  onChange={(e)=>{setProjDeadLine(e.target.value)}} />
              </div>
            </div>
            <div className="linhas">
              <div className="projF1">
                <p>Data Intermediária:{new Date(projIntermediateDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                <input type="date"  onChange={(e)=>{setProjIntermediateDate(e.target.value)}} />
              </div>
              <div className="projF1">
                <p>Status Intermediária:{projIntermediateStatus}</p>
                <select  onChange={(e)=>{setProjIntermediateStatus(e.target.value)}}>
                  <option value="">Selecione</option>
                  <option value="Interna">Interna</option>
                  <option value="Externa">Externa</option>
                </select>
              </div>
            </div>
            <div className="linhas">
              <div className="projF1" style={{width:'100%'}}>
                <p>Comentários:</p>
                <textarea id="" rows="6" style={{width:'100%'}} defaultValue={projComments} required onChange={(e)=>{setProjComments(e.target.value)}}></textarea>
              </div>
            </div>
        <input className='editValues'  type="submit" value="ATUALIZAR INFORMAÇÕES" />
        <button className='editValues' onClick={(e)=>{handleCloseInfo(e)}} >FECHAR</button>
        <div className="linhas">
          {uploading &&
            <span>uploading...</span>
          }
          {updated &&
            <span>O projeto foi atualizado com sucesso!</span>
          }
          {erros &&
            <span className='Erro'> Erro:{erro}<br/> tente novmente mais tarde</span>
          }
        </div>
        </form>
        
        </div>
      }
      {openLider &&
        <div className="popUp">
          <form className="form900">
              
              <div className="linhas">
                    <div className="projF1">
                      <p>Nome: </p>
                      <select onChange={(e)=>{handlLiderItem(e)}} id='lidernome' required>
                        
                          <option value="">Selecione</option>
                            {projLider.map((item,index)=>(
                              <option key={index} id={item.id} email={item.data.collabEmail} country={item.data.collabCountry} value={item.data.collabName}>{item.data.collabName} - {item.data.collabCountry}</option>
                            ))}
                          
                      </select>
                    </div>
                    <div className="projF1">
                          <p>Moeda:</p>
                          <select onChange={(e)=>{setprojLiderPaymentCurrency(e.target.value)}} id='moedaLider' required>
                            <option value="">Selecione</option>
                            <option value="USD">USD</option>
                            <option value="BRL">BRL</option>
                          </select>
                        </div>
                      <div className="projF1">
                        <p>Pagamento:</p>
                        <select onChange={(e)=>{handleLiderPaymentValue(e)}} id='pagamentoLider' required>
                          <option value="">Selecione</option>
                          <option pagamento={projLiderPayementCurrency === 'USD'? 0 : 0} value="Nível 0">Nível 0</option>
                          <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel1 : rates.brlNivel1} value="Nível 1">Nível 1 - USD:{rates.usdNivel1} - BRL:{rates.brlNivel1} </option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel2 : rates.brlNivel2}  value="Nível 2">Nível 2 - USD:{rates.usdNivel2} - BRL:{rates.brlNivel2}</option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel3 : rates.brlNivel3} value="Nível 3">Nível 3 - USD:{rates.usdNivel3} - BRL:{rates.brlNivel3}</option>
                        </select>
                      </div>
              </div>
            
              <div className="linhas">
                        <div className="projF1" style={{justifyContent:'flex-end'}}>
                        <button className='editValues' onClick={handleAddLider}>Adicionar</button>
                        </div>
              </div>
              {alertaLider &&
                        <div className="linhaCentral">Preencha todos os campos para adicionar um Lider de Projeto</div>
              }

              {projLideres? projLideres.map((item, index)=>(
                 <div className="linhas" key={index}>
                    <div className="clientFlex1">
                      <span>Nome:</span>
                      <p>{item.name}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Pagamento:</span>
                      <p>{item.payment}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Data Pagamento:</span>
                      <p>{item.paymentDate? new Date(item.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'A Pagar'}</p>
                    </div>
                    <div className="projF1">
                      <button  className='editValues' onClick={(e)=>{handleDeleteLider(e, item.id)}}>APAGAR</button>
                    </div>
                    <div className="projF1">
                      <button  className='editValues' onClick={(e)=>{handleEditLider2(e, item.id, item.name, item.payment, item.paymentValue, item.paymentCurrency, item.paymentDate)}}>EDITAR</button>
                    </div>

                 </div>
              ))
              :
              <div className="linhas">
                <div className="clientFlex1">
                <p> Não Registrado</p>
                </div>
              </div>
            }
            
              <input className='editValues'  type="submit" value="ATUALIZAR LÍDERES" onClick={(e)=>{handleUpdateLider(e)}} />
              <button className='editValues' onClick={(e)=>{handleCloseLider(e)}} >FECHAR</button>
              <div className="linhas">
              {uploading &&
                <span>uploading...</span>
              }
              {updated &&
                <span>O projeto foi atualizado com sucesso!</span>
              }
              {erros &&
                <span className='Erro'> Erro:{erro}<br/> tente novmente mais tarde</span>
              }
            </div>
          </form>
        </div>
         
      }
      {openLider2 &&
              <div className="popUp2">
                <form className="form900" onSubmit={(e)=>{updateLider(e, liderId)}}>
                <div className="linhas">
                    <div className="clientFlex1">
                      <span>Nome:</span>
                      <p>{liderName}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Pagamento:</span>
                      <p>{liderPayment? liderPayment : 'SELECIONE UM PAGAMENTO'}</p>
                    </div>
                    <div className="projF1">
                          <p>Moeda:</p>
                          <select onChange={(e)=>{setprojLiderPaymentCurrency(e.target.value)}} id='moedaLider' >
                            <option value="">Selecione</option>
                            <option value="USD">USD</option>
                            <option value="BRL">BRL</option>
                            
                          </select>
                        </div>
                    <div className="projF1">
                          <p>Editar Pagamento:</p>
                          <select onChange={(e)=>{handleLiderPaymentValue2(e)}} >
                            <option value="">Selecione</option>

                            <option pagamento={projLiderPayementCurrency === 'USD'? 0 : 0} value="Nível 0">Nível 0</option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel1 : rates.brlNivel1} value="Nível 1">Nível 1 - USD:{rates.usdNivel1} - BRL:{rates.brlNivel1} </option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel2 : rates.brlNivel2}  value="Nível 2">Nível 2 - USD:{rates.usdNivel2} - BRL:{rates.brlNivel2}</option>
                            <option pagamento={projLiderPayementCurrency === 'USD'? rates.usdNivel3 : rates.brlNivel3} value="Nível 3">Nível 3 - USD:{rates.usdNivel3} - BRL:{rates.brlNivel3}</option>
                          </select>
                        </div>
                      
                 </div>
                 {liderPaymentDate? 
                 <div className="projF1">
                 <p>Editar Data De Pagamento:{liderPaymentDate? new Date(liderPaymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'A Pagar'}</p>
                 <input type="date" onChange={(e)=>{setLiderPaymentDate(e.target.value)}} />
                 </div>
                 : ''
                  }
                  {projLiderPayementCurrency!== '' && liderPayment !==''?
                <input className='editValues'  type="submit" value="ATUALIZAR LIDER" />
                :
                <div className="linhas">Verifique todos os campos para atualizar o analista</div>
              }
                <button className='editValues' onClick={(e)=>{handleCloseLider2(e)}} >FECHAR</button>
                  </form>
              </div>
            }
             {openAnalistas &&
        <div className="popUp">
          <form className="form900">
              <div className="linhas">
              <div className="projF1">
              <p>Categoria:</p>
                      <select onChange={(e)=>{setProjAnalistaCateg(e.target.value)}} id='categoriaAnalista'>
                        <option value="">Selecione</option>
                        <option value="Pesquisador">Pesquisador</option>
                        <option value="Pesquisador de Documentos">Pesquisador de Documentos</option>
                        <option value="Segurança">Segurança</option>
                        <option value="Tradutor/Revisor">Tradutor/Revisor</option>
                      </select>
              </div>
              
                    <div className="projF1"  style={{justifyContent:'flex-end'}}><button  className='seeClient' onClick={(e)=>{searchAnalista(e)}}>Buscar</button></div>
              </div>
              <div className="linhas">
                    <div className="projF1">
                    <p>Nome:<span style={{color:'#FA9600'}}>{loadingAnalista? 'AGUARDE! FAZENDO A BUSCA':''}</span></p>
                        <select onChange={(e)=>{handleAnanlistaItem(e)}}>
                          {projAnalistaCateg && projAnalistaCateg ?
                            <>
                            <option value="">Selecione</option>
                              {projAnalista.map((item,index)=>(
                                <option key={index} id={item.id} email={item.data.collabEmail} country={item.data.collabCountry}  value={item.data.collabName}>{item.data.collabName} - {item.data.collabCountry}</option>
                              ))}
                              </>
                              :  <option value="">Selecione uma categoria a busca</option>}
                        </select>
                    </div>
              </div>
              <div className="linhas">
                  
                        <div className="projF1">
                      <p>Moeda:</p>
                          <select onChange={(e)=>{setprojAnalistaPaymentCurrency(e.target.value)}} id='moedaAnalista' required>
                            <option value="">Selecione</option>
                            <option value="USD">USD</option>
                            <option value="BRL">BRL</option>
                          </select>
                        </div>
                        
                        <div className="projF1">
                          <p>Valor:</p>
                         <span style={{display:'flex'}}> {projAnalistaPaymentCurrency? projAnalistaPaymentCurrency: '-'}:<input type="number" onChange={(e)=>{setProjAnalistaValue(parseFloat(e.target.value))}} defaultValue={projAnalistavalue?  `${Math.round(projAnalistavalue).toLocaleString('pt-br')}`: ''}  id='analistavalor' required /></span>
    
                    </div>
              </div>              
              <div className="linhas">
                        <div className="projF1" style={{justifyContent:'flex-end'}}>
                        <button className='editValues' onClick={handleAddAnalista}>Adicionar</button>
                        </div>
              </div>
              {alertaAnalista &&
                        <div className="linhaCentral">Preencha todos os campos para adicionar um Analista</div>
              }

              {projAnalistas? projAnalistas.map((item, index)=>(
                 <div className="linhas" key={index}>
                    <div className="clientFlex1">
                      <span>{item.category}:</span>
                      <p>{item.name}</p>
                    </div>
                    
                    <div className="clientFlex1">
                      <span>Pagamento:</span>
                      <p>{item.paymentCurrency}: {Math.round(item.payment).toLocaleString('pt-br')}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Data Pagamento:</span>
                      <p>{item.paymentDate? new Date(item.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'A Pagar'}</p>
                    </div>
                    <div className="projF1">
                      <button  className='editValues' onClick={(e)=>{handleDeleteAnalista(e, item.id)}}>APAGAR</button>
                    </div>
                    <div className="projF1">
                      <button  className='editValues' onClick={(e)=>{handleEditAnalista2(e, item.id, item.name, item.payment, item.paymentCurrency, item.paymentDate)}}>EDITAR</button>
                    </div>

                 </div>
              ))
              :
              <div className="linhas">
                <div className="clientFlex1">
                <p> Não Registrado</p>
                </div>
              </div>
            }
            
              <input className='editValues'  type="submit" value="ATUALIZAR ANALISTAS" onClick={(e)=>{handleUpdateAnalistas(e)}} />
              <button className='editValues' onClick={(e)=>{handleCloseAnalista(e)}} >FECHAR</button>
              <div className="linhas">
              {uploading &&
                <span>uploading...</span>
              }
              {updated &&
                <span>O projeto foi atualizado com sucesso!</span>
              }
              {erros &&
                <span className='Erro'> Erro:{erro}<br/> tente novmente mais tarde</span>
              }
            </div>
          </form>
        </div>
         
      }
      {openAnalista &&

        <div className="popUp2">
        <form className="form900" onSubmit={(e)=>{updateAnalista(e, analistaId, analistaPayment, analistaPaymentDate, analistaCurrency)}}>
        <div className="linhas">
       
                        <div className="clientFlex1" style={{border:0}}>
                      <p>Moeda:</p>
                          <select onChange={(e)=>{setAnalistaCurrency(e.target.value)}} id='moedaAnalista'>
                            <option value="">Selecione</option>
                            <option value="USD">USD</option>
                            <option value="BRL">BRL</option>
                          </select>
                        </div>
                        
                        <div className="clientFlex1"  style={{border:0}}>
                          <p>Valor:</p>
                         <span style={{display:'flex'}}> {analistaCurrency? analistaCurrency: '-'}:<input type="text" onChange={(e)=>{setAnalistapayment(parseFloat(e.target.value))}} value={analistaPayment?  `${Math.round(analistaPayment).toLocaleString('pt-br')}`: ''} /></span>
    
                    </div>
                    {analistaPaymentDate?
                    <div className="clientFlex1"  style={{border:0}}>
                        <p>Data de Pagamento:</p>
                        <input type="date" onChange={(e)=>{setAnalistaPaymentDate(e.target.value)}} />
                    </div>
                    :''
                    }

                   
                     <div className="projF1">
                    

                  </div>
             
        </div>
        <div className="linhas">
            <div className="clientFlex1">
              <span>Nome:</span>
              <p>{analistaName}</p>
            </div>
            
            <div className="clientFlex1">
              <span>Pagamento:</span>
              <p>{analistaCurrency}: {Math.round(analistaPayment).toLocaleString('pt-br')} </p>
            </div>
            <div className="clientFlex1">
                <span>Data Pagamento:</span>
                <p>{analistaPaymentDate? new Date(analistaPaymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'A Pagar'}</p>
            </div>
        </div>
        {analistaCurrency !== ""  && isNaN(analistaPayment)=== false?
        <input className='editValues'  type="submit" value="ATUALIZAR ANALISTA" />
        :
        <div className="linhas">Verifique todos os campos para atualizar o analista</div>
        }
        <button className='editValues' onClick={(e)=>{handlecloseAnalista2(e)}} >FECHAR</button>
          </form>
</div>

      }
    
      {resultados &&
          <div className="popUp">
            <form className="form900" onSubmit={(e)=>{handleUpdateCalc(e)}}>
            <div className="linhas">

                <div className="projF1">
                    <p>Moeda:</p>
                    <select onChange={(e)=>{setProjeCurrency(e.target.value)}}>
                      <option value="">Selecione</option>

                      <option value="USD">USD</option>
                      <option value="BRL">BRL</option>
                    </select>
                </div>
                <div className="projF1">
                  <p>Valor Final em {projCurrency}:</p>
                          <input type="number"  onChange={(e)=>{setProjFinalValue(parseFloat(e.target.value))}} value={projFinalValue}   />
                    </div> 
                    <div className="projF1">
                  <p>Valor Convertido em {proj2Currency}:</p>
                          <input type="number"  onChange={(e)=>{setProjCovertedValue(parseFloat(e.target.value))}} value={projCovertedValue}   />
                    </div>
                </div>
                <div className="linhas">
                <div className="projF1">
                    <p>Nº de Entrevistas:</p>
                    <select onChange={(e)=>{setProjSources(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Tipo do Cliente:</p>
                    <select  onChange={(e)=>{setProjClientType(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value='Consultoria Local'>Consultoria Local</option>
                      <option value='Consultoria Global'>Consultoria Global</option>
                      <option value='Cliente Final Local'>Cliente Final Local</option>
                      <option value='Cliente Final Global'>Cliente Final Global</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Tipo do Projeto:</p>
                    <select onChange={(e)=>{setProjType(e.target.value)}}>
                    <option value="">Selecione</option>
                      <option value='Humint'>Humint</option>
                      <option value='Documentos'>Documentos</option>
                      <option value='Híbrido'>Híbrido</option>
                    </select>
                </div>
                
            </div>
            <div className="linhaCentral">
            <input className='editValues'  type="submit" value="ATUALIZAR CALCULO" />
            </div>
           
            <button className='editValues' onClick={(e)=>{colseRECalc(e)}} >FECHAR</button>
           
              </form>
          </div>

      }
    </div>
    
  )
}

export default Project