import React from 'react'
import Logo from '../../media/logo_branco 2.webp'
import './partials.css'

function TopLogo() {
  return (
    <div className='SBtopLogo'>
        <img className='logoDesk' src={Logo} alt="logo" width={'100%'} height={'auto'} />
    </div>
  )
}

export default TopLogo