import React,{useState, useEffect} from 'react'
import SideBarCollabs from '../../Components/SideBar/SideBarCollabs'
import {collaboratorsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, where, deleteDoc, doc} from "firebase/firestore"
import { db } from '../../libs/firebase';
import { Link } from 'react-router-dom';
import Open from '../../media/open.webp'

function ListCollabBank() {
 //states para pegar infos e fazer filtro
const [collabs, setCollabs]=useState([]);
const [loading, setLoading]=useState(false);
const [search, setSearch]=useState('');
const [serachFilter, setSerachFilter]=useState('Busca Múltipla');

   //pegando infos cliente
useEffect(()=>{
     
 const q = query(collaboratorsCollectionRef, where ('collabCategory', '==', 'Banco/Financeiro'), orderBy('collabName', 'asc'));
 setLoading(true);
 const change = onSnapshot(q, snapshot=>{
     setCollabs(snapshot.docs.map(doc=>({
     data:doc.data(),
     id:doc.id
     })))
     setLoading(false);
 })

 return()=>{change()}

},[]);

//limpando serch
const handleClear=()=>{
 setSearch('')
}

//abrindo acoredeon
const [open, setopen]=useState(false)
const handleOpen = (id)=>{
 setopen(id)
}

const hancleClose = ()=>{
 setopen(!open)
}

    // deletandoo doC
    const [openDelete, setOpenDelete]=useState(false)
    const [deleting, setDeleting]=useState(false)
    const handleOpenDelete=()=>{
        setOpenDelete(true)
    }
  
  const handleCloseDelete =()=>{
      setOpenDelete(false)
  }
  
  const handleDeletClient =async(e, id)=>{
      e.preventDefault()
      setDeleting(true)
      await deleteDoc(doc(db, 'collaborators', id));
      setDeleting(false)
      
  }




return (
  <div className='superContainer'>
  <SideBarCollabs/>
  <div className='pageHeader'>
        <div className="pageTitleLeft">
        <div className="pageTitle">Colaboradores - Fornecedores - Categoria:&nbsp;&nbsp;&nbsp;Banco/Financeiro</div>
        </div>
        <div className='pageListSearch'>

          <div className='pageListSearchAelect'>
              Filtro: 
              <select onChange={(e)=>{setSerachFilter(e.target.value)}}>
              <option value="Busca Múltipla">Busca Múltipla</option>
                <option value="Nome">Nome</option>
                <option value="País">País</option>
                <option value="Desde">Desde</option>
                <option value="Profissão">Profissão</option>
                <option value="Email">Email</option>
                <option value="Telefone">Telefone</option>
                <option value="Referência/Origem">Referência/Origem</option>
                <option value="Comentários">Comentários</option>
                <option value="Dados de Pagamento">Dados de Pagamento</option>
              </select>
          </div>
          <div className='pageListSearcInput' ><input type="text"  placeholder={`Busca por ${serachFilter}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} /></div>
          <div className='pageListSearcClear'><button onClick={handleClear}>X</button></div>
        </div>
  </div>
  <div className='pagesContent'>
     {loading &&
        <div className="loading">
            <div className='pageListCLients'>Loading....</div>
        </div>
      }
      {collabs.filter((val)=>{
                  if(serachFilter === 'Nome'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabName.toLowerCase().includes(search.toLowerCase())){
                      
                      return val
                  }
                  }
                  if(serachFilter === 'País'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabCountry.toLowerCase().includes(search.toLowerCase())){
                      return val
                  }
                  }
                  if(serachFilter === 'Desde'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabStart.toLowerCase().includes(search.toLowerCase())){
                      return val
                  }
                  }
                  if(serachFilter === 'Profissão'){
                    if(search === ''){
                      return val
                  } else if ([val.data.collabJurisdictions].toString().toLowerCase().includes(search.toLowerCase())){
                      return val
                  }
                  }
                  if(serachFilter === 'Email'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabEmail.toLowerCase().includes(search.toLowerCase())){
                      return val
                  }
                  }
                  if(serachFilter === 'Telefone'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabPhone.toLowerCase().includes(search.toLowerCase())){
                      return val
                  }
                  }
                  if(serachFilter === 'Referência/Origem'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabRecomendation.toLowerCase().includes(search.toLowerCase())){
                      return val
                  }
                  }
                  if(serachFilter === 'Comentários'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabComments.toLowerCase().includes(search.toLowerCase())){
                      return val
                  }
                  }
                  if(serachFilter === 'Dados de Pagamento'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabAccount.toLowerCase().includes(search.toLowerCase())){

                      return val
                  }
                  }
                  if(serachFilter === 'Busca Múltipla'){
                    if(search === ''){
                      return val
                  } else if (val.data.collabCountry.toLowerCase().includes(search.toLowerCase())||
                  [val.data.collabJurisdictions].toString().toLowerCase().includes(search.toLowerCase())||
                  val.data.collabComments.toLowerCase().includes(search.toLowerCase())){
                      return val
                  }
                  }
              }).map((item, index)=>(
                <div className='pageListCLients' key={index} >
                <div className='pageListCLientsA'>
                    <div className="clientFlex1">
                      <span>Nome:</span>
                      <p>{item.data.collabName}</p>
                    </div>
                    <div className="clientW0">
                      <span>Tipo:</span>
                      <p>{item.data.collabType}</p>
                    </div>
                    <div className="clientW0">
                      <span>Categoria:</span>
                      <p>{item.data.collabCategory}</p>
                    </div>
                    <div className="clientW0">
                      <span>País:</span>
                      <p>{item.data.collabCountry}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Profissão:</span>
                      <p style={{maxWidth: 25+'ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{item.data.collabJurisdictions.join(' - ')}</p>
                    </div>
                    <div className="clientW0" style={{width:'fit-content'}}>
                      <span>Desde:</span>
                      <p>{item.data.collabStart}</p>
                    </div>
                    <div className='openClientContent'>
                      {open === item.id? 
                      <button className='closeClient' onClick={hancleClose}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                      :
                      <button className='openClient' onClick={()=>(handleOpen(item.id))}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                      }
                    </div>
                </div>
                <div className={open === item.id? 'pageListCLientsB listActive' : 'pageListCLientsB' }>
                  <div className='pageListCLientsBLinhas'>
                      <div className="clientFlex1">
                        <span>Email:</span>
                        <p style={{textTransform:'lowercase'}}>{item.data.collabEmail}</p>
                      </div>
                      <div className="clientFlex1">
                        <span>Telefone:</span>
                        <p>{item.data.collabPhone? item.data.collabPhone: 'Não Registrado' }</p>
                      </div>
                      <div className="clientFlex1">
                        <span>Referência/Origem:</span>
                        <p>{item.data.collabRecomendation}</p>
                      </div>
                  </div>
                  <div className='pageListCLientsBLinhas'>
                    <div className='clientFlex1'>
                      <div className='clientFlexTitle'>Comentários</div>
                      <div className='clientFlexBox' style={{height:'100%'}}>
                        <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.collabComments}</pre>
                      </div>
                    </div>
                    <div className='clientFlex1'>
                      <div className='clientFlexTitle'>DADOS DE PAGAMENTO</div>
                      <div className='clientFlexBox' style={{height:'100%'}}>
                        <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.collabAccount}</pre>
                      </div>
                    </div>
                    
                    <div className='clientFlex1'>
                      <div className='clientFlexTitle'>STATUS DE NDA</div>
                      <div className='pageListCLientsBLinhas'
                        style={item.data.collabNDADate? 
                                Math.floor((new Date(item.data.collabNDADate.toDate()) - new Date())/ (1000 * 60 * 60 * 24)) <= 0? {backgroundColor:'#710606'}: {}
                                : {}
                              }>
                            <div className="clientW01">
                              <span>Tem NDA?</span>
                              <p>{item.data.collabNDA? 'SIM': 'NÃO'}</p>
                            </div>
                            <div className="clientW01">
                              <span>Data de expiração:</span>
                              {item.data.collabNDADate?
                              <p>{item.data.collabNDADate.toDate().toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                              : <p></p>}
                            </div>
                            <div className="clientFlex1">
                              <span>Link do Documento:</span>
                              {item.data.collabNDALink?
                              <p style={{maxWidth: 25+'ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textTransform:'lowercase'}}><a href={item.data.collabNDALink} style={{textTransform:'lowercase'}} target="_blank" rel="noopener noreferrer">{item.data.collabNDALink}</a></p>
                              : <p></p>}
                            </div>
                      </div>
                      <div className='clientFlexTitle'>STATUS CONTRATO</div>
                      <div className='pageListCLientsBLinhas'
                            style={item.data.collabContractDate? 
                              Math.floor((new Date(item.data.collabContractDate.toDate()) - new Date())/ (1000 * 60 * 60 * 24)) <= 0? {backgroundColor:'#710606'}: {}
                              : {}
                              }>
                            <div className="clientW01">
                              <span>Tem Contrato?</span>
                              <p>{item.data.collabContract? 'SIM': 'NÃO'}</p>
                            </div>
                            <div className="clientW01">
                              <span>Data de expiração:</span>
                              {item.data.collabContractDate?
                              <p>{item.data.collabContractDate.toDate().toLocaleString('pt-BR', {timeZone: 'UTC'}).substr(0, 10)}</p>
                              : <p></p>}
                            </div>
                            <div className="clientFlex1">
                              <span>Link do Documento:</span>
                              {item.data.collabContractLink?
                              <p style={{maxWidth: 25+'ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textTransform:'lowercase'}}><a href={item.data.collabContractLink}  style={{textTransform:'lowercase'}} target="_blank" rel="noopener noreferrer">{item.data.collabContractLink}</a></p>
                              : <p></p>}
                            </div>
                      </div>
                     
                    </div>
                  </div>
                  <Link to={"/colaborador/"+item.id} className='seeClient'>VER COLADORADOR</Link>
                  <button className='editValues' onClick={handleOpenDelete}>APAGAR COLABORADOR</button>
                          {openDelete &&
                          <div className="popUpDelete">
                          <div className="deleteAviso">
                              <div>Você realmente quer apagar esse colaborador?</div>
                              <div className='deleteButtons'>
                                  <button onClick={handleCloseDelete} className='deleteNao'>❌ Não</button>
                                  <button onClick={(e)=>{handleDeletClient(e, item.id)}} className='deleteSim'>✔️ Sim</button>
                              </div>
                              {deleting &&
                              <div>Apagando Colaborador</div>
                              }
                            </div>
                          
                        </div>
                          }                 
                </div>
                
              </div>
                ))}
  </div>
</div>

)
}

export default ListCollabBank