import React,{useState, useEffect} from 'react'
import SideBarUpPages from '../../Components/SideBar/SideBarClients'
import {prospectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, deleteDoc, doc, addDoc} from "firebase/firestore"
import { useNavigate } from 'react-router-dom'
import { db } from '../../libs/firebase';
import { Link } from 'react-router-dom';
import Open from '../../media/open.webp'

function ListProspects() {
 //abrindo acordeon
 const [open, setopen]=useState(false)

 //states para pegar infos e fazer filtro
 const [clients, setClients]=useState([]);
 const [loading, setLoading]=useState(false);
 const [search, setSearch]=useState('');
 const [serachFilter, setSerachFilter]=useState('Nome');

 //abrindo acoredeon
 
 const handleOpen = (id)=>{
   setopen(id)
 }

 const hancleClose = ()=>{
   setopen(!open)
 }



 //pegando infos cliente
 useEffect(()=>{
       
     const q = query(prospectsCollectionRef, orderBy('clientName', 'asc'));
     setLoading(true);
     const change = onSnapshot(q, snapshot=>{
         setClients(snapshot.docs.map(doc=>({
         data:doc.data(),
         id:doc.id
         })))
         setLoading(false);
     })

     return()=>{change()}

 },[]);

 //limpando serch
   const handleClear=()=>{
     setSearch('')
   }

     // deletandoo doC
     const [openDelete, setOpenDelete]=useState(false)
     const [deleting, setDeleting]=useState(false)
     const handleOpenDelete=()=>{
         setOpenDelete(true)
     }
   
   const handleCloseDelete =()=>{
       setOpenDelete(false)
   }
   
   const handleDeletClient =async(e, id)=>{
       e.preventDefault()
       setDeleting(true)
       await deleteDoc(doc(db, 'prospects', id));
       setDeleting(false)
       
   }

   // tranformado em cliente
   const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const handleGoClient = async(e, id, nome, pais, ano, web, contatos, coments)=>{
    e.preventDefault();
    setUploading(true);
    await addDoc( clientsCollectionRef, {
      clientName: nome,
      clientCountry: pais,
      clientStartYear: ano,
      clientComments: coments,
      clientContacts:contatos,
      clientBillingType: 'Não Informado',
      clientBillingInfo: 'Não Informado',
      clientWeb:web,
      clientStatus: 'Ativo',
      clientProjects:0
      });
    //navigate('/');

    await deleteDoc(doc(db, 'prospects', id));
    setUploading(false);
    navigate('/clientes')
    
  }
 return (

   <div className='superContainer'>
       <SideBarUpPages/>
       <div className='pageHeader'>
             <div className="pageTitleLeft">
                 <div className="pageTitle">PROSPECÇÕES - Quantidade: {clients.length}</div>
             </div>
             <div className='pageListSearch'>

               <div className='pageListSearchAelect'>
                   Filtro: 
                   <select  onChange={(e)=>{setSerachFilter(e.target.value)}} >
                     <option value="Nome">Nome</option>
                     <option value="País">País</option>
                     <option value="Desde">Desde</option>
                     <option value="Nome de Contatos">Nome de Contatos</option>
                     <option value="Comentários">Comentários</option>

                   </select>
               </div>
               <div className='pageListSearcInput' ><input type="text"  placeholder={`Buca por ${serachFilter}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} /></div>
               <div className='pageListSearcClear' onClick={handleClear}><button>X</button></div>
             </div>
     </div>
           <div className='pagesContent'>
           {loading &&
           <div className="loading">
               <div className='pageListCLients'>Loading....</div>
           </div>
           
           }
           {clients.filter((val)=>{
                      if(serachFilter === 'Nome'){
                           if(search === ''){
                             return val
                         } else if (val.data.clientName.toLowerCase().includes(search.toLowerCase())){
                             return val
                         }
                       }
                       if(serachFilter === 'País'){
                             if(search === ''){
                               return val
                           } else if (val.data.clientCountry.toLowerCase().includes(search.toLowerCase())){
                               return val
                           }
                       }
                       if(serachFilter === 'Desde'){
                         if(search === ''){
                           return val
                       } else if (val.data.clientStartYear.toLowerCase().includes(search.toLowerCase())){
                           return val
                       }
                       }
                       if(serachFilter === 'Nome de Contatos'){
                         if(search === ''){
                           return val
                       } else if (val.data.clientContacts.map((item)=>item.name).toString().toLowerCase().includes(search.toLowerCase())){
                           return val
                       }
                       }
                       if(serachFilter === 'Comentários'){
                         if(search === ''){
                           return val
                       } else if (val.data.clientComments.toLowerCase().includes(search.toLowerCase())){
                           return val
                       }
                       }
                    
                       
                   }).map((item, index)=>(
           <div className='pageListCLients' key={index} >
               <div className='pageListCLientsA'>
                   <div className="clientFlex2">
                     <span>Nome:</span>
                     <p>{item.data.clientName}</p>
                   </div>
                   <div className="clientFlex1">
                     <span>País:</span>
                     <p>{item.data.clientCountry}</p>
                   </div>
                   <div className="clientFlex1">
                     <span>Desde:</span>
                     <p>{item.data.clientStartYear}</p>
                   </div>
                   <div className="clientFlex1">
                     <span >Web Site:</span>
                     <p style={{maxWidth: 18+'ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                     {item.data.clientWeb? <a href={item.data.clientWeb} style={{textTransform:'lowercase'}} target="_blank" rel="noopener noreferrer">{item.data.clientWeb}</a> :'Não Registrado'}
                       </p>
                   </div>
                   <div className='openClientContent'>
                     {open === item.id? 
                     <button className='closeClient' onClick={hancleClose}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                     :
                     <button className='openClient' onClick={()=>(handleOpen(item.id))}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                     }
                   </div>
               </div>
               <div className={open === item.id? 'pageListCLientsB listActive' : 'pageListCLientsB' }>
                   {item.data.clientContacts.map((item,index)=>(
                     <div className='pageListCLientsBLinhas' key={index} id={item.id}>
                         <div className="clientFlex2">
                           <span>Nome Contato:</span>
                           <p>{item.name}</p>
                         </div>
                         <div className="clientFlex1">
                           <span>Telefone Contato:</span>
                           <p>{item.phone}</p>
                         </div>
                         <div className="clientFlex1">
                           <span>Email Contato:</span>
                           <p style={{textTransform:'lowercase'}}>{item.email}</p>
                         </div>
                     </div>
                 ))}
                 
                 
                 <div className='pageListCLientsBLinhas'>
                   <div className='clientFlex1'>
                         <div className='clientFlexTitle'>Comentários</div>
                         <div className='clientFlexBox' style={{height:'100%'}}>
                           <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.clientComments}</pre>
                         </div>
                   </div>
                 </div>
                 <Link to={"/prospeccao/"+item.id} className='seeClient'>VER PROSPECÇÃO</Link>
                 <button className='editValues' onClick={(e)=>{handleGoClient(e, item.id, item.data.clientName, item.data.clientCountry, item.data.clientStartYear, item.data.clientWeb,item.data.clientContacts, item.data.clientComments)}}>TRANSFORMAR EM CLIENTE</button>
                 {uploading &&
                        <span>uploading...</span>
                    }
                 <button className='editValues' onClick={handleOpenDelete}>APAGAR PROSPECÇÃO</button>
                         {openDelete &&
                         <div className="popUpDelete">''
                         <div className="deleteAviso">
                             <div>Você realmente quer apagar essa prospecção?</div>
                             <div className='deleteButtons'>
                                 <button onClick={handleCloseDelete} className='deleteNao'>❌ Não</button>
                                 <button onClick={(e)=>{handleDeletClient(e, item.id)}} className='deleteSim'>✔️ Sim</button>
                             </div>
                             {deleting &&
                             <div>Apagando Prospecção</div>
                             }
                           </div>
                         
                       </div>
                         }                 
               </div>
               
             
           </div>
           ))}
       </div>
   </div>
   

 )
}

export default ListProspects