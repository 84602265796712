import { BrowserRouter } from 'react-router-dom';
import { RouteList } from './routes/Routes';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <RouteList />
    </BrowserRouter>
  );
}

export default App;
