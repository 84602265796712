import SideBarProjects from '../../Components/SideBar/SideBarProjects'
import React, {useState, useEffect} from 'react'
import { Link, useParams} from 'react-router-dom';
import { db } from '../../libs/firebase';
import { doc, getDoc, updateDoc} from "firebase/firestore"
import '../pages.css'

function ProjectToPay() {
    //pegand doc
    const { id } = useParams();
    const [projeto, setProjeto]=useState([]);
    const docRefdown = doc(db, 'projects', id);

    const pegandoDoc = async()=>{
      try {
        const docSnap = await getDoc(docRefdown);
        if(docSnap.exists()) {
            setProjeto(docSnap.data());
        } else {
            alert("Document does not exist")
        }
      }
      catch(error) {
       alert(error)
      }
      return(pegandoDoc());
    }
    useEffect(()=>{
      pegandoDoc();
    });

    //abrindo collabs
    const [open, setOpen]=useState(false)
    const [newProjLideres, setNewProjLideres]=useState([])
    const [newProjAnalistas, setNewProjAnalistas]=useState([])

    const handelOpenCollabs = ()=>{
        setOpen(true)
        setNewProjLideres(projeto.projLideres)
        setNewProjAnalistas(projeto.projAnalistas)
    }

    const handleCloseCollabs =(e)=>{
        e.preventDefault()
        setOpen(false)
        setNewProjLideres([])
        setNewProjAnalistas([])
    }

    //pagando lideres
    
    const[liderId, setLiderId]=useState('')
    const [liderName, setliderName]=useState('')
    const [liderPayment, setLiderPayment]=useState('')
    const [liderPaymentValue, setLiderPaymentValue]=useState(0)
    const [liderPaymentStatus, setliderPaymentStatus]=useState()
    const [liderPaymentDate, setLiderPaymentDate]=useState('')
    const [liderCurrency, setLiderCurrency]=useState('')
    const [openLider, setOpenlider]=useState(false);

    const handleOpenLider =(e,id, name, payment, paymentstatus, paymentdate, paymentValue, currency)=>{
        e.preventDefault()
        setLiderId(id)
        setliderName(name)
        setLiderPayment(payment)
        setliderPaymentStatus(paymentstatus)
        setLiderPaymentDate(paymentdate)
        setLiderPaymentValue(paymentValue)
        setLiderCurrency(currency)
        setOpenlider(true)
    
    }
    const handleCloseLider =(e)=>{
        e.preventDefault()
        setOpenlider(false)
    }

    const handlePayLider=(e)=>{
        e.preventDefault()
        const now = new Date()
        setliderPaymentStatus(true)
        setLiderPaymentDate(now.toISOString().slice(0,10))
    }

    const handleUnPayLider =(e)=>{
        e.preventDefault()
        setliderPaymentStatus(false)
        setLiderPaymentDate('')
    }
    const updateLider=(e, id, status , date)=>{
        e.preventDefault()
        setNewProjLideres(newProjLideres.map(contact=>{
            if(contact.id === id){
                return{...contact, paymentStatus:status, paymentDate:date }
            }else{
                return contact
            }
        }))
        setOpenlider(false)
        setLiderId('')
        setliderName('')
        setLiderPayment('')
        setliderPaymentStatus('')
        setLiderPaymentDate('')
    }
    // pagando analistas
    const[analistaId, setAnalistaId]=useState('')
    const [analistaName, setAnalistaName]=useState('')
    const [analistaPayment, setAnalistaPayment]=useState('')
    const [analistaPaymentStatus, setAnalistaPaymentStatus]=useState()
    const [analistaPaymentDate, setAnalistaPAymentDate]=useState('')
    const [analistaCurency, setAnalistaCurrency]=useState('')

    const [openAnalista, setOpenAnalista]=useState(false);

    const handleOpenAnalista =(e,id, name, payment, paymentstatus, paymentdate, currency)=>{
        e.preventDefault()
        setAnalistaId(id)
        setAnalistaName(name)
        setAnalistaPayment(payment)
        setAnalistaPaymentStatus(paymentstatus)
        setAnalistaPAymentDate(paymentdate)
        setAnalistaCurrency(currency)
        setOpenAnalista(true)
    
    }
    const handleCloseAnalista =(e)=>{
        e.preventDefault()
        setOpenAnalista(false)
    }

    const handlePayAnalsta=(e)=>{
        e.preventDefault()
        const now = new Date()
        setAnalistaPaymentStatus(true)
        setAnalistaPAymentDate(now.toISOString().slice(0,10))
    }
    const handleUnPayAnalsta=(e)=>{
        e.preventDefault()
        setAnalistaPaymentStatus(false)
        setAnalistaPAymentDate('')
    }
    const updateAnalista=(e, id, status , date)=>{
        e.preventDefault()
        setNewProjAnalistas(newProjAnalistas.map(contact=>{
            if(contact.id === id){
                return{...contact, paymentStatus:status, paymentDate:date }
            }else{
                return contact
            }
        }))
        setOpenAnalista(false)
        setLiderId('')
        setAnalistaName('')
        setAnalistaPayment('')
        setAnalistaPaymentStatus('')
        setAnalistaPAymentDate('')
    }
    //update projeto
    const [uploading, setUploading] = useState(false);
    const [updated, setUpdated]=useState(false);
    const [erros, setErros]=useState(false);
    const [erro, setErro]=useState('');
    const handleUpdate = async (e)=>{
        e.preventDefault();
        setUploading(true);
        const docRef = doc(db, "projects", id);
        const data ={
            projAnalistas:newProjAnalistas,
            projLideres:newProjLideres
        };
        setUploading(false);
        await updateDoc(docRef, data, { merge:true })
        .then(docRef => { setUpdated(true) })
        .catch(error => { setErros(true); setErro(error); })
       
        setUpdated(false);
        setOpen(false);
      }

  return (
    <div className='superContainer'>
        <SideBarProjects/>
        <div className='pageHeader'>
            <div className="pageTitleLeft">
                <div className="pageTitle">Projeto A pagar:&nbsp;&nbsp;&nbsp;<span>{projeto.projName}</span>  </div>
            </div>
        </div>
        <div className="pagesContentup">
            <div className='pageListCLients'>
                <div className="linhaCentral">
                        <div className="linhasTitle" style={{width:'fit-content', marginBottom:8+'px'}}>LÍDERES DE PROJETO</div>
                </div>
                {projeto.projLideres? projeto.projLideres.map((item, index)=>(
                <div className='pageListCLientsA' key={index} style={item.paymentStatus? {backgroundColor:'#0B008B', marginBottom:8+'px'}:{ marginBottom:8+'px'}}>
                    <div className="clientFlex1">
                        <span>Nome:</span>
                        <p><Link to={'/colaborador/'+item.link}>{item.name}</Link></p>
                    </div>
                    <div className="clientFlex1">
                        <span>País:</span>
                        <p>{item.country}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Email:</span>
                        <p>{item.email}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Pagamento:</span>
                        <p>{item.payment}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Valor:</span>
                        <p>{item.paymentCurrency}:  ${item.paymentValue? item.paymentValue.toLocaleString('pt-br') : 'Não Registrado' }</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Status Pagamento:</span>
                        {item.paymentStatus? 
                        <p>PAGO</p>
                        :
                        <p>A PAGAR</p>}
                        
                    </div>
                    <div className="clientFlex1">
                        <span>Data Pagamento:</span>
                        {item.paymentDate? 
                        <p>{new Date(item.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                        :
                        <p>Aguardando Pagamento</p>}
                        
                    </div>
                </div>
                )):
                <div className='pageListCLientsA'>
                    <div className='clientFlexTitle'>Loading...</div>
                </div>
                }
                    
                    <div className="linhaCentral">
                        <div className="linhasTitle" style={{width:'fit-content', marginBottom:8+'px', marginTop:8+'px'}}>ANALISTAS</div>
                </div>
                 {projeto.projAnalistas? projeto.projAnalistas.map((item, index)=>(
                <div className='pageListCLientsA' key={index} style={item.paymentStatus? {backgroundColor:'#0B008B',  marginBottom:8+'px'}:{ marginBottom:8+'px'}}>
                    <div className="clientFlex1">
                        <span>Nome:</span>
                        <p><Link to={'/colaborador/'+item.link}>{item.name}</Link></p>
                    </div>
                    <div className="clientFlex1">
                        <span>País:</span>
                        <p>{item.country}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Email:</span>
                        <p>{item.email}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Valor:</span>
                        <p>{item.paymentCurrency}: ${Math.round(item.payment).toLocaleString('pt-br')}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Status Pagamento:</span>
                        {item.paymentStatus? 
                        <p>PAGO</p>
                        :
                        <p>A PAGAR</p>}
                        
                    </div>
                    <div className="clientFlex1">
                        <span>Data Pagamento:</span>
                        {item.paymentDate? 
                        <p>{new Date(item.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                        :
                        <p>Aguardando Pagamento</p>}
                        
                    </div>
                    
                </div>
                )):
                <div className='pageListCLientsA'>
                    <div className='clientFlexTitle'>Loading...</div>
                </div>
                }
                
                <button className='editValues' style={{marginBottom:8+'px'}} onClick={handelOpenCollabs}>PAGAR COLABORADORES</button>
                <Link className='seeClient' to='/projetos/apagar'>VOLTAR</Link>
            </div>
        </div>
        {open &&
            <div className="popUp">
                <form className="pageListCLients" onSubmit={handleUpdate}>
                    <div className="linhaCentral">
                        <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px'}}>LÍDERES DE PROJETO</div>
                    </div>
                    {newProjLideres.map((item, index)=>(
                    <div className="linhas" key={index}  style={{marginBottom: 8+'px'}}>
                       <div className="clientFlex1">
                        <span>Nome:</span>
                        <p>{item.name}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>País:</span>
                        <p>{item.country}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Email:</span>
                        <p>{item.email}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Pagamento:</span>
                        <p>{item.payment}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Valor:</span>
                        <p>{item.paymentCurrency}:  ${item.paymentValue? item.paymentValue.toLocaleString('pt-br') : 'Não Registrado' }</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Status Pagamento:</span>
                        {item.paymentStatus? 
                        <p>PAGO</p>
                        :
                        <p>A PAGAR</p>}
                        
                    </div>
                    <div className="clientFlex1">
                        <span>Data Pagamento:</span>
                        {item.paymentDate? 
                        <p>{new Date(item.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                        :
                        <p>Aguardando Pagamento</p>}
                        
                    </div>
                    <div className='clientFlex1Button'>
                        <button onClick={(e)=>{handleOpenLider(e, item.id, item.name,item.payment, item.paymentStatus, item.paymentDate, item.paymentValue, item.paymentCurrency)}} >EDITAR</button>
                    </div>
                    </div>
                    ))}
                     <div className="linhaCentral">
                        <div className="linhasTitle" style={{width:'fit-content', marginBottom:8+'px', marginTop:8+'px'}}>ANALISTAS</div>
                    </div>
                    {newProjAnalistas.map((item, index)=>(
                    <div className="linhas" key={index} style={{marginBottom: 8+'px'}}>
                       <div className="clientFlex1">
                        <span>Nome:</span>
                        <p>{item.name}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>País:</span>
                        <p>{item.country}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Email:</span>
                        <p>{item.email}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Valor:</span>
                        <p>{item.paymentCurrency}: ${Math.round(item.payment).toLocaleString('pt-br')}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Status Pagamento:</span>
                        {item.paymentStatus? 
                        <p>PAGO</p>
                        :
                        <p>A PAGAR</p>}
                        
                    </div>
                    <div className="clientFlex1">
                        <span>Data Pagamento:</span>
                        {item.paymentDate? 
                        <p>{new Date(item.paymentDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                        :
                        <p>Aguardando Pagamento</p>}
                        
                    </div>
                    <div className='clientFlex1Button'>
                        <button  style={{maxHeight:45+'px'}} onClick={(e)=>{handleOpenAnalista(e, item.id, item.name,item.payment, item.paymentStatus, item.paymentDate, item.paymentCurrency)}} >EDITAR</button>
                    </div>
                    </div>
                    ))}

                    <input className='editValues'  type="submit" value="ATUALIZAR PAGAMENTOS" />
                    <button className='editValues' onClick={(e)=>{handleCloseCollabs(e)}}>FECHAR</button>
                        {uploading &&
                            <span>uploading...</span>
                        }
                        {updated &&
                            <span>O cliente foi atualizado com sucesso!</span>
                        }
                        {erros &&
                            <span className='Erro'> Erro:{erro}<br/> tente novmente mais tarde</span>
                        }
                    <div>
                 
                  </div>
                 </form>
            </div>
        }
        {openLider &&
             <div className="popUp2">
             <form className="form900" onSubmit={(e)=>{updateLider(e, liderId, liderPaymentStatus, liderPaymentDate)}}>
                <div className="linhas" key={liderId} style={{marginBottom:10+'px'}}>
                    <div className="clientFlex1">
                        <span>Nome:</span>
                        <p>{liderName}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Pagamento:</span>
                        <p>{liderPayment}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Valor:</span>
                        <p>{liderCurrency}:  ${liderPaymentValue? liderPaymentValue.toLocaleString('pt-br') : 'Não Registrado' }</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Status Pagemanto:</span>
                        <p>{liderPaymentStatus? 'PAGO':"A PAGAR"}</p>
                    </div>
                    <div className="clientFlex1" style={{border:0}}>
                    {liderPaymentStatus?
                        <button className='editValues' onClick={(e)=>{handleUnPayLider(e)}}>REMOVER</button>
                        :
                        <button className='editValues' onClick={(e)=>{handlePayLider(e)}}>PAGAR</button>
                    }
                    </div>
                </div>
                    <input className='editValues'  type="submit" value="ATUALIZAR PAGAMENTO" />
                    <button className='editValues' onClick={(e)=>{handleCloseLider(e)}}>FECHAR</button>
                
             </form>
             </div>
        }
         {openAnalista &&
             <div className="popUp2">
             <form className="form900" onSubmit={(e)=>{updateAnalista(e, analistaId, analistaPaymentStatus, analistaPaymentDate)}}>
                <div className="linhas" key={analistaId} style={{marginBottom:10+'px'}}>
                    <div className="clientFlex1">
                        <span>Nome:</span>
                        <p>{analistaName}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Valor:</span>
                        <p>{analistaCurency}: ${Math.round(analistaPayment).toLocaleString('pt-br')}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Status Pagemanto:</span>
                        <p>{analistaPaymentStatus? 'PAGO':"A PAGAR"}</p>
                    </div>
                    <div className="clientFlex1" style={{border:0}}>
                    {analistaPaymentStatus? 
                         <button className='editValues' onClick={(e)=>{handleUnPayAnalsta(e)}}>REMOVER</button>
                        :
                        <button className='editValues' onClick={(e)=>{handlePayAnalsta(e)}}>PAGAR</button>
                    }
                    </div>
                </div>
                    <input className='editValues'  type="submit" value="ATUALIZAR PAGAMENTO" />
                    <button className='editValues' onClick={(e)=>{handleCloseAnalista(e)}}>FECHAR</button>
                
             </form>
             </div>
        }
    </div>
  )
}

export default ProjectToPay