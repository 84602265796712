import React,{useState, useEffect, useLayoutEffect} from 'react'
import SideBarProjects from '../../Components/SideBar/SideBarProjects'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, doc, where, updateDoc} from "firebase/firestore"
import { db } from '../../libs/firebase';
import { Link } from 'react-router-dom';
import Open from '../../media/open.webp'


function ToBill() {
   //abrindo acordeon
   const [open, setopen]=useState(false)
   const handleOpen = (id)=>{
     setopen(id)
   }
 
   const hancleClose = ()=>{
     setopen(!open)
   }
 

   //states para pegar infos e fazer filtro
   const [projects, setProjects]=useState([]);
   const [loading, setLoading]=useState(false);
   const [search, setSearch]=useState('');
   const [serachFilter, setSerachFilter]=useState('Busca Múltipla');
   const [reais, setReais]=useState([]);
   const [dolar, setDolar]=useState([])

 //pegando infos projeto
 useEffect(()=>{
       
   const q = query(projectsCollectionRef,where('projeStatus2', '==', 'A Cobrar'), orderBy('projNumber', 'asc'));
   setLoading(true);
   const change = onSnapshot(q, snapshot=>{
     setProjects(snapshot.docs.map(doc=>({
       data:doc.data(),
       id:doc.id
       })))
       setLoading(false);
   })

  
   return()=>{change()}

},[]);

useLayoutEffect(()=>{
  let reaislist = projects.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('BRL'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  length = reaislist.length;
  var reaisNumeros = [];
  for (var i = 0; i < length; i++)
  reaisNumeros.push(Number(reaislist[i]))

  let dolarlist =projects.filter((val)=>{ if(val.data.projCurrency? val.data.projCurrency.includes('USD'):''){return val}}).map((item)=>item.data.projFinalValue).toString().split(',')
  var  lengthD = dolarlist.length;
  var dolarNumeros = [];
  for (var a = 0; a < lengthD; a++)
  dolarNumeros.push(Number(dolarlist[a]))
  setReais(reaisNumeros)
  setDolar(dolarNumeros)
},[projects])

//limpando serch
 const handleClear=()=>{
   setSearch('')
 }


 //mudando estatus do projeto
 const [billing, setBilling]=useState('')
 const [type, setType]=useState('')
 const [projID, setProjID]=useState('')
 const handleChangeStatus = async(e)=>{
    e.preventDefault()
     const analystDoc = doc(db, 'projects', projID)
     const newField = ({projBillingDoc: billing, projeStatus2: 'A Receber', projBillingDate:new Date().toISOString().slice(0,10)})
     await updateDoc(analystDoc , newField)
     setProjID('')
  setType('')
  setOpenDoc(false)

 }

 //inserindo NF-invoice
 const[openDoc, setOpenDoc]=useState(false)
 const handleOpenDoc =(e, id, billType)=>{
  e.preventDefault()
  setProjID(id)
  setType(billType)
  setOpenDoc(true)
  
 }

 const handleCloseDoc =(e)=>{
  e.preventDefault()
  setProjID('')
  setType('')
  setOpenDoc(false)
 }

 const [data1, setData1]=useState('')
 const [data2, setData2]=useState('')
   
   const handleClear2=()=>{
     setData1('')
     setData2('')
   }

  return (
    <div className='superContainer'>
        <SideBarProjects/>
        <div className='pageHeader'>
              <div className="pageTitleLeft" style={{width:'100%'}}>
                  <div className="pageTitle">PROJETOS: A COBRAR - valores totais - BRL: ${isNaN(reais.reduce((acc, curr) => acc + curr, 0))? 0: reais.reduce((acc, curr) => acc + curr, 0).toLocaleString('pt-BR')} - USD: ${isNaN(dolar.reduce((acc, curr) => acc + curr, 0))? 0: dolar.reduce((acc, curr) => acc + curr, 0).toLocaleString('pt-BR')}</div>
              </div>
              <div className='pageListSearch' style={{width:'100%'}}>

                <div className='pageListSearchAelect'>
                    FIltro: 
                    <select  onChange={(e)=>{setSerachFilter(e.target.value)}} >
                    <option value="Busca Múltipla">Busca Múltipla</option>
                      <option value="Nome">Nome</option>
                      <option value="Numero">Numero</option>
                      <option value="Ano">Ano</option>
                      <option value="Data Início">Data Início</option>
                      <option value="Data Final">Data Final</option>
                      <option value="Data Entrega">Data Entrega</option>
                      <option value="Cliente">Cliente</option>
                      <option value="País de Contratação">País de Contratação</option>
                      <option value="Jurisdições">Jurisdições</option>
                    </select>
                </div>
                {serachFilter !== 'Data Início' && serachFilter !== 'Data Final' && serachFilter !== 'Data Entrega'? 
                  <>
                  <div className='pageListSearcInput' ><input type="text"  placeholder={`Buca por ${serachFilter}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} /></div>
                  <div className='pageListSearcClear' onClick={handleClear}><button>X</button></div>
                  
                  {serachFilter === 'Busca Múltipla'? 
                  <div  className='pageListSearcInput2'>Busca em Comentátios, País de Contratação, Jurisdições, Analistas e Lideres de Projeto</div>
                  :''
                  }
                  </>
                  :
                  <>
                  <div>DE: <input type="date" value={data1}  onChange={(e)=>{setData1(e.target.value)}} /></div><div>ATÉ: <input type="date" value={data2}  onChange={(e)=>{setData2(e.target.value)}} /></div>
                  <div className='pageListSearcClear' onClick={handleClear2}><button>X</button></div>
                  </>
                }
              </div>
      </div>
            <div className='pagesContent'>
              {loading &&
              <div className="loading">
                  <div className='pageListProjects'>Loading....</div>
              </div>
              
              }
               {projects.filter((val)=>{
                      if(serachFilter === 'Busca Múltipla'){
                        if(search === ''){
                          return val 
                      } else if (val.data.projHiringCountry.toLowerCase().includes(search.toLowerCase()) || [val.data.projJurisdictions].toString().toLowerCase().includes(search.toLowerCase())){
                          return val
                      }
                    }
                    if(serachFilter === 'Data Entrega'){
                      if(data1 === '' || data2 ===''){
                        return val 
                    } else if (val.data.projDeliveryDate>= data1 && val.data.projDeliveryDate <= data2){
                        return val
                    }
                    }
                    if(serachFilter === 'Data Final'){
                      if(data1 === '' || data2 ===''){
                        return val 
                    } else if (val.data.projDeadLine>= data1 && val.data.projDeadLine <= data2){
                        return val
                    }
                    }
                       if(serachFilter === 'Data Início'){
                        if(data1 === '' || data2 === ''){
                          return val 
                      } else if (val.data.projStartDate >= data1 && val.data.projStartDate <= data2){
                          return val
                      }
                    }
                    if(serachFilter === 'Ano'){
                      if(search === ''){
                        return val 
                    } else if (new Date(val.data.projStartDate).getFullYear().toString().includes(search.toString())){
                        return val
                    }
                  }
                       if(serachFilter === 'Nome'){
                            if(search === ''){
                              return val
                          } else if (val.data.projName.toLowerCase().includes(search.toLowerCase())){
                              return val
                          }
                        }
                        if(serachFilter === 'Numero'){
                          if(search === ''){
                            return val
                        } else if (val.data.projNumber.toString().includes(search)){
                            return val
                        }
                        }
                        if(serachFilter === 'Cliente'){
                          if(search === ''){
                            return val
                        } else if (val.data.projClient.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'País de Contratação'){
                              if(search === ''){
                                return val
                            } else if (val.data.projHiringCountry.toLowerCase().includes(search.toLowerCase())){
                                return val
                            }
                        }
                        if(serachFilter === 'Jurisdições'){
                          if(search === ''){
                            return val
                        } else if ([val.data.projJurisdictions].toString().toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                      }).map((item, index)=>(
                    <div className='pageListProjects' key={index} >
                        <div className='pageListCLientsA'>
                            <div className="clientFit">
                              <span>Ano:</span>
                              <p>{new Date(item.data.projStartDate).getFullYear()}</p>
                            </div>
                            <div className="clientFit">
                              <span>Numero:</span>
                              <p style={{textAlign:'center', width:'100%'}}>{item.data.projNumber}</p>
                            </div>
                            <div className="clientFlex1" style={{overflow:'hidden'}}>
                              <span>Nome:</span>
                              <p style={{whiteSpace:'nowrap'}}>{item.data.projName}</p>
                            </div>
                            <div className="clientFlex1" style={{overflow:'hidden'}}>
                              <span>Cliente:</span>
                              <p style={{whiteSpace:'nowrap'}}><Link to={'/cliente/'+item.data.projClientID}>{item.data.projClient}</Link></p>
                            </div>
                            <div className="clientFit">
                              <span>Data Início:</span>
                              <p>{new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                            </div>
                            <div className="clientFit">
                              <span>Data Final:</span>
                              <p>{new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                            </div>
                            <div className="clientFit">
                              <span>Data Entrega:</span>
                              <p>{new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).substr(0, 10)}</p>
                            </div>
                            <div className="clientFit">
                              <span>Status de Cobrança:</span>
                              <p>{item.data.projeStatus2}</p>
                            </div>
                            <div className='openClientContent'>
                              {open === item.id? 
                              <button className='closeClient' onClick={hancleClose}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                              :
                              <button className='openClient' onClick={()=>(handleOpen(item.id))}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                              }
                            </div>
                      </div>
                      <div className={open === item.id? 'pageListCLientsB listActive' : 'pageListCLientsB' }>
                        <div className='pageListCLientsBLinhas'>
                            <div className="clientFlex1">
                                <span>País de contratação:</span>
                                <p>{item.data.projHiringCountry}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Juridições:</span>
                                <p>{item.data.projJurisdictions.join('  -  ')}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Valor Final Total:</span>
                                {item.data.projFinalValue?
                                <p>{item.data.projCurrency}: ${Math.round(item.data.projFinalValue).toLocaleString('pt-br')}</p>
                                :
                                <p>INFORMAR VALOR PARA PROCEDER COBRANÇA</p>
                                }
                            </div>
                            
                        </div>
                        <div className='pageListCLientsBLinhas'>
                              <div className='clientFlexTitle'>Detalhes de Faturamento</div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                            <div className="clientFlex1">
                                <span>Tipo de Faturamento:</span>
                                <p>{item.data.projClientBillInfo? item.data.projClientBillInfo: 'NÃO REGISTRADO' }</p>
                            </div> 
                            <div className="clientFlex1">
                                <span>Informações de cobrança:</span>
                                <pre className='clientFlexBoxPre'  style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.projClientBill? item.data.projClientBill: 'NÃO REGISTRADO' }</pre>
                                
                            </div>      
                        </div>
                       {item.data.projFinalValue && item.data.projClientID?
                       <button onClick={(e)=>{handleOpenDoc(e,item.id, item.data.projClientBillInfo)}} style={{fontSize:16+'px'}} className='seeClient'>INSERIR DOCUMENTO DE FATURAMENTO</button>:
                       <p style={{width:'100%', textAlign:'center'}}>CADASTRE O CLIENTE E CALCULO DE PROJETO PARA PROCEDER FATURAMENTO</p>
                        }
                        <Link to={"/projeto/"+item.id} className='seeClient'>VER Projeto</Link>
                       
                      </div>
                        
                    </div>
              ))}
       </div>
       {openDoc &&
        <div className="popUp">
            <form className="form900" onSubmit={handleChangeStatus} >
              <p>{type}: <input type="text" required onChange={(e)=>{setBilling(e.target.value)}} style={{width:'100%'}} /></p>
              <input className='editValues'  type="submit" value="ATUALIZAR PROJETO" />
              <button className='editValues' onClick={(e)=>{handleCloseDoc(e)}} >Fechar</button>
            </form>
        </div>
       }
  </div>
  )
}

export default ToBill