import React,{useState, useEffect} from 'react'
import SideBarProjects from '../../Components/SideBar/SideBarProjects'
//importando collections
import {clientsCollectionRef} from '../../services/fireref'
import { db } from '../../libs/firebase'
import { addDoc, onSnapshot, query, doc, getDoc, where, orderBy , updateDoc} from "firebase/firestore";
import { Link, useParams} from 'react-router-dom';
import Countries from '../../libs/Countries.json'

function Proposal() {
    //states do projeto
    const [proposalName, setProposalName]=useState('')
    
    const [proposalStartDate, setProposalStartDate]=useState('');
    const [propoalHiringCountry, setProposalHiringCountry]=useState('')
    const [proposalComments, setProposalComments]=useState('')
    const [proposalClient, setProposalClient]=useState('')
    const [proposalClientID, setProposalClientID]=useState('')
    const [propoalClientBill, setProposalClientBill]=useState('')
    const [proposalClientBillInfo, setProposalClientBillInfo]=useState('')
    const [novoCliente, setNovoCliente]=useState(true)
    const [clients, setClients]=useState([])
        //pegando doc
        const [loading, setLoading]=useState(false)
        const { id } = useParams();
        const [project, setProject]=useState([]);
        const [openInfo, setOpenInfo]=useState(false)
        const docRefdown = doc(db, 'proposals', id);
    
        const pegandoDoc = async()=>{
          try {
            const docSnap = await getDoc(docRefdown);
            if(docSnap.exists()) {
                setProject(docSnap.data());
            } else {
                alert("Document does not exist")
            }
          }
          catch(error) {
           alert(error)
          }
          return(pegandoDoc());
        }
        useEffect(()=>{
          pegandoDoc();
        },[]);

        const handleEditInfo =(e)=>{
            e.preventDefault()
            setProposalName(project.proposalName)
            setProposalStartDate(project.proposalStartDate)
            setProposalHiringCountry(project.propoalHiringCountry)
            setProposalComments(project.proposalComments)
            setProposalClient(project.proposalClient)
            setProposalClientID(project.proposalClientID? project.proposalClientID : '' )
            setProposalClientBill(project.propoalClientBill? project.propoalClientBill : '' )
            setProposalClientBillInfo(project.proposalClientBillInfo? project.proposalClientBillInfo :'')
            setOpenInfo(true)
        }
        const OpenClienteNao =(e)=>{
            e.preventDefault()
            setNovoCliente(true)
        }
        const OpenClientExistente =(e)=>{
            e.preventDefault()
            const c = query(clientsCollectionRef,where('clientStatus', '==', 'Ativo'), orderBy('clientName', 'asc'));
            setLoading(true);
        const changeClients = onSnapshot(c, snapshot=>{
            setClients(snapshot.docs.map(doc=>({
              data:doc.data(),
              id:doc.id
              })))
              setLoading(false);
              setNovoCliente(false)
            })
      
            return()=>{ changeClients()}
        }
        const handleAddClient =(e)=>{
            const index = e.target.selectedIndex;
            const optionElement = e.target.childNodes[index];
            const optionElementId = optionElement.getAttribute('id');
            const optionElementBill = optionElement.getAttribute('billType');
            const optionElementBillInfo = optionElement.getAttribute('billInfo');
          
            setProposalClient(e.target.value);
            setProposalClientID(optionElementId);
            setProposalClientBill(optionElementBill);
            setProposalClientBillInfo(optionElementBillInfo)
        }
        const handleCloseInfo =(e)=>{
            e.preventDefault()
            setProposalName('')
            setProposalStartDate('')
            setProposalHiringCountry('')
            setProposalComments('')
            setProposalClient('')
            setProposalClientID('')
            setProposalClientBill('')
            setProposalClientBillInfo('')
            setOpenInfo(false)
        }
        const [uploading, setUploading] = useState(false);
        const [updated, setUpdated]=useState(false);
        const [erros, setErros]=useState(false);
        const [erro, setErro]=useState('');

      const handleUpdateInfo = async (e)=>{
        e.preventDefault();
        setUploading(true);
        const docRef = doc(db, "proposals", id);
        const data ={
         proposalName,
         proposalStartDate,
        propoalHiringCountry,
           proposalComments,
          proposalClient,
          proposalClientID: proposalClientID? proposalClientID : '',
          propoalClientBill: propoalClientBill? propoalClientBill : '',
          proposalClientBillInfo: proposalClientBillInfo? proposalClientBillInfo: ''
        };
        setUploading(false);
        await updateDoc(docRef, data, { merge:true })
        .then(docRef => { setUpdated(true) })
        .catch(error => { setErros(true); setErro(error); })
       
        setUpdated(false);
        setProposalName('')
        setProposalStartDate('')
        setProposalHiringCountry('')
        setProposalComments('')
        setProposalClient('')
        setProposalClientID('')
        setProposalClientBill('')
        setProposalClientBillInfo('')
       
        setOpenInfo(false)
        
      }
      

        
  return (
    <div className='superContainer'>
        <SideBarProjects/>
        <div className='pageHeader'>
              <div className="pageTitleLeft" style={{width:'100%'}}>
                  <div className="pageTitle">proposta:&nbsp;&nbsp;&nbsp;<span>{project.proposalName}</span></div>
              </div>
            </div>
            <div className="pagesContentup">
            <div className='pageListCLients'>
                <div className='pageListCLientsA'>
                    <div className="clientFlex2">
                      <span>Nome:</span>
                      <p>{project.proposalName}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>País:</span>
                      <p>{project.propoalHiringCountry}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Cliente:</span>
                      <p>{project.proposalClient}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Data Cadastramento:</span>
                      <p>{new Date(project.proposalStartDate).toLocaleDateString('pt-br',{timeZone: 'UTC'}).slice(0,10)}</p>
                    </div>
                    
                    
                </div>
                <div className={'pageListCLientsB listActive'}>
                   
                  <div className='pageListCLientsBLinhas'>
                    <div className='clientFlex1'>
                          <div className='clientFlexTitle'>Comentários</div>
                          <div className='clientFlexBox' style={{height:'100%'}}>
                            <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{project.proposalComments}</pre>
                          </div>
                    </div>
                  </div>
                  
                  <button className='editValues' onClick={(e)=>{handleEditInfo(e)}} >EDITAR PROPOSTA</button> 
    
                
                </div>
                
              
            </div>
            </div>
            {openInfo &&
            <div className="popUp2">
                       <form onSubmit={handleUpdateInfo}className="form600">
              <div className="linhas">
                  <div className="clientName">
                    <p>Nome:</p>
                    <input type="text"  onChange={(e)=>{setProposalName(e.target.value)}} defaultValue={proposalName} />
                  </div>
              </div>
              <div className="linhas">
                  <div className="clientName">
                    <p>Data : {new Date(proposalStartDate).toLocaleDateString('pt-br',{timeZone: 'UTC'}).slice(0,10)}</p>
                    <input type="date" onChange={(e)=>{setProposalStartDate(e.target.value)}} />
                  </div>
                  <div className="clientName">
                    <p>País de Contratação: {propoalHiringCountry}</p>
                    <select onChange={(e)=>{setProposalHiringCountry(e.target.value)}} style={{width:'100%'}}>
                        <option value="">Selecione</option>
                        {[...Countries].map((item, index)=>(
                          <option key={index} value={item.country}>{item.country}</option>
                        ))}
                    </select>
                  </div>
              </div>
              <div className="linhas"><div className="clientName">Cliente: {proposalClient}</div></div>
              <div className="linhas">
              
                  <div className="clientName">
                    <p>cLIENTE JÁ CADASTRADO?:</p>
                  </div>
                  <div className="clientName">
                    <button  className='seeClient' onClick={(e)=>{OpenClientExistente(e)}} >SIM</button>
                  </div>
                  <div className="clientName">
                    <button  className='seeClient' onClick={(e)=>{OpenClienteNao(e)}} >NÃO</button>
                  </div>                 
              </div>
              {novoCliente &&
                <div className="linhas">
                  <div className="clientName">
                    <p>Nome do Cliente Não Cadastrado:</p>
                    <input type="text" required onChange={(e)=>{setProposalClient(e.target.value)}} placeholder='Nome' />
                  </div>
                </div>
              }
              {!novoCliente &&

              <div className="linhas">
                   <div className="clientName">
                 <p>Cliente Cadastrado:</p>
                <select required onChange={(e)=>{handleAddClient(e)}} style={{width:'100%'}}>
                  <option value="">Selecione</option>
                  {clients.map((item, index)=>(
                      <option key={index} id={item.id} billType={item.data.clientBillingType} billInfo={item.data.clientBillingInfo} value={item.data.clientName}>{item.data.clientName}</option>
                  ))}
                  
                </select>
                </div>
              </div>
              
              }
              <div className="linhas">
                  <div className="projF1" style={{width:'100%'}}>
                    <p>Comentários:</p>
                    <textarea id="" rows="6" style={{width:'100%'}} placeholder='Comentários'defaultValue={proposalComments}  onChange={(e)=>{setProposalComments(e.target.value)}}></textarea>
                  </div>
            </div>
            <input type="submit" value="ATUALIZAR PROPOSTA" />
            <button className='editValues' onClick={(e)=>{handleCloseInfo(e)}} >FECHAR</button>
          </form>
            </div>
            }
      </div>
      
  )
}

export default Proposal