import React from 'react'
import './unfound.css'
import Logo from '../../media/logo_branco 2.webp'
import { Link } from 'react-router-dom'

function UnfoundClient() {
  return (
    <div className="unContainer">
        <div className="unContent2">
            <div className="unLogo"><img src={Logo} alt="Logo" width={'320px'} height={'auto'}/></div>
            <div className="unInfo">
                <div className="unIcon">
                    <div className="speech">404</div>
                </div>
                <div className="unText">
                    <div className="untext-text">
                    Cliente não Cadastrado<br/>
                    Vá para <Link to='/novocliente'  className='unLink'>Criar Cliente</Link><br/>
                    E cadastre o cliente deste projeto
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UnfoundClient