import React,{useState, useEffect, useLayoutEffect} from 'react'
import { Link } from 'react-router-dom'
import {projectsCollectionRef, proposalsCollectionRef} from '../../services/fireref';
import {  onSnapshot,  query} from "firebase/firestore"

function ProjectFilters() {
  const [projects, setProjects]=useState([]);
  const [todos, setTodos]=useState()
  const [emandamento, setEmandmento]=useState()
  const [entregue, setEntregue]=useState()
  const [pausa, setPausa]=useState()
  const [analise, setAnalise]=useState()
  const [cobrar, setCobrar]=useState()
  const [receber, setReceber]=useState()
  const [depositado, setDepositado]=useState()
  const [pagar, setPagar]=useState()
  const [pago, setPago]=useState()
  const [finalizado, setFinalizado]=useState()
  const [propo, setProposals]=useState([])
  const [propostas, setPropostas]=useState()
   //pegando infos projeto
   useEffect(()=>{
        
    const q = query(projectsCollectionRef);
    const p = query(proposalsCollectionRef);
    
    const change = onSnapshot(q, snapshot=>{
      setProjects(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
        
    })
    
     
     const change2 = onSnapshot(p, snapshot=>{
       setProposals(snapshot.docs.map(doc=>({
         data:doc.data(),
         id:doc.id
         })))

     })
 

    return()=>{change(); change2()}

},[]);

useEffect(()=>{
  setTodos(projects.filter((val)=>{if(val.data.projStatus1 !== "deletado"){return val}}).length)
  setEmandmento(projects.filter((val)=>{if(val.data.projStatus1.includes("Em Andamento")){return val}}).length)
  setEntregue((projects.filter((val)=>{if(val.data.projStatus1.includes("Entregue")){return val}}).length))
  setPausa((projects.filter((val)=>{if(val.data.projStatus1.includes("Em Pausa")){return val}}).length))
  setAnalise((projects.filter((val)=>{if(val.data.projStatus1.includes("Em Análise")){return val}}).length))
  setCobrar((projects.filter((val)=>{if(val.data.projeStatus2? val.data.projeStatus2.includes("A Cobrar"):''){return val}}).length))
  setReceber((projects.filter((val)=>{if(val.data.projeStatus2? val.data.projeStatus2.includes("A Receber"):''){return val}}).length))
  setDepositado((projects.filter((val)=>{if(val.data.projeStatus2? val.data.projeStatus2.includes("Recebido"):''){return val}}).length))
  setPagar((projects.filter((val)=>{if(val.data.projeStatus3? val.data.projeStatus3.includes("A Pagar"):''){return val}}).length))
  setPago((projects.filter((val)=>{if(val.data.projeStatus3? val.data.projeStatus3.includes("Pago"):''){return val}}).length))
  setFinalizado(projects.filter((val)=>{if(val.data.projStatus1.includes("Finalizado") && val.data.projeStatus2? val.data.projeStatus2.includes("Finalizado"):'' && val.data.projeStatus3? val.data.projeStatus3.includes("Finalizado"):'' ){return val}}).length)
  setPropostas(propo.length)
},[projects])



  return (
    <div className='SBNav'>
    <div className='SBsectionName'>FILTROS DE PROJETO</div>
    <div className="SBContent">
        <Link to='/'>em andamento - {emandamento}</Link>
        <Link to='/projetos/entregues'>entregue - {entregue}</Link>
        <Link to='/projetos/empausa'>em pausa - {pausa}</Link>
        <Link to='/projetos/emanalise'>em análise - {analise}</Link>
        <Link to='/projetos/acobrar'>a cobrar - {cobrar}</Link>
        <Link to='/projetos/areceber'>a receber - {receber}</Link>
        <Link to='/projetos/depositado'>depositado - {depositado}</Link>
        <Link to='/projetos/apagar'>a pagar - {pagar}</Link>
        <Link to='/projetos/analistasapagar'>analistas a pagar</Link>
        <Link to='/projetos/lideresapagar'>líderes a pagar</Link>
        <Link to='/projetos/pago'>pago - {pago}</Link>
        <Link to='/projetos/finalizado'>finalizado - {finalizado}</Link>
        <Link to='/projetos/todos'>todos - {todos}</Link>
        <Link to='/projetos/propostas'>propostas - {propostas}</Link>
    </div>
</div>
  )
}

export default ProjectFilters