import React, {useState, useEffect} from 'react'
import { Link, useParams} from 'react-router-dom';
import { db } from '../../libs/firebase';
import { doc, getDoc, updateDoc, query, onSnapshot, orderBy} from "firebase/firestore"
import '../pages.css'
import SideBarCollabs from '../../Components/SideBar/SideBarCollabs'
import Countries from '../../libs/Countries.json'
import {projectsCollectionRef} from '../../services/fireref';

function Collabortor() {
 
    //states de informações
    const [collabType, setCollabType]=useState('');
    const [collabCategory, setCollabCategory]=useState('')
    const [collabName, setCollabName]=useState('');
    const [collabEmail, setCollabEmail]=useState('');
    const [collabPhone, setCollabPhone]=useState('');
    const [collabCountry, setCollabCountry]=useState('');
    const [collabJurisdictions, setCollabJaurisdictions]=useState([]);
    const [collabStart, setCollabStart]=useState('');
    const [collabRecomendation, setCollabRecomendation]=useState('');
    const [collabComments, setCollabComments]=useState('');
    const [collabAccount, setCollabAccount]=useState('');
    const [collabNDA, setCollabNDA]=useState('');
    const [collabNDADate, setCollabNDADate]=useState('');
    const [collabNDALink, setCollabNDALink]=useState('');
    const [collabContract, setCollabContract]=useState('');
    const [collabContractDate, setCollabContractDate]=useState('');
    const [collabContractLink, setCollabContractLink]=useState('');

     //states para abrir e update
     const [uploading, setUploading] = useState(false);
     const [updated, setUpdated]=useState(false);
     const [erros, setErros]=useState(false);
     const [erro, setErro]=useState('');
     const [open, setOpen]=useState(false);
     const [expire1, setExpire1]=useState('')
     const [expire2, setExpire2]=useState('')
 
     //pegando doc
     const { id } = useParams();
     const [collab, setCollab]=useState([]);
     const docRefdown = doc(db, 'collaborators', id);
 
     const pegandoDoc = async()=>{
       try {
         const docSnap = await getDoc(docRefdown);
         if(docSnap.exists()) {
           setCollab(docSnap.data());
         } else {
             alert("Document does not exist")
         }
       }
       catch(error) {
        alert(error)
       }
       return(pegandoDoc());
     }
     useEffect(()=>{
       pegandoDoc();
     });
      

    // abrindo e update
    //função abrir e setar valores:
  const handleOpen =()=>{
    setOpen(true);
    setCollabType(collab.collabType);
    setCollabCategory(collab.collabCategory)
    setCollabName(collab.collabName);
    setCollabEmail(collab.collabEmail);
    setCollabPhone(collab.collabPhone);
    setCollabCountry(collab.collabCountry);
    setCollabJaurisdictions(collab.collabJurisdictions);
    setCollabStart(collab.collabStart);
    setCollabRecomendation(collab.collabRecomendation);
    setCollabComments(collab.collabComments);
    setCollabAccount(collab.collabAccount);
    setCollabNDA(collab.collabNDA);
    setCollabNDALink(collab.collabNDALink);
    setCollabContract(collab.collabContract);
    setCollabContractLink(collab.collabContractLink);
    setCollabNDADate(collab.collabContractDate)
    setCollabContractDate(collab.collabContractDate)
    setExpire1(collab.collabNDADate? collab.collabNDADate.toDate().toLocaleString('pt-BR', {timeZone: 'UTC'}).substr(0, 10):'');
    setExpire2(collab.collabContractDate? collab.collabContractDate.toDate().toLocaleString('pt-BR', {timeZone: 'UTC'}).substr(0, 10):'')
}

 //mudando estados para boleanos

 useEffect(()=>{
    if(collabNDA === 'true'){
      setCollabNDA(true)
      
    }
    if(collabNDA === 'false'){
      setCollabNDA(false)
      setCollabNDADate('')
        setCollabNDALink('')
    }
  },[collabNDA])

  useEffect(()=>{
    if(collabContract === 'true'){
      setCollabContract(true)
    }
    if(collabContract === 'false'){
      setCollabContract(false)
      setCollabContractLink('')
      setCollabContractDate('')
    }
  },[collabContract])
  //fechando pop up
  const handleClose =()=>{
    setOpen(false)
  }
  //udatedoc
  const handleUpdate = async (e)=>{
    e.preventDefault();
    setUploading(true);
    const docRef = doc(db, "collaborators", id);
    const data ={
        collabType,
        collabCategory,
        collabName,
        collabEmail,
        collabPhone,
        collabCountry,
        collabJurisdictions,
        collabStart,
        collabRecomendation,
        collabComments,
        collabAccount,
        collabNDA,
        collabNDADate,
        collabNDALink,
        collabContract,
        collabContractDate,
        collabContractLink 
    };
    setUploading(false);
    await updateDoc(docRef, data, { merge:true })
    .then(docRef => { setUpdated(true) })
    .catch(error => { setErros(true); setErro(error); })
   
    setUpdated(false);
    setOpen(false);
    setCollabType('');
    setCollabCategory('')
    setCollabName('');
    setCollabEmail('');
    setCollabPhone('');
    setCollabCountry('');
    setCollabJaurisdictions('');
    setCollabStart('');
    setCollabRecomendation('');
    setCollabComments('');
    setCollabAccount('');
    setCollabNDA('');
    setCollabNDADate();
    setCollabNDALink('');
    setCollabContract('');
    setCollabContractDate();
    setCollabContractLink('');
    
  }

    //states para pegar infos e fazer filtro
    const [projects, setProjects]=useState([]);
    const [loading, setLoading]=useState(false);
    const [reais, setReais]=useState([]);
    const [dolar, setDolar]=useState([])
    const [reais2, setReais2]=useState([]);
    const [dolar2, setDolar2]=useState([])
 
 
  //pegando infos projeto
  useEffect(()=>{
              
   const p = query(projectsCollectionRef,orderBy('projNumber', 'desc'));
   setLoading(true);
   const change = onSnapshot(p, snapshot=>{
     setProjects(snapshot.docs.map(doc=>({
       data:doc.data(),
       id:doc.id
       })))
       setLoading(false);
   })
 
   return()=>{change()}
 
 },[]);
 const handleAddReais =(e, payment)=>{
  e.preventDefault()
  const newItemR = Math.round(payment)
  setReais([...reais, newItemR])
  
 }
 const handleAddDolar =(e, payment)=>{
  e.preventDefault()
  const newItem = Math.round(payment)
  setDolar([...dolar, newItem])
 }

 const handleAll = (e)=>{
  e.preventDefault()
  let reaislist = projects.filter((val)=>{ if(val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()) && val.data.projAnalistas.map((item)=>item.paymentStatus).includes(false):''){return val}}).map((item)=>item.data.projAnalistas.filter((v)=>{if (v.link === id && v.paymentStatus === false && v.paymentCurrency==='BRL'){return v}}).map((its)=>its.payment!==''? Math.round(its.payment):0)).toString().split(',')
  var  length = reaislist.length;
  var reaisNumeros = [];
  for (var i = 0; i < length; i++)
  reaisNumeros.push(Number(reaislist[i]))

  let dolarlist = projects.filter((val)=>{ if(val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()) && val.data.projAnalistas.map((item)=>item.paymentStatus).includes(false):''){return val}}).map((item)=>item.data.projAnalistas.filter((v)=>{if (v.link === id && v.paymentStatus === false && v.paymentCurrency==='USD'){return v}}).map((its)=>its.payment!==''? Math.round(its.payment):0)).toString().split(',')
  var  lengthD = dolarlist.length;
  var dolarNumeros = [];
  for (var d = 0; d < lengthD; d++)
  dolarNumeros.push(Number(dolarlist[d]))


  setReais(reaisNumeros)
  setDolar(dolarNumeros)
 }

 const handleAll2 = (e)=>{
  e.preventDefault()
  let reaislist = projects.filter((val)=>{ if(val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()) && val.data.projAnalistas.map((item)=>item.paymentStatus).includes(true):''){return val}}).map((item)=>item.data.projAnalistas.filter((v)=>{if (v.link === id && v.paymentStatus === true && v.paymentCurrency==='BRL'){return v}}).map((its)=>its.payment!==''? Math.round(its.payment):0)).toString().split(',')
  var  length = reaislist.length;
  var reaisNumeros = [];
  for (var i = 0; i < length; i++)
  reaisNumeros.push(Number(reaislist[i]))

  let dolarlist = projects.filter((val)=>{ if(val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()) && val.data.projAnalistas.map((item)=>item.paymentStatus).includes(true):''){return val}}).map((item)=>item.data.projAnalistas.filter((v)=>{if (v.link === id && v.paymentStatus === true && v.paymentCurrency==='USD'){return v}}).map((its)=>its.payment!==''? Math.round(its.payment):0)).toString().split(',')
  var  lengthD = dolarlist.length;
  var dolarNumeros = [];
  for (var d = 0; d < lengthD; d++)
  dolarNumeros.push(Number(dolarlist[d]))


  setReais2(reaisNumeros)
  setDolar2(dolarNumeros)
 }

 const handleAllLider = (e)=>{
  e.preventDefault()
  let reaislist = projects.filter((val)=>{ if(val.data.projLideres? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()) && val.data.projLideres.map((item)=>item.paymentStatus).includes(false):''){return val}}).map((item)=>item.data.projLideres.filter((v)=>{if (v.link === id && v.paymentStatus === false && v.paymentCurrency==='BRL'){return v}}).map((its)=>its.paymentValue!==''? Math.round(its.paymentValue):0)).toString().split(',')
  var  length = reaislist.length;
  var reaisNumeros = [];
  for (var i = 0; i < length; i++)
  reaisNumeros.push(Number(reaislist[i]))

  let dolarlist = projects.filter((val)=>{ if(val.data.projLideres? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()) && val.data.projLideres.map((item)=>item.paymentStatus).includes(false):''){return val}}).map((item)=>item.data.projLideres.filter((v)=>{if (v.link === id && v.paymentStatus === false && v.paymentCurrency==='USD'){return v}}).map((its)=>its.paymentValue!==''? Math.round(its.paymentValue):0)).toString().split(',')
  var  lengthD = dolarlist.length;
  var dolarNumeros = [];
  for (var d = 0; d < lengthD; d++)
  dolarNumeros.push(Number(dolarlist[d]))


  setReais(reaisNumeros)
  setDolar(dolarNumeros)
 }
 const handleAllLider2 = (e)=>{
  e.preventDefault()
  let reaislist = projects.filter((val)=>{ if(val.data.projLideres? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()) && val.data.projLideres.map((item)=>item.paymentStatus).includes(true):''){return val}}).map((item)=>item.data.projLideres.filter((v)=>{if (v.link === id && v.paymentStatus === true && v.paymentCurrency==='BRL'){return v}}).map((its)=>its.paymentValue!==''? Math.round(its.paymentValue):0)).toString().split(',')
  var  length = reaislist.length;
  var reaisNumeros = [];
  for (var i = 0; i < length; i++)
  reaisNumeros.push(Number(reaislist[i]))

  let dolarlist = projects.filter((val)=>{ if(val.data.projLideres? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()) && val.data.projLideres.map((item)=>item.paymentStatus).includes(true):''){return val}}).map((item)=>item.data.projLideres.filter((v)=>{if (v.link === id && v.paymentStatus === true && v.paymentCurrency==='USD'){return v}}).map((its)=>its.paymentValue!==''? Math.round(its.paymentValue):0)).toString().split(',')
  var  lengthD = dolarlist.length;
  var dolarNumeros = [];
  for (var d = 0; d < lengthD; d++)
  dolarNumeros.push(Number(dolarlist[d]))

  console.log(reais2)
  setReais2(reaisNumeros)
  setDolar2(dolarNumeros)
 }

  return (
    <div className='superContainer'>
        <SideBarCollabs/>
        {loading &&
                <div className="loading">
                    <div className='pageListProjects'>Loading....</div>
                </div>
                
                }
        <div className='pageHeader'>
            <div className="pageTitleLeft">
                <div className="pageTitle">Colaborator:&nbsp;&nbsp;&nbsp;<span>{collab.collabName}</span>  </div>
            </div>
        </div>
       
        <div className="pagesContentup">
            <div className='pageListCLients'>
                <div className='pageListCLientsA'>
                    <div className="clientFlex1">
                        <span>Nome:</span>
                        <p>{collab.collabName}</p>
                    </div>
                    <div className="clientW0">
                        <span>Tipo:</span>
                        <p>{collab.collabType}</p>
                    </div>
                    <div className="clientW0">
                        <span>Categoria:</span>
                        <p>{collab.collabCategory}</p>
                    </div>
                    <div className="clientW0">
                        <span>País:</span>
                        <p>{collab.collabCountry}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Profissão:</span>
                        <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{collab.collabJurisdictions? collab.collabJurisdictions.join(' - '):''}</p>
                    </div>
                    <div className="clientW0">
                        <span>Desde:</span>
                        <p>{collab.collabStart}</p>
                    </div>
                </div>
                <div className={'pageListCLientsB listActive'}>
                    <div className='pageListCLientsBLinhas'>
                        <div className="clientFlex1">
                        <span>Email:</span>
                        <p style={{textTransform:'lowercase'}}>{collab.collabEmail}</p>
                        </div>
                        <div className="clientFlex1">
                        <span>Telefone:</span>
                        <p>{collab.collabPhone? collab.collabPhone : 'Não Registrado' }</p>
                        </div>
                        <div className="clientFlex1">
                        <span>Referência/Origem:</span>
                        <p>{collab.collabRecomendation}</p>
                        </div>
                    </div>
                
                <div className='pageListCLientsBLinhas'>
                    <div className='clientFlex1'>
                        <div className='clientFlexTitle'>Comentários</div>
                        <div className='clientFlexBox' style={{height:'100%'}}>
                        <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{collab.collabComments}</pre>
                        </div>
                    </div>
                    <div className='clientFlex1'>
                        <div className='clientFlexTitle'>DADOS DE PAGAMENTO</div>
                        <div className='clientFlexBox' style={{height:'100%'}}>
                        <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{collab.collabAccount}</pre>
                        </div>
                    </div>
                    <div className='clientFlex1'>
                        <div className='clientFlexTitle'>STATUS DE NDA</div>
                        <div className='pageListCLientsBLinhas'
                        style={collab.collabNDADate? 
                          Math.floor((new Date(collab.collabNDADate.toDate()) - new Date())/ (1000 * 60 * 60 * 24)) <= 0? {backgroundColor:'#710606'}: {}
                          : {}
                        }>
                            <div className="clientW01">
                                <span>Tem NDA?</span>
                                <p>{collab.collabNDA? 'SIM': 'NÃO'}</p>
                            </div>
                            <div className="clientW01">
                                <span>Data de expiração:</span>
                                {collab.collabNDADate?
                                <p>{collab.collabNDADate.toDate().toLocaleString('pt-BR', {timeZone: 'UTC'}).substr(0, 10)}</p>
                                : <p></p>}
                            </div>
                            <div className="clientFlex1">
                                <span>Link do Documento:</span>
                                {collab.collabNDALink?
                                <p style={{maxWidth: 15+'ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textTransform:'lowercase'}}><a href={collab.collabNDALink} target="_blank" rel="noopener noreferrer" style={{textTransform:'lowercase'}}>{collab.collabNDALink}</a></p>
                                : <p></p>}
                            </div>
                        </div>
                        <div className='clientFlexTitle'>STATUS CONTRATO</div>
                        <div className='pageListCLientsBLinhas'
                        style={collab.collabContractDate? 
                          Math.floor((new Date(collab.collabContractDate.toDate()) - new Date())/ (1000 * 60 * 60 * 24)) <= 0? {backgroundColor:'#710606'}: {}
                          : {}
                          }>
                            <div className="clientW01">
                                <span>Tem Contrato?</span>
                                <p>{collab.collabContract? 'SIM': 'NÃO'}</p>
                            </div>
                            <div className="clientW01">
                                <span>Data de expiração:</span>
                                {collab.collabContractDate?
                                <p>{collab.collabContractDate.toDate().toLocaleString('pt-BR', {timeZone: 'UTC'}).substr(0, 10)}</p>
                                : <p></p>}
                            </div>
                            <div className="clientFlex1">
                                <span>Link do Documento:</span>
                                {collab.collabContractLink?
                                <p style={{maxWidth: 15+'ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textTransform:'lowercase'}}><a href={collab.collabContractLink} target="_blank" rel="noopener noreferrer" style={{textTransform:'lowercase'}} >{collab.collabContractLink}</a></p>
                                : <p></p>}
                            </div>
                        </div>
                       
                    </div>
                    
                </div>
                
                { collab.collabType === 'analista' && collab.collabCategory!== 'Líder de Projeto' &&
                <div className={'pageListCLientsB listActive'}>

                {loading &&
                <div className="loading">
                    <div className='pageListProjects'>Loading....</div>
                </div>
                
                }
                       {!loading &&
                       <>
                       <div className="linhaCentral">
                          <div className="linhasTitle" style={{width:'100%'}} >PROJETOS EM ANDAMENTO - {
                            projects.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento') && val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()):''){return val}}).map((item)=>(item.data.projAnalistas).filter((v)=>{if(v.link===id){return v}})).length
                          }</div>
                        </div>
                       
                          
                          {projects.filter((val)=>{
                            if(val.data.projStatus1.includes('Em Andamento') && val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()):''
                            ){
                              return val
                            }
                          }).map((item, index)=>(
                           
                            <div className={'pageListCLientsB listActive'} key={index}>
                               {item.data.projAnalistas.filter((val)=>{if(
                              val.link === id 
                            ){return val}}).map((its)=>(
                                  <div className={'pageListCLientsBLinhas'}>
                                    <div className="clientFlex1">
                                        <span>Nome do Projeto:</span>
                                        <p> <Link to={'/projeto/'+item.id}>{item.data.projName}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                        <span>Status do Projeto:</span>
                                        <p> {item.data.projStatus1}</p>
                                    </div>
                                    <div className="clientFlex1">
                                        <span>Data de Inicio:</span>
                                        <p> {item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Data Final:</span>
                                      <p>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                  </div>
                                  </div>
                                  ))}
                                  
                            </div>
                            
                          ))}
                          <div className="linhaCentral">
                          <Link className='editValuesG' to={'/tabela/emandamento/analista/'+id}>EXPORTAR TABELA</Link>
                            </div>
                       
                       
                        <div className="linhaCentral" style={{marginTop:30+'px'}}>
                          <div className="linhasTitle"  style={{width:'100%'}}>PROJETOS A PAGAR</div>
                        </div>
                       
                          
                          {projects.filter((val)=>{
                            if(val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()) && val.data.projAnalistas.map((item)=>item.paymentStatus).includes(false):'' 
                            ){
                              return val
                            }
                          }).map((item, index)=>(
                           
                            <div className={'pageListCLientsB listActive'} key={index}>
                               {item.data.projAnalistas.filter((val)=>{if(
                              val.link === id && val.paymentStatus === false
                            ){return val}}).map((its)=>(
                                  <div className={'pageListCLientsBLinhas'}>
                                    <div className="clientFlex1">
                                        <span>Nome do Projeto:</span>
                                        <p> <Link to={'/projeto/'+item.id}>{item.data.projName}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                        <span>Status do Projeto:</span>
                                        <p> {item.data.projStatus1}</p>
                                    </div>
                                    <div className="clientFlex1">
                                        <span>Data de Inicio:</span>
                                        <p> {item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Data Final:</span>
                                      <p>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                  </div>
                                  <div className="clientFlex1">
                                <span>Data Entrega:</span>
                                <p>{item.data.projDeliveryDate? new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</p>
                            </div>
                                     
                                      <div className="clientFlex1">
                                      <span>valor BRL:</span>
                                      {its.paymentCurrency === 'BRL'?
                                           <p>{its.paymentCurrency}: $ {Math.round(its.payment)}</p>
                                          :
                                          ''
                                      }
                                     
                                  </div>
                                  <div className="clientFlex1">
                                      <span>valor em USD:</span>
                                      {its.paymentCurrency === 'USD'?
                                           <p>{its.paymentCurrency}: $ {Math.round(its.payment)}</p>
                                          :
                                          ''
                                      }
                                  </div>
                                  <div className="clientFlex1">
                                    <button className='seeClient' onClick={its.paymentCurrency === 'BRL'? (e)=>handleAddReais(e,its.payment):(e)=>handleAddDolar(e,its.payment)}>SOMAR</button>
                                  </div>
                             
                                   
                                    <div className="clientFlex1">
                                    <span>Proceder Pagamento:</span>
                                    <p><Link to={"/projeto/pagamento/"+item.id} target='_blank' >PAGAR PROJETO</Link></p>
                                  </div>
                                  </div>
                                  ))}
                            </div>
                          ))} 
                       
                        
                        
                        <div className={'pageListCLientsB listActive'}>
                            <div className={'pageListCLientsBLinhas'}>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                                <div className="clientFlex1">
                                    <p>TOTAIS:</p>
                                </div>
                                <div className="clientFlex1">
                                      <span>TOTAL EM BRL:</span>
                                      <p>BRL: ${reais.reduce((acc, curr) => acc + curr, 0)}</p>
                                  </div>
                                  <div className="clientFlex1">
                                      <span>TOTAL EM USD:</span>
                                      <p>USD: ${dolar.reduce((acc, curr) => acc + curr, 0)}</p>
                                  </div>
                                  <div className="clientFlex1" style={{border:0}}>
                                  <button className='seeClient' onClick={(e)=>{handleAll(e)}}>somar todos</button>
                                  </div>
                                  <div className="clientFlex1" style={{border:0}}>
                                  <button className='seeClient' onClick={(e)=>{e.preventDefault();setDolar([]);setReais([])}}>limpar soma</button>
                                  </div>
                                  
                                 
                              </div>
                        </div>
                        <div className="linhaCentral">
                          <Link className='editValuesG' to={'/tabela/apagar/analista/'+id}>EXPORTAR TABELA</Link>
                        </div>

                         <div className="linhaCentral"  style={{marginTop:30+'px'}}>
                         <div className="linhasTitle" style={{width:'100%'}}>PROJETOS PAGOS</div>
                       </div>
                      
                         
                         {projects.filter((val)=>{
                           if(val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.link).toString().includes(id.toString()) && val.data.projAnalistas.map((item)=>item.paymentStatus).includes(true):'' 
                           ){
                             return val
                           }
                         }).map((item, index)=>(
                          
                           <div className={'pageListCLientsB listActive'} key={index}>
                              {item.data.projAnalistas.filter((val)=>{if(
                             val.link === id && val.paymentStatus === true
                           ){return val}}).map((its)=>(
                                 <div className={'pageListCLientsBLinhas'}>
                                   <div className="clientFlex1">
                                       <span>Nome do Projeto:</span>
                                       <p> <Link to={'/projeto/'+item.id}>{item.data.projName}</Link></p>
                                   </div>
                                   <div className="clientFlex1">
                                       <span>Status do Projeto:</span>
                                       <p> {item.data.projStatus1}</p>
                                   </div>
                                   <div className="clientFlex1">
                                        <span>Data de Inicio:</span>
                                        <p> {item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Data Final:</span>
                                      <p>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                  </div>
                                    
                                     <div className="clientFlex1">
                                     <span>valor BRL:</span>
                                     {its.paymentCurrency === 'BRL'?
                                          <p>{its.paymentCurrency}: $ {Math.round(its.payment)}</p>
                                         :
                                         ''
                                     }
                                    
                                 </div>
                                 <div className="clientFlex1">
                                     <span>valor em USD:</span>
                                     {its.paymentCurrency === 'USD'?
                                          <p>{its.paymentCurrency}: $ {Math.round(its.payment)}</p>
                                         :
                                         ''
                                     }
                                 </div>
                                 <div className="clientFlex1">
                                     <span>Data Pagamento:</span>
                                          <p>{new Date(its.paymentDate).toLocaleDateString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                                 </div>
                                
                            
                                  
                                   <div className="clientFlex1">
                                   <span>Atualizar Pagamento</span>
                                   <p><Link to={"/projeto/pagamento/"+item.id} target='_blank' >ATUALIZAR PAGAMENTO</Link></p>
                                 </div>
                                 </div>
                                 ))}
                           </div>
                         ))}
                         <div className={'pageListCLientsB listActive'}>
                            <div className={'pageListCLientsBLinhas'}>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                                <div className="clientFlex1">
                                    <p>TOTAIS:</p>
                                </div>
                                <div className="clientFlex1">
                                      <span>TOTAL EM BRL:</span>
                                      <p>BRL: ${reais2.reduce((acc, curr) => acc + curr, 0)}</p>
                                  </div>
                                  <div className="clientFlex1">
                                      <span>TOTAL EM USD:</span>
                                      <p>USD: ${dolar2.reduce((acc, curr) => acc + curr, 0)}</p>
                                  </div>
                                  <div className="clientFlex1" style={{border:0}}>
                                  <button className='seeClient' onClick={(e)=>{handleAll2(e)}}>somar todos</button>
                                  </div>
                                  <div className="clientFlex1" style={{border:0}}>
                                  <button className='seeClient' onClick={(e)=>{e.preventDefault();setDolar2([]);setReais2([])}}>limpar soma</button>
                                  </div>
                                  
                                 
                              </div>
                        </div>
                        <div className="linhaCentral">
                          <Link className='editValuesG' to={'/tabela/pago/analista/'+id}>EXPORTAR TABELA</Link>
                        </div>
                      
                       </>
                       }

                        
            </div>
            
            }
            
             { collab.collabType ==='analista' && collab.collabCategory=== 'Líder de Projeto' &&
                <div className={'pageListCLientsB listActive'}>

                {loading &&
                <div className="loading">
                    <div className='pageListProjects'>Loading....</div>
                </div>
                
                }
                       {!loading &&
                       <>
                       <div className="linhaCentral">
                          <div className="linhasTitle" style={{width:'100%'}} >PROJETOS EM ANDAMENTO - {
                            projects.filter((val)=>{if(val.data.projStatus1.includes('Em Andamento') && val.data.projLideres? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()):''){return val}}).map((item)=>(item.data.projLideres).filter((v)=>{if(v.link===id){return v}})).length
                          }</div>
                        </div>
                       
                          
                          {projects.filter((val)=>{
                            if(val.data.projStatus1.includes('Em Andamento') && val.data.projLideres? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()):''
                            ){
                              return val
                            }
                          }).map((item, index)=>(
                           
                            <div className={'pageListCLientsB listActive'} key={index}>
                               {item.data.projLideres.filter((val)=>{if(
                              val.link === id 
                            ){return val}}).map((its)=>(
                                  <div className={'pageListCLientsBLinhas'}>
                                    <div className="clientFlex1">
                                        <span>Nome do Projeto:</span>
                                        <p> <Link to={'/projeto/'+item.id}>{item.data.projName}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                        <span>Status do Projeto:</span>
                                        <p> {item.data.projStatus1}</p>
                                    </div>
                                    
                                    <div className="clientFlex1">
                                        <span>Data de Inicio:</span>
                                        <p> {item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Data Final:</span>
                                      <p>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                  </div>
                                
                                  </div>
                                  ))}
                                  
                            </div>
                          ))}
                          
                        <div className="linhaCentral">
                          <Link className='editValuesG' to={'/tabela/emandamento/analista/'+id}>EXPORTAR TABELA</Link>
                        </div>
                        <div className="linhaCentral" style={{marginTop:30+'px'}}>
                          <div className="linhasTitle" style={{width:'100%'}} >PROJETOS A PAGAR</div>
                        </div>
                       
                          
                          {projects.filter((val)=>{
                            if(val.data.projAnalistas? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()) && val.data.projLideres.map((item)=>item.paymentStatus).includes(false):'' 
                            ){
                              return val
                            }
                          }).map((item, index)=>(
                           
                            <div className={'pageListCLientsB listActive'} key={index}>
                               {item.data.projLideres.filter((val)=>{if(
                              val.link === id && val.paymentStatus === false
                            ){return val}}).map((its)=>(
                                  <div className={'pageListCLientsBLinhas'}>
                                    <div className="clientFlex1">
                                        <span>Nome do Projeto:</span>
                                        <p> <Link to={'/projeto/'+item.id}>{item.data.projName}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                        <span>Status do Projeto:</span>
                                        <p> {item.data.projStatus1}</p>
                                    </div>
                                    <div className="clientFlex1">
                                        <span>Data de Inicio:</span>
                                        <p> {item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Data Final:</span>
                                      <p>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                  </div>
                                  <div className="clientFlex1">
                                <span>Data Entrega:</span>
                                <p>{item.data.projDeliveryDate? new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</p>
                            </div>
                                     
                                      <div className="clientFlex1">
                                      <span>valor BRL:</span>
                                      {its.paymentCurrency === 'BRL'?
                                           <p>{its.paymentCurrency}: $ {Math.round(its.paymentValue)}</p>
                                          :
                                          ''
                                      }
                                     
                                  </div>
                                  <div className="clientFlex1">
                                      <span>valor em USD:</span>
                                      {its.paymentCurrency === 'USD'?
                                           <p>{its.paymentCurrency}: $ {Math.round(its.paymentValue)}</p>
                                          :
                                          ''
                                      }
                                  </div>
                                  <div className="clientFlex1">
                                    <button className='seeClient' onClick={its.paymentCurrency === 'BRL'? (e)=>handleAddReais(e,its.paymentValue):(e)=>handleAddDolar(e,its.paymentValue)}>SOMAR</button>
                                  </div>
                             
                                   
                                    <div className="clientFlex1">
                                    <span>Proceder Pagamento:</span>
                                    <p><Link to={"/projeto/pagamento/"+item.id} target='_blank'  >PAGAR PROJETO</Link></p>
                                  </div>
                                  </div>
                                  ))}
                            </div>
                          ))} 
                       
                        
                        <div className={'pageListCLientsB listActive'}>
                            <div className={'pageListCLientsBLinhas'}>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                                <div className="clientFlex1">
                                    <p>TOTAIS:</p>
                                </div>
                                <div className="clientFlex1">
                                      <span>TOTAL EM BRL:</span>
                                      <p>BRL: ${reais.reduce((acc, curr) => acc + curr, 0)}</p>
                                  </div>
                                  <div className="clientFlex1">
                                      <span>TOTAL EM USD:</span>
                                      <p>USD: ${dolar.reduce((acc, curr) => acc + curr, 0)}</p>
                                  </div>
                                  <div className="clientFlex1" style={{border:0}}>
                                  <button className='seeClient' onClick={(e)=>{handleAllLider(e)}}>somar todos</button>
                                  </div>
                                  <div className="clientFlex1" style={{border:0}}>
                                  <button className='seeClient' onClick={(e)=>{e.preventDefault();setDolar([]);setReais([])}}>limpar soma</button>
                                  </div>
                                  

                              </div>
                        </div>
                        <div className="linhaCentral">
                          <Link className='editValuesG' to={'/tabela/apagar/analista/'+id}>EXPORTAR TABELA</Link>
                        </div>
                        
                        <div className="linhaCentral" style={{marginTop:30+'px'}}>
                         <div className="linhasTitle"style={{width:'100%'}} >PROJETOS PAGOS</div>
                       </div>
                      
                         
                         {projects.filter((val)=>{
                           if(val.data.projLideres? val.data.projLideres.map((item)=>item.link).toString().includes(id.toString()) && val.data.projLideres.map((item)=>item.paymentStatus).includes(true):'' 
                           ){
                             return val
                           }
                         }).map((item, index)=>(
                          
                           <div className={'pageListCLientsB listActive'} key={index}>
                              {item.data.projLideres.filter((val)=>{if(
                             val.link === id && val.paymentStatus === true
                           ){return val}}).map((its)=>(
                                 <div className={'pageListCLientsBLinhas'}>
                                   <div className="clientFlex1">
                                       <span>Nome do Projeto:</span>
                                       <p> <Link to={'/projeto/'+item.id}>{item.data.projName}</Link></p>
                                   </div>
                                   <div className="clientFlex1">
                                       <span>Status do Projeto:</span>
                                       <p> {item.data.projStatus1}</p>
                                   </div>
                                   <div className="clientFlex1">
                                        <span>Data de Inicio:</span>
                                        <p> {item.data.projStartDate? new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Data Final:</span>
                                      <p>{item.data.projDeadLine? new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10):''}</p>
                                  </div>
                                  <div className="clientFlex1">
                                <span>Data Entrega:</span>
                                <p>{item.data.projDeliveryDate? new Date(item.data.projDeliveryDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10) : 'Aguardando Entrega' }</p>
                            </div>
                                    
                                     <div className="clientFlex1">
                                     <span>valor BRL:</span>
                                     {its.paymentCurrency === 'BRL'?
                                          <p>{its.paymentCurrency}: $ {Math.round(its.paymentValue)}</p>
                                         :
                                         ''
                                     }
                                    
                                 </div>
                                 <div className="clientFlex1">
                                     <span>valor em USD:</span>
                                     {its.paymentCurrency === 'USD'?
                                          <p>{its.paymentCurrency}: $ {Math.round(its.paymentValue)}</p>
                                         :
                                         ''
                                     }
                                 </div>
                                 <div className="clientFlex1">
                                     <span>Data Pagamento:</span>
                                          <p>{its.paymentDate? new Date(its.paymentDate).toLocaleDateString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Não Registrada'}</p>
                                 </div>
                                
                            
                                  
                                   <div className="clientFlex1">
                                   <span>Atualizar Pagamento</span>
                                   <p><Link to={"/projeto/pagamento/"+item.id} target='_blank'  >ATUALIZAR PAGAMENTO</Link></p>
                                 </div>
                                 </div>
                                 ))}
                           </div>
                         ))}
                         <div className={'pageListCLientsB listActive'}>
                            <div className={'pageListCLientsBLinhas'}>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                            <div className="clientFlex1" style={{border:0}}></div>
                                <div className="clientFlex1">
                                    <p>TOTAIS:</p>
                                </div>
                                <div className="clientFlex1">
                                      <span>TOTAL EM BRL:</span>
                                      <p>BRL: ${reais2.reduce((acc, curr) => acc + curr, 0)}</p>
                                  </div>
                                  <div className="clientFlex1">
                                      <span>TOTAL EM USD:</span>
                                      <p>USD: ${dolar2.reduce((acc, curr) => acc + curr, 0)}</p>
                                  </div>
                                  <div className="clientFlex1" style={{border:0}}>
                                  <button className='seeClient' onClick={(e)=>{handleAllLider2(e)}}>somar todos</button>
                                  </div>
                                  <div className="clientFlex1" style={{border:0}}>
                                  <button className='seeClient' onClick={(e)=>{e.preventDefault();setDolar2([]);setReais2([])}}>limpar soma</button>
                                  </div>
                                  
                                 
                              </div>
                        </div>
                        <div className="linhaCentral">
                          <Link className='editValuesG' to={'/tabela/pago/analista/'+id}>EXPORTAR TABELA</Link>
                        </div>

                        </>
                        }                   
            </div>
            
            }
             
                <button className='editValues' onClick={handleOpen}>EDITAR COLABORADOR</button>
                
            </div>
            </div>
        </div>
    
        {open &&
            <div className="popUp">
                <form className="form600" onSubmit={handleUpdate}>
            <div className="linhas">
                <div className='clientName'>
                    <p>Nome:</p>
                    <input type="text" defaultValue={collabName} onChange={(e)=>{setCollabName(e.target.value)}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Email:</p>
                    <input type="text" defaultValue={collabEmail} onChange={(e)=>{setCollabEmail(e.target.value)}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Telefone</p>
                    <input type="text" defaultValue={collabPhone? collabPhone: 'Não Registrado'} onChange={(e)=>{setCollabPhone(e.target.value)}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientPhone'>
                    <p>País: {collabCountry}</p>
                    <select onChange={(e)=>{setCollabCountry(e.target.value)}}>
                    <option value="">Selecionar</option>
                        {[...Countries].map((item, index)=>(
                        <option value={item.country} key={index} id={item.id}>{item.country}</option>
                        ))}
                    </select>
                </div>
                <div className='clientPhone'>
                    <p>Tipo: {collabType}</p>
                    <select onChange={(e)=>{setCollabType(e.target.value)}}>
                      <option value="">Selecionar</option>
                      <option value="analista">Analista</option>
                      <option value="fornecedor">Fornecedor</option>
                    </select>
                </div>
                <div className='clientPhone'>
                <p>Calegoria:{collabCategory}</p>
                    <select  onChange={(e)=>{setCollabCategory(e.target.value)}}>
                      {collabType !== 'analista' && collabType !== 'fornecedor' ?  <option value="">Escolha um tipo</option> : ''}
                      {collabType === 'analista'?
                        <>
                        <option value="">Selecione</option>
                        <option value="Líder de Projeto">Líder de Projeto</option>
                        <option value="Pesquisador">Pesquisador</option>
                        <option value="Pesquisador de Documentos">Pesquisador de Documentos</option>
                        <option value="Segurança">Segurança</option>
                        <option value="Tradutor/Revisor">Tradutor/Revisor</option>
                        </>
                        : ''
                      }
                      {collabType === 'fornecedor'?
                        <>
                        <option value="">Selecione</option>
                        <option value="Tecnologia/Telecom">Tecnologia/Telecom</option>
                        <option value="Contabilidade">Contabilidade</option>
                        <option value="Escritório Virtual">Escritório Virtual</option>
                        <option value="Banco/Financeiro">Banco/Financeiro</option>
                        <option value="Documentos/Banco de Dados">Documentos/Banco de Dados</option>                         
                        </>
                        : ''
                      }                       
                    </select>
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Profissão:</p>
                    <input type="text" defaultValue={collabJurisdictions} onChange={(e)=>{setCollabJaurisdictions(e.target.value.split(','))}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientStat'>
                    <p>Desde:</p>
                    <input type="text" defaultValue={collabStart}  onChange={(e)=>{setCollabStart(e.target.value)}}/>
                </div>
                <div className='clientName'>
                    <p>Referência/Origem:</p>
                    <input type="text" defaultValue={collabRecomendation} onChange={(e)=>{setCollabRecomendation(e.target.value)}} />
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Comentários:</p>
                    <textarea rows="6" defaultValue={collabComments}  onChange={(e)=>{setCollabComments(e.target.value)}}></textarea>
                </div>
            </div>
            <div className="linhaCentral">
              <div className="linhasTitle">DADOS PAGAMENTO</div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Dados de Pagamento:</p>
                    <textarea rows="6" defaultValue={collabAccount} onChange={(e)=>{setCollabAccount(e.target.value)}}></textarea>
                </div>
            </div>
            <div className="linhaCentral">
              <div className="linhasTitle">NDA</div>
            </div>
            <div className="linhas">
              <div className='clientPhone'>
                  <p>Tem NDA? : {collabNDA? 'SIM': 'NÃO'}</p>
                  <select onChange={(e)=>{setCollabNDA(e.target.value)}}>
                    <option value="">Selecione</option>
                    <option value={true}>SIM</option>
                    <option value={false}>NÃO</option>
                  </select>
              </div>
                      
              <div className='clientPhone'>
                  <p>Data de Expiração: {expire1? `${expire1}`: ''} </p>
                  <input type="date"  onChange={(e)=>{setCollabNDADate(new Date(e.target.value))}} />
              </div>
              <div className='clientName'>
                  <p>Link do Documento:</p>
                  <input type="text" defaultValue={collabNDALink} onChange={(e)=>{setCollabNDALink(e.target.value)}} />
              </div>
            </div>         
            <div className="linhaCentral">
              <div className="linhasTitle">CONTRATO</div>
            </div>
            <div className="linhas">
              <div className='clientPhone'>
                  <p>Tem Contrato?: {collabContract? 'SIM': 'NÃO'}</p>
                  <select onChange={(e)=>{setCollabContract(e.target.value)}}>
                    <option value="">Selecione</option>
                    <option value={true}>SIM</option>
                    <option value={false}>NÃO</option>
                  </select>
                  
              </div>
              <div className='clientPhone'>
                  <p>Data de Expiração: {expire2? `${expire2}`: ''}</p>
                  <input type="date"  onChange={(e)=>{setCollabContractDate(new Date(e.target.value))}}/>
              </div>
              <div className='clientName'>
                  <p>Link do Documento:</p>
                  <input type="text" defaultValue={collabContractLink} placeholder='Documet Link' onChange={(e)=>{setCollabContractLink(e.target.value)}}/>
              </div>
            </div>  



                <input className='editValues'  type="submit" value="ATUALIZAR COLABORADOR" />
                <button className='editValues' onClick={handleClose} >FECHAR</button>
                <div>
                  {uploading &&
                    <span>uploading...</span>
                  }
                  {updated &&
                    <span>O Colaborador foi atualaizado com sucesso!</span>
                  }
                  {erros &&
                    <span className='Erro'> Erro:{erro}<br/> Tente novamente mais tarde</span>
                  }
                  </div>
            </form>
                
            </div>
        }
                            
       
    </div>
  )
}

export default Collabortor