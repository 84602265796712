import React from 'react'
import { Link } from 'react-router-dom'

function TableFilter() {
  return (
    <div className='SBNav'>
    <div className='SBsectionName'>FILTROS DE tabelas</div>
    <div className="SBContent">
        <Link to='/tabela/projetos'>Projetos</Link>
        <Link to='/tabela/projetoperiodo'>Projetos por período</Link>
        <Link to='/tabela/pagamentoanalistas'>Pagamentos Analistas</Link>
        <Link to='/tabela/pagamentolideres'>Pagamentos Líderes</Link>
        <Link to='/tabela/clientes'>Clientes</Link>
        <Link to='/tabela/analistas'>Analistas</Link>
    </div>
</div>
  )
}

export default TableFilter