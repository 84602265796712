import React from 'react'
import { Link } from 'react-router-dom'

function ClientsFilter() {
  return (
    <div className='SBNav'>
    <div className='SBsectionName'>FILTROS DE CLIENTES</div>
    <div className="SBContent">
        <Link to='/clientes/ativos'>ativos</Link>
        <Link to='/clientes/inativos'>inativos</Link>
        <Link to='/clientes'>todos</Link>

    </div>
</div>
  )
}

export default ClientsFilter