import React, {useEffect, useState} from 'react'
import SideBarUpPages from '../../Components/SideBar/SideBarUpPages'
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from '../../libs/firebase';
import '../pages.css'
function Pricing() {
  //states para lista
  const [rates, setRates]=useState([]);
  //states para valores e update
  const [uploading, setUploading] = useState(false);
  const [updated, setUpdated]=useState(false);
  const [erros, setErros]=useState(false);
  const [erro, setErro]=useState('');
  const [open, setOpen]=useState(false);
  //valores
  const [usd1a3, setusd1a3]=useState(0);
  const [brl1a3, setbrl1a3]=useState(0);
  const [usd4a7, setusd4a7]=useState(0);
  const [brl4a7, setbrl4a7]=useState(0);
  const [usd8a12, setusd8a12]=useState(0);
  const [brl8a12, setbrl8a12]=useState(0);
  const [usd13a20, setusd13a20]=useState(0);
  const [brl13a20, setbrl13a20]=useState(0);
  const [TCconsLocal, setTCconsLocal]=useState(0);
  const [TCconsGlobal,setTCconsGlobal]=useState(0);
  const [TCfinalLocal, setTCfinalLocal]=useState(0);
  const [TCfinlGlobal, setTCfinlGlobal]=useState(0);
  const [TPreputacional, setTPreputacional]=useState(0);
  const [TPinvestiga, setTPinvestiga]=useState(0);
  const [TPhibrido, setTPhibrido]=useState(0);
  const [rush, setRush]=useState(0);
  const [Traducao, setTraducao]=useState(0);
  const [brlNivel1, setBrlNivel1]=useState(0);
  const [brlNivel2, setBrlNivel2]=useState(0);
  const [brlNivel3, setBrlNivel3]=useState(0);
  const [usdNivel1, setUsdNivel1]=useState(0);
  const [usdNivel2, setUsdNivel2]=useState(0);
  const [usdNivel3, setUsdNivel3]=useState(0);
  
  //pegando doc

  const docRefdown = doc(db, "rates", "N4vpgUqlpsDNzBXwNS4H");

  const pegandoDoc = async()=>{
   
    try {
      const docSnap = await getDoc(docRefdown);
      if(docSnap.exists()) {
        setRates(docSnap.data());
        
      } else {
          alert("Document does not exist")
      }
    }
    catch(error) {
     alert(error)
    }
    return(pegandoDoc());
  
  }

  useEffect(()=>{
    pegandoDoc();
  });

  //função abrir e setar valores:
  const handleOpen =()=>{
    setOpen(true);
    setusd1a3(rates.usd1a3);
    setbrl1a3(rates.brl1a3);
    setusd4a7(rates.usd4a7);
    setbrl4a7(rates.brl4a7);
    setusd8a12(rates.usd8a12);
    setbrl8a12(rates.brl8a12);
    setusd13a20(rates.usd13a20);
    setbrl13a20(rates.brl13a20);
    setTCconsLocal(rates.TCconsLocal);
    setTCconsGlobal(rates.TCconsGlobal);
    setTCfinalLocal(rates.TCfinalLocal);
    setTCfinlGlobal(rates.TCfinlGlobal);
    setTPreputacional(rates.TPreputacional);
    setTPinvestiga(rates.TPinvestiga);
    setTPhibrido(rates.TPhibrido);
    setRush(rates.rush);
    setTraducao(rates.Traducao);
    setBrlNivel1(rates.brlNivel1)
  setBrlNivel2(rates.brlNivel2)
  setBrlNivel3(rates.brlNivel3)
  setUsdNivel1(rates.usdNivel1)
  setUsdNivel2(rates.usdNivel2)
  setUsdNivel3(rates.usdNivel3)
  }

  const handleClose =()=>{
    setOpen(false)
  }

  //funçao de updte dos values

  const handleUpdate = async (e)=>{
    e.preventDefault();
    setUploading(true);
    const docRef = doc(db, "rates", "N4vpgUqlpsDNzBXwNS4H");
    const data ={
      usd1a3,
      brl1a3,
      usd4a7,
      brl4a7,
      usd8a12,
      brl8a12,
      usd13a20,
      brl13a20,
      TCconsLocal,
      TCconsGlobal,
      TCfinalLocal,
      TCfinlGlobal,
      TPreputacional,
      TPinvestiga,
      TPhibrido,
      rush,
      Traducao,
      brlNivel1,
      brlNivel2,
      brlNivel3,
      usdNivel1,
      usdNivel2,
      usdNivel3
    };
    setUploading(false);
    await updateDoc(docRef, data, { merge:true })
    .then(docRef => { setUpdated(true) })
    .catch(error => { setErros(true); setErro(error); })

    setusd1a3();
    setbrl1a3();
    setusd4a7();
    setbrl4a7();
    setusd8a12();
    setbrl8a12();
    setusd13a20();
    setbrl13a20();
    setTCconsLocal();
    setTCconsGlobal();
    setTCfinalLocal();
    setTCfinlGlobal();
    setTPreputacional();
    setTPinvestiga();
    setTPhibrido();
    setRush();
    setTraducao();
    setBrlNivel1()
  setBrlNivel2()
  setBrlNivel3()
  setUsdNivel1()
  setUsdNivel2()
  setUsdNivel3()
   
    setUpdated(false);
    setOpen(false);
  }



  return (

    <div className='superContainer'>
      <SideBarUpPages/>
      <div className='pageHeader'>
          <div className="pageTitleCenter">
            <div className="pageTitle">Precificação de Projetos</div>
          </div>
      </div>
        <div className='pagesContentup'>
        <div className="pageContentCenter">
          <div className="pricingContent">
          <div className='clientFlexTitle'>Valores para Entrevistas</div>
            <div className="pricingItem">
                <div className="pricingItemNane">Numero de Entrevistas</div>
                <div className="pricingItemCotation">USD</div>
                <div className="pricingItemCotation">BRL</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">1 até 3</div>
                <div className="pricingItemCotation">US$ {[rates.usd1a3].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                <div className="pricingItemCotation">RS$ {[rates.brl1a3].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">4 até 7</div>
                <div className="pricingItemCotation">US$ {[rates.usd4a7].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                <div className="pricingItemCotation">RS$ {[rates.brl4a7].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">8 até 12</div>
                <div className="pricingItemCotation">US$ {[rates.usd8a12].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                <div className="pricingItemCotation">RS$ {[rates.brl8a12].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">13 até 20</div>
                <div className="pricingItemCotation">US$ {[rates.usd13a20].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                <div className="pricingItemCotation">RS$ {[rates.brl13a20].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
            </div>
            <div className='clientFlexTitle'>Valores para Líderes de Projeto</div>
            <div className="pricingItem">
                <div className="pricingItemNane">Níveis</div>
                <div className="pricingItemCotation">Global</div>
                <div className="pricingItemCotation">Brasil</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">
                  <p>Nível 1<br/>
                  Para Vantage e casos de 2 a 3 fontes</p>
                </div>
                <div className="pricingItemCotation">US$ {[rates.usdNivel1].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                <div className="pricingItemCotation">RS$ {[rates.brlNivel1].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">
                <p>Nível 2<br/>
                Para casos de 4 a 8 fontes</p>
                </div>
                <div className="pricingItemCotation">US$ {[rates.usdNivel2].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                <div className="pricingItemCotation">RS$ {[rates.brlNivel2].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">
                <p>Nível 3<br/>
                Para casos de 9 fontes em diante</p>
                </div>
                <div className="pricingItemCotation">US$ {[rates.usdNivel3].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                <div className="pricingItemCotation">RS$ {[rates.brlNivel3].toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
            </div>
     
           
            <div className='clientFlexTitle'>Taxas Para Tipo de Cliente</div>
            <div className="pricingItem">
                <div className="pricingItemNane">Tipo de CLiente</div>
                <div className="pricingItemCotation">Valor</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Consultoria Local</div>
                <div className="pricingItemCotation"> x {rates.TCconsLocal}</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Consultoria Global</div>
                <div className="pricingItemCotation"> x {rates.TCconsGlobal}</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Cliente Final Local</div>
                <div className="pricingItemCotation"> x {rates.TCfinalLocal}</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Cliente Final Global</div>
                <div className="pricingItemCotation"> x {rates.TCfinlGlobal}</div>
            </div>
            <div className='clientFlexTitle'>Taxas Para Tipo de Projeto</div>
            <div className="pricingItem">
                <div className="pricingItemNane">Tipo de Projeto</div>
                <div className="pricingItemCotation">Valor</div>
                <div className="pricingItemCotation">%</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Reputacional</div>
                <div className="pricingItemCotation"> x {rates.TPreputacional}</div>
                <div className="pricingItemCotation"> {((rates.TPreputacional-1)*100)}%</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Investigação</div>
                <div className="pricingItemCotation"> x {rates.TPinvestiga}</div>
                <div className="pricingItemCotation"> {Math.round((rates.TPinvestiga-1)*100)}%</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Híbrido</div>
                <div className="pricingItemCotation"> x {rates.TPhibrido}</div>
                <div className="pricingItemCotation">{Math.round((rates.TPhibrido-1)*100)}%</div>
            </div>
            <div className='clientFlexTitle'>Outras Taxas</div>
            <div className="pricingItem">
                <div className="pricingItemNane">Tipo</div>
                <div className="pricingItemCotation">Valor</div>
                <div className="pricingItemCotation">%</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Rush</div>
                <div className="pricingItemCotation"> x {rates.rush}</div>
                <div className="pricingItemCotation">{Math.round((rates.rush-1)*100)}%</div>
            </div>
            <div className="pricingItem">
                <div className="pricingItemNane">Tradução</div>
                <div className="pricingItemCotation"> x {rates.Traducao}</div>
                <div className="pricingItemCotation">{Math.round((rates.Traducao-1)*100)}%</div>
            </div>
            <button className='editValues' onClick={handleOpen}>Editar Valores</button>
          </div>
        </div>
        
      </div>
      {open &&
      <div className="popUp">
            <form onSubmit={handleUpdate} className="pricingContent">
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">1 até 3</div>
                    US$: <input type="number" defaultValue={usd1a3} className='inputPrice' onChange={(e)=>{setusd1a3(parseFloat(e.target.value))}}/>
                    RS$: <input type="number" defaultValue={brl1a3} className='inputPrice' onChange={(e)=>{setbrl1a3(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">4 até 7</div>
                    US$: <input type="number" defaultValue={usd4a7} className='inputPrice' onChange={(e)=>{setusd4a7(parseFloat(e.target.value))}}/>
                    RS$: <input type="number" defaultValue={brl4a7} className='inputPrice' onChange={(e)=>{setbrl4a7(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">8 até 12</div>
                    US$: <input type="number" defaultValue={usd8a12} className='inputPrice' onChange={(e)=>{setusd8a12(parseFloat(e.target.value))}}/>
                    RS$: <input type="number" defaultValue={brl8a12} className='inputPrice' onChange={(e)=>{setbrl8a12(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">13 até 20</div>
                    US$: <input type="number" defaultValue={usd13a20} className='inputPrice' onChange={(e)=>{setusd13a20(parseFloat(e.target.value))}}/>
                    RS$: <input type="number" defaultValue={brl13a20} className='inputPrice' onChange={(e)=>{setbrl13a20(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
            <div className="pricingItemNane">Nível1</div>
                    US$: <input type="number" defaultValue={usdNivel1} className='inputPrice' onChange={(e)=>{setUsdNivel1(parseFloat(e.target.value))}}/>
                    RS$: <input type="number" defaultValue={brlNivel1} className='inputPrice' onChange={(e)=>{setBrlNivel1(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
            <div className="pricingItemNane">Nível2</div>
                    US$: <input type="number" defaultValue={usdNivel2} className='inputPrice' onChange={(e)=>{setUsdNivel2(parseFloat(e.target.value))}}/>
                    RS$: <input type="number" defaultValue={brlNivel2} className='inputPrice' onChange={(e)=>{setBrlNivel2(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
            <div className="pricingItemNane">Nível3</div>
                    US$: <input type="number" defaultValue={usdNivel3} className='inputPrice' onChange={(e)=>{setUsdNivel3(parseFloat(e.target.value))}}/>
                    RS$: <input type="number" defaultValue={brlNivel3} className='inputPrice' onChange={(e)=>{setBrlNivel3(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Consultoria Local</div>
                    x <input type="number" defaultValue={TCconsLocal} className='inputPrice' onChange={(e)=>{setTCconsLocal(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Consultoria Global</div>
                    x <input type="number" defaultValue={TCconsGlobal} className='inputPrice' onChange={(e)=>{setTCconsGlobal(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Cliente Final Local</div>
                    x <input type="number" defaultValue={TCfinalLocal} className='inputPrice' onChange={(e)=>{setTCfinalLocal(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Cliente Final Global</div>
                    x <input type="number" defaultValue={TCfinlGlobal} className='inputPrice' onChange={(e)=>{setTCfinlGlobal(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Projeto Reputacional</div>
                    x <input type="number" defaultValue={TPreputacional} className='inputPrice' onChange={(e)=>{setTPreputacional(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Projeto de Investigação</div>
                    x <input type="number" defaultValue={TPinvestiga} className='inputPrice' onChange={(e)=>{setTPinvestiga(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Projeto Híbrido</div>
                    x <input type="number" defaultValue={TPhibrido} className='inputPrice' onChange={(e)=>{setTPhibrido(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Rush</div>
                    x <input type="number" defaultValue={rush} className='inputPrice' onChange={(e)=>{setRush(parseFloat(e.target.value))}}/>
            </div>
            <div className="pricingItemUpdate">
                    <div className="pricingItemNane">Tradução</div>
                    x <input type="number" defaultValue={Traducao} className='inputPrice' onChange={(e)=>{setTraducao(parseFloat(e.target.value))}}/>
            </div>
              <input className='editValues'  type="submit" value="Atualizar Valores" />
              <button className='editValues' onClick={handleClose} >Fechar</button>
                <div>
                  {uploading &&
                    <span>uploading...</span>
                  }
                  {updated &&
                    <span>Os valores foram atualizados com sucesso!</span>
                  }
                  {erros &&
                    <span className='Erro'> Erro:{erro}<br/> Tente novamente mais tarde</span>
                  }
                  </div>
            </form>
              
              
                  
      </div>
      }
    </div>
    
     

  )
}

export default Pricing