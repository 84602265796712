import React from 'react'
import './sidebar.css'
import TopLogo from '../Parcial/TopLogo'
import Clock from '../Parcial/Clock'
import Logout from '../Parcial/Logout'
import Navigtion from '../Parcial/Navigtion'
import Create from '../Parcial/Create'
import TableFilter from '../Parcial/TableFilter'
import Utils from '../Parcial/Utils'
import SideBarTablesMob from '../Parcial/SideBarTablesMob'

function SideBarTables() {
  return (
    <>
    <div className='sideBar'>
      <div className="sideBar2">
        <TopLogo />
        <Navigtion />
        <TableFilter />
        <Create />
        <Utils />
        <Clock />
        <Logout />
        </div>
    </div>
    <div className="sideBarMob">
      <SideBarTablesMob />
    </div>
    </>
  )
}

export default SideBarTables