import SideBarClients from '../../Components/SideBar/SideBarClients'
import React, {useState, useEffect} from 'react'
import { useParams} from 'react-router-dom';
import { db, } from '../../libs/firebase';
import {projectsCollectionRef} from '../../services/fireref'
import { doc, getDoc, updateDoc, query, where, onSnapshot, orderBy} from "firebase/firestore"
import '../pages.css'
import Countries from '../../libs/Countries.json'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Client() {
    //states para pegr informações
    const [clientName, setClientName]=useState('');
    const [clientCountry, setClientCountry]=useState('');
    const [clientStartYear, setClientStartYear]=useState('');
    const [clientComments, setClientComments]=useState('');
    const [clientBillingType, setClientBillingType]=useState('');
    const [clientBillingInfo, setClientBillingInfo]=useState('');
    const [clientStatus, setClientStatus]=useState('')
    const [clientProjects, setClientProjects]=useState(0)
    const [clientWeb, setClientWeb]=useState('')


    //states para abrir e update
    const [uploading, setUploading] = useState(false);
    const [updated, setUpdated]=useState(false);
    const [erros, setErros]=useState(false);
    const [erro, setErro]=useState('');
    const [open, setOpen]=useState(false);
    
    // contato states
    
    const [clientContacts, setClientContacts] = useState([])
    const [clientContacID, setClientContacID]=useState('')
    const [clientContactName, setClientContactName] = useState('');
    const [clientContactPhone, setClientContactPhone] = useState('');
    const [clientContactEmail, setClientContactEmail] = useState('');
    const [alerta, setAlerta]=useState(false);
    const [open2, setOpen2]=useState(false);
    // doc states
    const [clientDocs, setClientDocs]=useState([]);
    const [clientDocID, setClientDocsID]=useState('')
    const [clientDocName, setClientDocName]= useState('');
    const [clientDocLink, setClientDocLink]=useState('')
    

 //alertas
    const [alerta2, setAlerta2]=useState(false);
    const [openDoc, setOpenDoc]=useState(false)

    //pegando doc
    const { id } = useParams();
    const [client, setClient]=useState([]);
    const docRefdown = doc(db, 'clients', id);
    

    const pegandoDoc = async()=>{
      try {
        const docSnap = await getDoc(docRefdown);
        if(docSnap.exists()) {
          setClient(docSnap.data());
        } else {
            alert("Document does not exist")
        }
      }
      catch(error) {
       alert(error)
      }
      return(pegandoDoc());
    }
    useEffect(()=>{
      pegandoDoc();
    });

    //pegando projetos do cliente
    const [projects, setProjects]=useState([]);
    const navigate = useNavigate(); 

    useEffect(()=>{
        
        const q = query(projectsCollectionRef,where('projClientID', '==', `${id}`), orderBy('projNumber', 'desc'));
        
        const change = onSnapshot(q, snapshot=>{
          setProjects(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
           
        })
    
        return()=>{change()}
    
    },[]);

    const HandleSeeProject=(e)=>{
        e.preventDefault()
        let nav = e.target.value
        navigate(`/projeto/${nav}`)
    }
  

    // abrindo e update
    //função abrir e setar valores:
  const handleOpen =()=>{
    setOpen(true);
    setClientName(client.clientName);
    setClientCountry(client.clientCountry);
    setClientStartYear(client.clientStartYear);
    setClientComments(client.clientComments);
    setClientContacts(client.clientContacts? client.clientContacts:[])
    setClientBillingType(client.clientBillingType);
    setClientBillingInfo(client.clientBillingInfo);
    setClientDocs(client.clientDocs? client.clientDocs: [])
    setClientStatus(client.clientStatus)
    setClientProjects(client.clientProjects)
    setClientWeb(client.clientWeb? client.clientWeb: '' )
  }
  //fechando pop up
  const handleClose =()=>{
    setOpen(false)
  }
  //udatedoc
  const handleUpdate = async (e)=>{
    e.preventDefault();
    setUploading(true);
    const docRef = doc(db, "clients", id);
    const data ={
        clientName,
        clientCountry,
        clientStartYear,
        clientComments,
        clientBillingType,
        clientBillingInfo,
        clientContacts,
        clientDocs,
        clientStatus,
        clientProjects,
        clientWeb: clientWeb? clientWeb:''
    };
    setUploading(false);
    await updateDoc(docRef, data, { merge:true })
    .then(docRef => { setUpdated(true) })
    .catch(error => { setErros(true); setErro(error); })
   
    setUpdated(false);
    setOpen(false);
    setClientName('');
    setClientCountry('');
    setClientStartYear('');
    setClientComments('');
    setClientContacts([]);
    setClientBillingType('');
    setClientBillingInfo('');
    setClientStatus('')
  }

  //lidando com contatos
const addContact = (e, name, phone, email)=>{
    e.preventDefault();
    if(clientContactName!=='' && clientContactEmail !=='' && clientContactPhone !== ''){
        setAlerta(false)
        const contact = {
            id: crypto.randomUUID(),
            name,
            email,
            phone
        }
        setClientContacts([...clientContacts, contact])
        setClientContactName('')
        setClientContactPhone('')
        setClientContactEmail('')
    }else{
        setAlerta(true)
    }
    
}

const handleDeleteC =(e, id)=>{
    e.preventDefault();
    setClientContacts(clientContacts.filter(contact => contact.id !== id))
}

const handleOpenC = (e, id, name, phone, email)=>{
    e.preventDefault();
    setClientContacID(id)
    setClientContactName(name)
    setClientContactPhone(phone)
    setClientContactEmail(email)
    setOpen2(true)
}
const handleCloseC =()=>{
    setOpen2(false);
    setClientContacID('')
    setClientContactName('')
    setClientContactPhone('')
    setClientContactEmail('')
}
const handleUpdateC = (e, clientContacID, clientContactName, clientContactPhone, clientContactEmail)=>{
    e.preventDefault()
    setClientContacts(clientContacts.map(contact=>{
        if(contact.id === clientContacID){
            return{...contact, name:clientContactName, phone:clientContactPhone, email:clientContactEmail }
        }else{
            return contact
        }
    }))
    setOpen2(false)
    setClientContacID('')
    setClientContactName('')
    setClientContactPhone('')
    setClientContactEmail('')
}

const HandleaddDocs = (e, name, link)=>{
    e.preventDefault();
    if(clientDocName!=='' && clientDocLink !==''){
        setAlerta2(false)
        const doc = {
            id: crypto.randomUUID(),
            name,
            link
        }
        setClientDocs([...clientDocs, doc])
        setClientDocName('')
        setClientDocLink('')
    }else{
        setAlerta2(true)
    }
    
}
const handleDeleteDoc =(e, id)=>{
    e.preventDefault();
    setClientDocs(clientDocs.filter(doc => doc.id !== id))
}
const handleOpenDoc = (e, id, name, link)=>{
    e.preventDefault();
    setClientDocsID(id)
    setClientDocName(name)
    setClientDocLink(link)
    setOpenDoc(true)
}
const handleCloseDoc =()=>{
    setOpenDoc(false);
    setClientDocsID('')
    setClientDocName('')
    setClientDocLink('')
}

const handleUpdateDoc = (e, clientDocID, clientDocName, ClientDocLink)=>{
    e.preventDefault()
    setClientDocs(clientDocs.map(doc=>{
        if(doc.id === clientDocID){
            return{...doc, name:clientDocName, link:ClientDocLink }
        }else{
            return doc
        }
    }))
    setOpenDoc(false)
    setClientDocsID('')
    setClientDocName('')
    setClientDocLink('')
}



  return (
    <div className='superContainer'>
        <SideBarClients/>
        <div className='pageHeader'>
            <div className="pageTitleLeft">
                <div className="pageTitle">Cliente:&nbsp;&nbsp;&nbsp;<span>{client.clientName}</span>  </div>
            </div>
        </div>
            <div className="pagesContentup">
                <div className='pageListCLients'>
                    <div className='pageListCLientsA'>
                        <div className="clientFlex2">
                        <span>Nome:</span>
                        <p>{client.clientName}</p>
                        </div>
                        <div className="clientFlex1">
                        <span>País:</span>
                        <p>{client.clientCountry}</p>
                        </div>
                        <div className="clientFlex1">
                        <span>Status:</span>
                        <p>{client.clientStatus}</p>
                        </div>
                        <div className="clientFlex1">
                        <span>Desde:</span>
                        <p>{client.clientStartYear}</p>
                        </div>
                        <div className="clientFlex1">
                        <span>Web Site:</span>
                        <p style={client.clientWeb? {textTransform:'lowercase'}:{}}>{client.clientWeb? client.clientWeb: 'Não Registrado' }</p>
                        </div>
                    </div>
                    <div className='pageListCLientsB listActive'>
                        {client.clientContacts &&
                                client.clientContacts.map((item,index)=>(
                                    <div className='pageListCLientsBLinhas' key={index} id={item.id}>
                                        <div className="clientFlex2">
                                            <span>Nome contato:</span>
                                            <p>{item.name}</p>
                                        </div>
                                        <div className="clientFlex1">
                                            <span>Telefone contato:</span>
                                            <p>{item.phone}</p>
                                        </div>
                                        <div className="clientFlex1">
                                            <span>Email contato:</span>
                                            <p style={{textTransform:'lowercase'}}>{item.email}</p>
                                        </div>
                                    </div>
                                ))

                            }
                             {client.clientDocs &&
                                client.clientDocs.map((item,index)=>(
                                    <div className='pageListCLientsBLinhas' key={index} id={item.id}>
                                        <div className="clientFlex2">
                                            <span>Nome do documento:</span>
                                            <p><a href={item.link} target="_blank" rel="noopener noreferrer">{item.name}</a></p>
                                        </div>
                                    </div>
                                ))

                            }
                        <div className='pageListCLientsBLinhas'>
                            <div className='clientFlex1'>
                                <div className='clientFlexTitle'>Comentários</div>
                                <div className='clientFlexBox' style={{height:'100%'}}>
                                    <pre className='clientFlexBoxPre'  style={{fontFamily:'"Ubuntu", sans-serif'}}>{client.clientComments}</pre>
                                </div>
                            </div>
                            <div className='clientFlex1'>
                                <div className='clientFlexTitle'>Detalhes de Faturamento</div>
                                <div className='clientFlexP'>Faturamento: {client.clientBillingType}</div>
                                <div className='clientFlexBox'>
                                    <pre className='clientFlexBoxPre'  style={{fontFamily:'"Ubuntu", sans-serif'}}>{client.clientBillingInfo}</pre>
                                </div>
                            </div>
                        </div>
                        <div className="linhaCentral">
                            <div className="linhasTitle"  style={{width:'fit-content', marginBottom:8+'px'}}>PROJETOS DO CLIENTE</div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                        <div className="clientFlex1">
                                <span>Projetos Anteriores:</span>
                                <p>{client.clientProjects? client.clientProjects: 0 }</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Projetos No Sistema:</span>
                                <p>{projects.length}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Projetos Total:</span>
                                <p>{client.clientProjects? (parseInt(projects.length)+parseInt(client.clientProjects)) : projects.length}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Lista de Projetos:</span>
                                <select onChange={(e)=>{HandleSeeProject(e)}} style={{width:'100%'}}>
                                    <option value="">Selecione</option>
                                    {projects.map((item, index)=>(
                                    <option value={item.id} key={index} >n°: {item.data.projNumber} - {item.data.projName}</option>
                                    ))}
                                </select>
                            </div>
                            
                        </div>
                        <div className="linhaCentral">
                          <Link className='editValuesG' to={'/tabela/clientes/projetos/'+id}>EXPORTAR TABELA</Link>
                            </div>
                        <button className='editValues' onClick={handleOpen}>EDITAR CLIENTE</button>
                    </div>
             
                </div>
            </div>


        {open &&
            <div className="popUp">
                <form className="form900" onSubmit={handleUpdate} >
            <div className="linhas">
                <div className='clientName'>
                    <p>Nome:</p>
                    <input type="text" placeholder='Nome' onChange={(e)=>{setClientName(e.target.value)}} defaultValue={clientName}/>
                </div>
                <div className='clientCountry'>
                    <p>País:  {clientCountry}</p>
                    <select onChange={(e)=>{setClientCountry(e.target.value)}} style={{width:'100%'}}>
                        <option value="">Selecione</option>
                        {[...Countries].map((item, index)=>(
                        <option value={item.country} key={index} id={item.id}>{item.country}</option>
                        ))}
                    </select>
                </div>
                <div className='clientCountry'>
                    <p>Status:{clientStatus}</p>
                    <select style={{width:'100%'}} onChange={(e)=>{setClientStatus(e.target.value)}}>
                        <option value="">Selecione</option>
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                    </select>
                   
                </div>
                <div className='clientStat'>
                    <p>Desde:</p>
                    <input type="text"  placeholder='AAAA' defaultValue={clientStartYear}  onChange={(e)=>{setClientStartYear(e.target.value)}}/>
                </div>
                <div className='clientName'>
                    <p>Web Site:</p>
                    <input type="text"  defaultValue={clientWeb}  onChange={(e)=>{setClientWeb(e.target.value)}}/>
                </div>
                <div className='clientName'>
                    <p>Projetos anteriores:</p>
                    <input type="number"  defaultValue={clientProjects} required onChange={(e)=>{setClientProjects(e.target.value)}}/>
                </div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>comentários:</p>
                    <textarea id="" rows="6" placeholder='Comentários' onChange={(e)=>{setClientComments(e.target.value)}} defaultValue={clientComments}></textarea>
                </div>
            </div>
            <div className="linhaCentral">
              <div className="linhasTitle">CONTATOS</div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Nome contato:</p>
                    <input type="text" placeholder='nome' value={clientContactName} onChange={(e)=>{setClientContactName(e.target.value)}}/>
                </div>
                <div className='clientPhone'>
                    <p>Telefone contato:</p>
                    <input type="text" placeholder='telefone' value={clientContactPhone} onChange={(e)=>{setClientContactPhone(e.target.value)}}/>
                </div>
                <div className='clientPhone'>
                    <p>email contato:</p>
                    <input type="text" placeholder='email' value={clientContactEmail} onChange={(e)=>{setClientContactEmail(e.target.value)}} />
                </div>
                <div className='clientFlex1Button2'><button onClick={(e)=>{addContact(e,clientContactName, clientContactPhone, clientContactEmail)}}>ADCIONAR</button></div>
            </div>
            {alerta &&
            <div className="linhas" style={{color:'#FA9600'}}>Preencha todos os campos para adicionar um contato</div>
            }
            {clientContacts.length>=1 &&  clientContacts.map((item,index) =>(
               <div className="pageListCLientsBLinhas" key={index} id={item.id}>
                        <div className='clientFlex2'>
                            <span>Nome contato:</span>
                            <p>{item.name}</p>
                        </div>
                        <div className='clientFlex1'>
                            <span>Telefone contato:</span>
                            <p style={{textTransform:'lowercase'}}>{item.phone}</p>
                        </div>
                        <div className='clientFlex1'>
                            <span>email contato:</span>
                            <p style={{textTransform:'lowercase'}}>{item.email}</p>
                        </div>
                        <div className='clientFlex1Button'>
                            <button onClick={(e)=>{handleDeleteC(e, item.id)}}>APAGAR</button>
                            <button onClick={(e)=>{handleOpenC(e, item.id, item.name, item.phone, item.email)}}>EDITAR</button>
                        </div>
                    </div>
            ))}
             <div className="linhaCentral">
              <div className="linhasTitle">DOCUMENTOS</div>
            </div>
            <div className="linhas">
                <div className='clientName'>
                    <p>Nome documento:</p>
                    <input type="text" placeholder='nome' value={clientDocName} onChange={(e)=>{setClientDocName(e.target.value)}}/>
                </div>
                <div className='clientName'>
                    <p>link documento:</p>
                    <input type="text" placeholder='link' value={clientDocLink} onChange={(e)=>{setClientDocLink(e.target.value)}}/>
                </div>
                <div className='clientFlex1Button2'><button onClick={(e)=>{HandleaddDocs(e, clientDocName, clientDocLink)}}>ADICIONAR</button></div>
            </div>
            {alerta2 &&
            <div className="linhas" style={{color:'#FA9600'}}>Preencha todos os campos para adicionar um documento</div>
            }
            {clientDocs.length>=1 &&  clientDocs.map((item,index) =>(
               <div className="pageListCLientsBLinhas" key={index} id={item.id}>
                        <div className='clientFlex2'>
                            <span>Nome documento:</span>
                            <p>{item.name}</p>
                        </div>
                        <div className='clientFlex1'>
                            <span>Link do documento:</span>
                            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',textTransform:'lowercase', maxWidth: 25+'ch'}}>{item.link}</p>
                        </div>
                        <div className='clientFlex1Button'>
                            <button onClick={(e)=>{handleDeleteDoc(e, item.id)}}>APAGAR</button>
                            <button onClick={(e)=>{handleOpenDoc(e, item.id, item.name, item.link)}}>EDITAR</button>
                        </div>
                    </div>
            ))}
            <div className="linhaCentral">
              <div className="linhasTitle">FATURAMENTO</div>
            </div>
            <div className="linhas">
                <div className='clientPhone'  style={{alignSelf:'flex-start', maxWidth:'100px'}}>
                    <p>Faturamento: {clientBillingType}</p>
                    <select onChange={(e)=>{setClientBillingType(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value="NF">NF</option>
                      <option value="Invoice">Invoice</option>
                    </select>
                </div>
                <div className='clientName' >
                    <p>Informações de faturamento:</p>
                    <textarea id="" rows="6" placeholder='Informações de faturamento' onChange={(e)=>{setClientBillingInfo(e.target.value)}} defaultValue={clientBillingInfo}></textarea>
                </div>
            </div>
        
            <input className='editValues'  type="submit" value="ATUALIZAR CLIENTE" />
              <button className='editValues' onClick={handleClose} >FECHAR</button>
                <div>
                  {uploading &&
                    <span>uploading...</span>
                  }
                  {updated &&
                    <span>O cliente foi atualizado com sucesso!</span>
                  }
                  {erros &&
                    <span className='Erro'> Erro:{erro}<br/> tente novmente mais tarde</span>
                  }
                  </div>
          </form>
            </div>
        }
        {open2 &&
            <div className="popUp2">
                <form className="form900" onSubmit={(e)=>{handleUpdateC(e, clientContacID, clientContactName, clientContactPhone, clientContactEmail)}}>
            <div className="linhas">
                <div className='clientName'>
                    <p>Contact name:</p>
                    <input type="text" required onChange={(e)=>{setClientContactName(e.target.value)}} defaultValue={clientContactName}/>
                </div>
                <div className='clientName'>
                    <p>Contact phone:</p>
                    <input type="text" required  onChange={(e)=>{setClientContactPhone(e.target.value)}} defaultValue={clientContactPhone}/>
                </div>
                <div className='clientName'>
                    <p>Contact phone:</p>
                    <input type="text" required onChange={(e)=>{setClientContactEmail(e.target.value)}} defaultValue={clientContactEmail}/>
                </div>
            </div>
            <input className='editValues'  type="submit" value="ATUALIZAR CONTATO" />
            <button className='editValues' onClick={handleCloseC} >FECHAR</button>
            </form>
            
            </div>
        } 
        {openDoc &&
            <div className="popUp">
                <form className="form900" onSubmit={(e)=>{handleUpdateDoc(e, clientDocID, clientDocName, clientDocLink)}}>
            <div className="linhas">
                <div className='clientName'>
                    <p>Document Name:</p>
                    <input type="text" required onChange={(e)=>{setClientDocName(e.target.value)}} defaultValue={clientDocName}/>
                </div>
                <div className='clientName'>
                    <p>Document Link:</p>
                    <input type="text" required  onChange={(e)=>{setClientDocLink(e.target.value)}} defaultValue={clientDocLink}/>
                </div>
            </div>
            <input className='editValues'  type="submit" value="ATUALIZAR DOCUMENTO" />
            <button className='editValues' onClick={handleCloseDoc} >FECHAR</button>
            </form>
            
            </div>
        } 
       
    </div>
  )
}

export default Client