import React from 'react'
import { Link } from 'react-router-dom'

function EmailFilters() {
  return (
    <div className='SBNav'>
        <div className='SBsectionName'>EMAILS PARA</div>
        <div className="SBContent">
            <Link to='/email/clientes'>Clientes</Link>
            <Link to='/email/prospeccoes'>Prospecções</Link>
            <Link to='/email/clienteseprospeccoes'>Clientes E Prospecções</Link>
            <Link to='/email/analistas'>Analistas</Link>
            <Link to='/email/fornecedores'>Fornecedores</Link>

        </div>
    </div>
  )
}

export default EmailFilters