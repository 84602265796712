import React from 'react'
import './sidebar.css'
import TopLogo from '../Parcial/TopLogo'
import Clock from '../Parcial/Clock'
import Logout from '../Parcial/Logout'
import Navigtion from '../Parcial/Navigtion'
import Create from '../Parcial/Create'
import SibeBarMobUp from '../Parcial/SibeBarMobUp'
import Utils from '../Parcial/Utils'

function SideBarUpPages() {
  return (
    <>
    <div className='sideBar'>
     <div className="sideBar2">
        <TopLogo />
        <Navigtion />
        <Create />
        <Utils />
        <Clock />
        <Logout />
        </div>
    </div>
    <div className="sideBarMob">
      <SibeBarMobUp />
    </div>
    </>
    
  )
}

export default SideBarUpPages