import React, {useEffect, useState} from 'react'
import SideBarUpPages from '../../Components/SideBar/SideBarUpPages'
import { doc,  getDoc } from "firebase/firestore";
import { db } from '../../libs/firebase';
import '../pages.css'

function Calculadora() {
    //pegando doc de preços

const [rates, setRates]=useState([]);

const docRefdown = doc(db, "rates", "N4vpgUqlpsDNzBXwNS4H");

const pegandoDoc = async()=>{

  try {
    const docSnap = await getDoc(docRefdown);
    if(docSnap.exists()) {
      setRates(docSnap.data());

    } else {
        alert("Document does not exist")
    }
  }
  catch(error) {
   alert(error)
  }
  return(pegandoDoc());

}

useEffect(()=>{
  pegandoDoc();
});

  //states de calculadora
  const [projCurrency, setProjeCurrency]=useState('');
  const [proj2Currency, setProj2Currency]=useState('');
  const [projSources, setProjSources]=useState(0);
  const [projSourcesValue, setProjSourcesValue]=useState(0)
  const [projSourcesValue2, setProjSourcesValue2]=useState(0)
  const [projClientType, setProjClientType]=useState('');
  const [projCTValue, setProjCTValue]=useState(0)
  const [projType, setProjType]=useState('');
  const [projTypeValue, setProjTypeValue]=useState(0);
  const [projRush, setProjRush]=useState('');
  const [projTranslation, setProjTranslation]=useState('')
  const [alertCalc, setAlertaCalc]=useState(false);
  const [entreviewsTotalValue, setEntreviewsTotalValue]=useState(0)
  const [entreviewsTotalValue2, setEntreviewsTotalValue2]=useState(0)
  const [resultados, setResultados]=useState(false)

 


  const [projCalculatedTotal, setProjCalculatedTotal] = useState(0)
  const [projCovertedValue, setProjCovertedValue]=useState(0)
   //transformando para numeros
  const HandleProjClientType=(e)=>{
    e.preventDefault();
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const optionElementId = optionElement.getAttribute('id');
     setProjCTValue(e.target.value);
     setProjClientType(optionElementId);
  }

  const HandleProjType=(e)=>{
    e.preventDefault();
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const optionElementId = optionElement.getAttribute('id');
    setProjTypeValue(e.target.value);
     setProjType(optionElementId);
  }

  //setando maincurrency e secondary e determinando valor de entrevista pelo numero
  useEffect(()=>{
    if(projCurrency === 'BRL' ){
      setProj2Currency('USD')
      if(projSources <4){
        setProjSourcesValue(rates.brl1a3)
        setProjSourcesValue2(rates.usd1a3)
      }
  
      if(projSources>=4 && projSources<8){
        setProjSourcesValue(rates.brl4a7)
        setProjSourcesValue2(rates.usd4a7)
      }
  
      if(projSources>= 8 && projSources<13){
        setProjSourcesValue(rates.brl8a12)
        setProjSourcesValue2(rates.usd8a12)
      }
      if(projSources>= 13){
        setProjSourcesValue(rates.brl13a20)
        setProjSourcesValue2(rates.usd13a20)
      }
      
    }

    if(projCurrency === 'USD' ){
      setProj2Currency('BRL')
      if(projSources <4){
        setProjSourcesValue(rates.usd1a3)
        setProjSourcesValue2(rates.brl1a3)

      }

      if(projSources>=4 && projSources<8){
        setProjSourcesValue(rates.usd4a7)
        setProjSourcesValue2(rates.brl4a7)

      }

      if(projSources>= 8 && projSources<13){
        setProjSourcesValue(rates.usd8a12)
        setProjSourcesValue2(rates.brl8a12)

        
      }
      if(projSources>= 13){
        setProjSourcesValue(rates.usd13a20)
        setProjSourcesValue2(rates.brl13a20)

      }
  }
  },[projCurrency, projSources])

  //calculando projeto
  const handleCalculateProj=(e)=>{
    e.preventDefault();
    if (projSources === '' || projCTValue ==='' || projType === '' || projRush === ''|| projTranslation ===''){
      setAlertaCalc(true)
    }else{
      setResultados(true)
      setAlertaCalc(false)
      const subtotal1 = parseFloat(projSources)* parseFloat(projSourcesValue) ;
      setEntreviewsTotalValue(subtotal1)
      const subtotal1a = parseFloat(projSources)* parseFloat(projSourcesValue2) ;
      setEntreviewsTotalValue2(subtotal1a)
      const subtotal2 = subtotal1 * parseFloat(projCTValue);
      const subtotal3 = subtotal2* parseFloat(projTypeValue);
      const subtotal4 = subtotal3* parseFloat(projRush);
      const subtotal5 = subtotal4* parseFloat(projTranslation);
      setProjCalculatedTotal(subtotal5)
      const converterBRL = ()=>{
        const host = 'api.frankfurter.app';
        fetch(`https://${host}/latest?amount=${subtotal5}&from=${projCurrency}&to=${proj2Currency}`)
        .then(resp => resp.json())
        .then((data) => {
          setProjCovertedValue(data.rates[proj2Currency]);
          //setCalculating(false)
          //setResults1(true)
        });
       
      }
      return converterBRL();
      
    }
  }

  const handleReCalculateProj = (e)=>{
    e.preventDefault()
    setResultados(false)
    setProjSources(0)
    setProjSourcesValue(0)
    setProjCTValue(0)
    setProjClientType('')
    setProj2Currency('')
    setProjeCurrency('')
    setProjType('')
    setProjTypeValue(0)
    setProjRush(0)
    setProjTranslation(0)
    setProjCalculatedTotal(0)
    setProjCovertedValue(0)
    setEntreviewsTotalValue(0)
    setEntreviewsTotalValue2(0)
    setAlertaCalc(false)
  }


  return (
    <div className='superContainer'>
        <SideBarUpPages/>
        <div className='pageHeader'>
            <div className="pageTitleCenter">
                <div className="pageTitle">Calculadora de projetos</div>
            </div>
        </div>
        <div className="pagesContentup">
            <div className="form900">
            <div className="linhaCentral">
              <div className="proejctTitle">
                CALCULADORA
              </div>
            </div>
            {!resultados &&
            <>
            <div className="linhaProjetoCalc">
                <div className="projF1">
                    <p>Moeda:</p>
                    <select required onChange={(e)=>{setProjeCurrency(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value="USD">USD</option>
                      <option value="BRL">BRL</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Nº de Entrevistas:</p>
                    <select required onChange={(e)=>{setProjSources(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Tipo do Cliente:</p>
                    <select required onChange={(e)=>{HandleProjClientType(e)}}>
                      <option value="">Selecione</option>
                      <option value={rates.TCconsLocal} id='Consultoria Local'>Consultoria Local</option>
                      <option value={rates.TCconsGlobal} id='Consultoria Global'>Consultoria Global</option>
                      <option value={rates.TCfinalLocal} id='Cliente Final Local'>Cliente Final Local</option>
                      <option value={rates.TCfinlGlobal} id='Cliente Final Global'>Cliente Final Global</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Tipo do Projeto:</p>
                    <select required onChange={(e)=>{HandleProjType(e)}}>
                      <option value="">Selecione</option>
                      <option value={rates.TPreputacional} id='Reputacional'>Reputacional</option>
                      <option value={rates.TPinvestiga} id='Ivestigação'>Investigação</option>
                      <option value={rates.TPhibrido} id='Híbrido'>Híbrido</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Rush?</p>
                    <select required onChange={(e)=>{setProjRush(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value='1'>Não</option>
                      <option value={rates.rush}>Sim</option>
                    </select>
                </div>
                <div className="projF1">
                    <p>Tradução?</p>
                    <select required onChange={(e)=>{setProjTranslation(e.target.value)}}>
                      <option value="">Selecione</option>
                      <option value='1'>Não</option>
                      <option value={rates.Traducao}>Sim</option>
                    </select>
                </div>
            </div>
            <div className="linhaCentral">
              <button className='seeClient' onClick={(e)=>{handleCalculateProj(e)}}>CALCULAR</button>
            </div>
            {alertCalc &&
              <div className="linhaCentral">Preencha Todos o campos para fazer o Calculo do projeto</div>
            }
            </>

            }
            {resultados &&
              <>
                <div className="linhaCalculado">
                  <div className="projF1-2">
                    <div className="linhaCentral">Parâmetros de Cálculo</div>
                    <div><p>Moeda Principal: <span>{projCurrency}</span></p></div>
                    <div> <p>N° de Entrevistas: <span>{projSources}</span></p> </div>
                    <div><p>Valor por entrevista moeda principal: <span>{projCurrency === 'BRL'? 'R$': 'US$'} {Math.round(projSourcesValue).toLocaleString('pt-br')}</span></p></div>
                    <div><p>Valor por entrevista moeda secundária: <span>{proj2Currency === 'BRL'? 'R$': 'US$'} {Math.round(projSourcesValue2).toLocaleString('pt-br')}</span></p></div>
                    <div><p>Tipo de Cliente: <span>{projClientType}</span></p></div>
                    <div><p>Tipo de Projeto: <span>{ projType=== 'Reputacional'? 'Humint' : projType=== 'Ivestigação'? 'Documentos' : projType }</span></p></div>
                    <div><p>Rush: <span>{projRush !== '1'? 'Sim': 'Não'}</span></p></div>
                    <div><p>Tradução: <span>{projTranslation !== '1'? 'Sim':'Não'}</span></p></div>
                  </div>
                  <div className="projF1-2">
                    <div className="linhaCentral">Valores Calculados</div>
                    <div><p>Valor Calculado em {projCurrency}: <span>${Math.round(projCalculatedTotal).toLocaleString('pt-br')}</span></p></div>
                    <div><p>Valor Calculdo em {proj2Currency}: <span>${Math.round(projCovertedValue).toLocaleString('pt-br')}</span></p></div>

                    <div><p>Valor Das Entrevistas em {projCurrency}: <span>${Math.round(entreviewsTotalValue).toLocaleString('pt-br')}</span></p></div>
                    <div><p>Valor Das Entrevistas em {proj2Currency}: <span>${Math.round(entreviewsTotalValue2).toLocaleString('pt-br')}</span></p></div>
                    </div>
                    
                 
              </div>
              <div className="linhaCentral">
                <button className='seeClient' onClick={(e)=>{handleReCalculateProj(e)}}>RECALCULAR</button>
              </div>
            </>
            
            }
            </div>
        </div>
      </div>
  )
}

export default Calculadora