import SideBarUpPages from '../../Components/SideBar/SideBarClients'
import React, {useState, useEffect} from 'react'
import { useParams} from 'react-router-dom';
import { db, } from '../../libs/firebase';
import { doc, getDoc, updateDoc} from "firebase/firestore"
import '../pages.css'
import Countries from '../../libs/Countries.json'


function Prospect() {
  //states para pegr informações
  const [clientName, setClientName]=useState('');
  const [clientCountry, setClientCountry]=useState('');
  const [clientStartYear, setClientStartYear]=useState('');
  const [clientComments, setClientComments]=useState('');
  const [clientProjects, setClientProjects]=useState(0)


  //states para abrir e update
  const [uploading, setUploading] = useState(false);
  const [updated, setUpdated]=useState(false);
  const [erros, setErros]=useState(false);
  const [erro, setErro]=useState('');
  const [open, setOpen]=useState(false);
  
  // contato states
  
  const [clientContacts, setClientContacts] = useState([])
  const [clientContacID, setClientContacID]=useState('')
  const [clientContactName, setClientContactName] = useState('');
  const [clientContactPhone, setClientContactPhone] = useState('');
  const [clientContactEmail, setClientContactEmail] = useState('');
  const [alerta, setAlerta]=useState(false);
  const [open2, setOpen2]=useState(false);

  



  //pegando doc
  const { id } = useParams();
  const [client, setClient]=useState([]);
  const docRefdown = doc(db, 'prospects', id);
  

  const pegandoDoc = async()=>{
    try {
      const docSnap = await getDoc(docRefdown);
      if(docSnap.exists()) {
        setClient(docSnap.data());
      } else {
          alert("Document does not exist")
      }
    }
    catch(error) {
     alert(error)
    }
    return(pegandoDoc());
  }
  useEffect(()=>{
    pegandoDoc();
  });

  




  // abrindo e update
  //função abrir e setar valores:
const handleOpen =()=>{
  setOpen(true);
  setClientName(client.clientName);
  setClientCountry(client.clientCountry);
  setClientStartYear(client.clientStartYear);
  setClientComments(client.clientComments);
  setClientContacts(client.clientContacts? client.clientContacts:[])
  setClientProjects(client.clientProjects)
}
//fechando pop up
const handleClose =()=>{
  setOpen(false)
}
//udatedoc
const handleUpdate = async (e)=>{
  e.preventDefault();
  setUploading(true);
  const docRef = doc(db, "prospects", id);
  const data ={
      clientName,
      clientCountry,
      clientStartYear,
      clientComments,
      clientContacts

  };
  setUploading(false);
  await updateDoc(docRef, data, { merge:true })
  .then(docRef => { setUpdated(true) })
  .catch(error => { setErros(true); setErro(error); })
 
  setUpdated(false);
  setOpen(false);
  setClientName('');
  setClientCountry('');
  setClientStartYear('');
  setClientComments('');
  setClientContacts([]);

}

//lidando com contatos
const addContact = (e, name, phone, email)=>{
  e.preventDefault();
  if(clientContactName!=='' && clientContactEmail !=='' && clientContactPhone !== ''){
      setAlerta(false)
      const contact = {
          id: crypto.randomUUID(),
          name,
          email,
          phone
      }
      setClientContacts([...clientContacts, contact])
      setClientContactName('')
      setClientContactPhone('')
      setClientContactEmail('')
  }else{
      setAlerta(true)
  }
  
}

const handleDeleteC =(e, id)=>{
  e.preventDefault();
  setClientContacts(clientContacts.filter(contact => contact.id !== id))
}

const handleOpenC = (e, id, name, phone, email)=>{
  e.preventDefault();
  setClientContacID(id)
  setClientContactName(name)
  setClientContactPhone(phone)
  setClientContactEmail(email)
  setOpen2(true)
}
const handleCloseC =()=>{
  setOpen2(false);
  setClientContacID('')
  setClientContactName('')
  setClientContactPhone('')
  setClientContactEmail('')
}
const handleUpdateC = (e, clientContacID, clientContactName, clientContactPhone, clientContactEmail)=>{
  e.preventDefault()
  setClientContacts(clientContacts.map(contact=>{
      if(contact.id === clientContacID){
          return{...contact, name:clientContactName, phone:clientContactPhone, email:clientContactEmail }
      }else{
          return contact
      }
  }))
  setOpen2(false)
  setClientContacID('')
  setClientContactName('')
  setClientContactPhone('')
  setClientContactEmail('')
}





return (
  <div className='superContainer'>
      <SideBarUpPages/>
      <div className='pageHeader'>
          <div className="pageTitleLeft">
              <div className="pageTitle">Prospecção:&nbsp;&nbsp;&nbsp;<span>{client.clientName}</span>  </div>
          </div>
      </div>
          <div className="pagesContentup">
              <div className='pageListCLients'>
                  <div className='pageListCLientsA'>
                      <div className="clientFlex2">
                      <span>Nome:</span>
                      <p>{client.clientName}</p>
                      </div>
                      <div className="clientFlex1">
                      <span>País:</span>
                      <p>{client.clientCountry}</p>
                      </div>
                      
                      <div className="clientFlex1">
                      <span>Desde:</span>
                      <p>{client.clientStartYear}</p>
                      </div>
                      <div className="clientFlex1">
                      <span>Web Site:</span>
                      <p style={client.clientWeb? {textTransform:'lowercase'}:{}}>{client.clientWeb? client.clientWeb: 'Não Registrado' }</p>
                      </div>
                  </div>
                  <div className='pageListCLientsB listActive'>
                      {client.clientContacts &&
                              client.clientContacts.map((item,index)=>(
                                  <div className='pageListCLientsBLinhas' key={index} id={item.id}>
                                      <div className="clientFlex2">
                                          <span>Nome contato:</span>
                                          <p>{item.name}</p>
                                      </div>
                                      <div className="clientFlex1">
                                          <span>Telefone contato:</span>
                                          <p>{item.phone}</p>
                                      </div>
                                      <div className="clientFlex1">
                                          <span>Email contato:</span>
                                          <p style={{textTransform:'lowercase'}}>{item.email}</p>
                                      </div>
                                  </div>
                              ))

                          }
                           
                      <div className='pageListCLientsBLinhas'>
                          <div className='clientFlex1'>
                              <div className='clientFlexTitle'>Comentários</div>
                              <div className='clientFlexBox' style={{height:'100%'}}>
                                  <pre className='clientFlexBoxPre'  style={{fontFamily:'"Ubuntu", sans-serif'}}>{client.clientComments}</pre>
                              </div>
                          </div>
                         
                      </div>
                      
                    
                      <button className='editValues' onClick={handleOpen}>EDITAR PROSPECÇÃO</button>
                  </div>
           
              </div>
          </div>


      {open &&
          <div className="popUp">
              <form className="form900" onSubmit={handleUpdate} >
          <div className="linhas">
              <div className='clientName'>
                  <p>Nome:</p>
                  <input type="text" placeholder='Nome' onChange={(e)=>{setClientName(e.target.value)}} defaultValue={clientName}/>
              </div>
              <div className='clientCountry'>
                  <p>País:  {clientCountry}</p>
                  <select onChange={(e)=>{setClientCountry(e.target.value)}} style={{width:'100%'}}>
                      <option value="">Selecione</option>
                      {[...Countries].map((item, index)=>(
                      <option value={item.country} key={index} id={item.id}>{item.country}</option>
                      ))}
                  </select>
              </div>
              
              <div className='clientStat'>
                  <p>Desde:</p>
                  <input type="text"  placeholder='AAAA' defaultValue={clientStartYear}  onChange={(e)=>{setClientStartYear(e.target.value)}}/>
              </div>
             
          </div>
          <div className="linhas">
              <div className='clientName'>
                  <p>comentários:</p>
                  <textarea id="" rows="6" placeholder='Comentários' onChange={(e)=>{setClientComments(e.target.value)}} defaultValue={clientComments}></textarea>
              </div>
          </div>
          <div className="linhaCentral">
            <div className="linhasTitle">CONTATOS</div>
          </div>
          <div className="linhas">
              <div className='clientName'>
                  <p>Nome contato:</p>
                  <input type="text" placeholder='nome' value={clientContactName} onChange={(e)=>{setClientContactName(e.target.value)}}/>
              </div>
              <div className='clientPhone'>
                  <p>Telefone contato:</p>
                  <input type="text" placeholder='telefone' value={clientContactPhone} onChange={(e)=>{setClientContactPhone(e.target.value)}}/>
              </div>
              <div className='clientPhone'>
                  <p>email contato:</p>
                  <input type="text" placeholder='email' value={clientContactEmail} onChange={(e)=>{setClientContactEmail(e.target.value)}} />
              </div>
              <div className='clientFlex1Button2'><button onClick={(e)=>{addContact(e,clientContactName, clientContactPhone, clientContactEmail)}}>ADCIONAR</button></div>
          </div>
          {alerta &&
          <div className="linhas" style={{color:'#FA9600'}}>Preencha todos os campos para adicionar um contato</div>
          }
          {clientContacts.length>=1 &&  clientContacts.map((item,index) =>(
             <div className="pageListCLientsBLinhas" key={index} id={item.id}>
                      <div className='clientFlex2'>
                          <span>Nome contato:</span>
                          <p>{item.name}</p>
                      </div>
                      <div className='clientFlex1'>
                          <span>Telefone contato:</span>
                          <p style={{textTransform:'lowercase'}}>{item.phone}</p>
                      </div>
                      <div className='clientFlex1'>
                          <span>email contato:</span>
                          <p style={{textTransform:'lowercase'}}>{item.email}</p>
                      </div>
                      <div className='clientFlex1Button'>
                          <button onClick={(e)=>{handleDeleteC(e, item.id)}}>APAGAR</button>
                          <button onClick={(e)=>{handleOpenC(e, item.id, item.name, item.phone, item.email)}}>EDITAR</button>
                      </div>
                  </div>
          ))}
           
         
          
      
          <input className='editValues'  type="submit" value="ATUALIZAR PROPRECÇÃO" />
            <button className='editValues' onClick={handleClose} >FECHAR</button>
              <div>
                {uploading &&
                  <span>uploading...</span>
                }
                {updated &&
                  <span>O cliente foi atualizado com sucesso!</span>
                }
                {erros &&
                  <span className='Erro'> Erro:{erro}<br/> tente novmente mais tarde</span>
                }
                </div>
        </form>
          </div>
      }
      {open2 &&
          <div className="popUp2">
              <form className="form900" onSubmit={(e)=>{handleUpdateC(e, clientContacID, clientContactName, clientContactPhone, clientContactEmail)}}>
          <div className="linhas">
              <div className='clientName'>
                  <p>Contact name:</p>
                  <input type="text" required onChange={(e)=>{setClientContactName(e.target.value)}} defaultValue={clientContactName}/>
              </div>
              <div className='clientName'>
                  <p>Contact phone:</p>
                  <input type="text" required  onChange={(e)=>{setClientContactPhone(e.target.value)}} defaultValue={clientContactPhone}/>
              </div>
              <div className='clientName'>
                  <p>Contact phone:</p>
                  <input type="text" required onChange={(e)=>{setClientContactEmail(e.target.value)}} defaultValue={clientContactEmail}/>
              </div>
          </div>
          <input className='editValues'  type="submit" value="ATUALIZAR CONTATO" />
          <button className='editValues' onClick={handleCloseC} >FECHAR</button>
          </form>
          
          </div>
      } 
      
     
  </div>
)
}

export default Prospect