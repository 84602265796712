import React, {useState, useEffect} from 'react'
import SideBarTables from '../../Components/SideBar/SideBarTables'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, where} from "firebase/firestore"
import '../pages.css'
import '../tabelas.css'

import ReactHTMLTableToExcel from "react-html-table-to-excel";

function FilteredProjects() {
  


  const [projects, setProjects]=useState([]);
  const [loading, setLoading]=useState(false);
  const [data1, setData1]=useState('')
  const [data2, setData2]=useState('')
    
    const handleClear2=()=>{
      setData1('')
      setData2('')
    }
  useEffect(()=>{
           
      const q = query(projectsCollectionRef,where ('projStatus1', 'in', ['Em Andamento', 'Em Análise', 'Em Pausa' , 'Entregue',  'Finalizado' ]),orderBy('projNumber', 'desc'));
      setLoading(true);
      const change = onSnapshot(q, snapshot=>{
        setProjects(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
          setLoading(false);
      })
   
      return()=>{change()}
   
   },[]);



  return (
    <div className='superContainer'>
        <SideBarTables/>
        <div className='pageHeader'>
            <div className="pageTitleLeft" style={{width:'100%'}}>
                <div className="pageTitle"><p>Tabela de Projetos</p>
            </div>
            <div className='pageListSearch' style={{width:'fit-content', justifyContent:'center', alignItems:'center'}}>
              <div>DE: <input type="date" value={data1}  onChange={(e)=>{setData1(e.target.value)}} /></div><div>ATÉ: <input type="date" value={data2}  onChange={(e)=>{setData2(e.target.value)}} /></div>
                    <div className='pageListSearcClear' onClick={handleClear2}><button>X</button></div>
            </div>
                <div className="pageTitle">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="seeClientG"
                    table="table-to-xls"
                    filename={`Tabela de Projetos de ${new Date(data1).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} ate ${new Date(data2).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}`}
                    sheet="Projetos"
                    buttonText="Exportar XLS"
                  />
               </div>
            </div>
        </div>
        <div className='pagesContent2'>
        {loading &&
        <div>Loading...</div>
        }
        {!loading &&
        <div className="tableContainer">
          <table className='GeneratedTable' id="table-to-xls">
            <thead>
              <th>Número</th>
              <th>Ano</th>
              <th>Nome</th>
              <th>Cliente</th>
              <th>Data Início</th>
              <th>Moeda de Cobrança</th>
              <th>BRL</th>
              <th>USD</th>
              
             
            </thead>
            <tbody>
              {projects.filter((val)=>{ if(data1 === '' || data2 === ''){
                      return val 
                  } else if (val.data.projStartDate >= data1 && val.data.projStartDate <= data2){
                      return val
                  }}).map((item, index)=>(
              <tr key={index}>
                <td>{item.data.projNumber}</td>
                <td>{new Date(item.data.projStartDate).getFullYear()}</td>
                <td>{item.data.projName}</td>
                <td>{item.data.projClient}</td>
                <td >{new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</td>
                <td>{item.data.projCurrency}</td>
                <td>{item.data.projCurrency === 'BRL'? item.data.projFinalValue: item.data.proj2Currency ==='BRL'? item.data.projCovertedValue: 0}</td>
                <td>{item.data.projCurrency === 'USD'? item.data.projFinalValue: item.data.proj2Currency ==='USD'? item.data.projCovertedValue: 0}</td>
                
                
                
              </tr>
              ))}
            </tbody>
          </table>
          </div>
          }
        </div>
    </div>
  )
}

export default FilteredProjects