import React,{useState, useEffect, useLayoutEffect} from 'react'
import SideBarProjects from '../../Components/SideBar/SideBarProjects'
import {proposalsCollectionRef, projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, doc, addDoc, deleteDoc} from "firebase/firestore"
import { db } from '../../libs/firebase';
import { useNavigate, Link } from 'react-router-dom';
import Open from '../../media/open.webp'



function ListProposal() {

   //abrindo acoredeon
   const [open, setopen]=useState(false)

  
   const handleOpen = (id)=>{
    setopen(id)
  }

  const hancleClose = ()=>{
    setopen(!open)
  }

  const [proposals, setProposals]=useState([]);
  const [loading, setLoading]=useState(false);
  const [search, setSearch]=useState('');
  const [serachFilter, setSerachFilter]=useState('Nome');

      //pegando infos propotas
      useEffect(()=>{
             
        const q = query(proposalsCollectionRef,
         orderBy('proposalDate', 'asc'));
        setLoading(true);
        const change = onSnapshot(q, snapshot=>{
          setProposals(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
            setLoading(false);
        })
     
        return()=>{change()}
     
     },[]);
     
     //limpando serch
      const handleClear=()=>{
        setSearch('')
      }

         // deletandoo doC
         const [openDelete, setOpenDelete]=useState(false)
         const [deleting, setDeleting]=useState(false)
         const handleOpenDelete=()=>{
             setOpenDelete(true)
         }
       
       const handleCloseDelete =()=>{
           setOpenDelete(false)
       }
       
       const handleDeletClient =async(e, id)=>{
           e.preventDefault()
           setDeleting(true)
           await deleteDoc(doc(db, 'proposals', id));
           setDeleting(false)
           
       }

       //numero projeto
       const [proejcts, setProjects]=useState([])
       const [projNumber, setProjNumber]=useState(0);
       useEffect(()=>{

        const q = query(projectsCollectionRef);
        setLoading(true);
        const changeProj = onSnapshot(q, snapshot=>{
          setProjects(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
      
        })
       
      
        return()=>{changeProj()}
      
      },[]);
      
      useLayoutEffect(()=>{
        setProjNumber(proejcts.length+4000)
      
      
      },[proejcts.length]);

       ////// transformando em projeto
       const navigate = useNavigate();
       const [uploading, setUploading] = useState(false);
      const [updated, setUpdated]=useState(false);
      const [erros, setErros]=useState(false);
      const [erro, setErro]=useState('');

       const [openProject, setOpenProject]=useState(false)
       const [projName, setProjName]=useState('')
       const [projClient, setProjClient]=useState('')
       const [projHiringCountry, setProjHiringCountry]=useState('')
       const [projJurisdictions, setProjJurisdictions]=useState([])
       const [projStartDate, setProjStartDate]=useState('');
       const [projIntermediateDate, setProjIntermediateDate]=useState('')
       const [projIntermediateStatus, setProjIntermediateStatus]=useState('')
       const [projDeadLine, setProjDeadLine]=useState('')
       const [projComments, setProjComments]=useState('')
       const [projClientID, setProjClientID]=useState('')
       const [projClientBill, setProjClientBill]=useState('')
       const [projClientBillInfo, setProjClientBillInfo]=useState('')
       const [projStatus1, setProjStatus1]=useState('')
       const [propId, setPropId]=useState('')

       
       const handleOpenProject =(e, id, name, country, cliente, comments, clienteId, clienteBill, clientebillInfo)=>{
        e.preventDefault()
        setProjName(name)
        setProjHiringCountry(country)
        setProjClient(cliente)
        setProjComments(comments)
        setProjClientID(clienteId)
        setProjClientBill(clienteBill)
        setProjClientBillInfo(clientebillInfo)
        setOpenProject(true)
        setPropId(id)
       }
       
       const closeProject=(e)=>{
        e.preventDefault()
        setOpenProject(false)
       }

       const handleAddProj =async(e, id)=>{
        e.preventDefault();
        setUploading(true);
        await addDoc( projectsCollectionRef, {
          projName,
          projNumber,
          projStartDate,
          projIntermediateDate,
          projIntermediateStatus,
          projDeadLine,
          projStatus1: 'Em Andamento',
          projClient,
          projClientID,
          projClientBill,
          projClientBillInfo,
          projHiringCountry,
          projJurisdictions,
          projComments,
          projDeadLineAlert:false,
          projDeadLineAlert2:false,
          projIntStatus:false,
          projLideres:[],
          projAnalistas:[]
          }
          );
    
      
        
        setUploading(false);
        navigate('/')
        await deleteDoc(doc(db, 'proposals', id));
      }


  return (
    <div className='superContainer'>
        <SideBarProjects/>
        <div className='pageHeader'>
                <div className="pageTitleLeft">
                    <div className="pageTitle">PROPOSTAS</div>
                </div>
                <div className='pageListSearch'>
    
                  <div className='pageListSearchAelect'>
                      FIltro: 
                      <select  onChange={(e)=>{setSerachFilter(e.target.value)}} >
                        <option value="Nome">Nome</option>
                        <option value="Cliente">Cliente</option>
                        <option value="País de Contratação">País de Contratação</option>
                        <option value="Cometários">Cometários</option>
                      </select>
                  </div>
                  <div className='pageListSearcInput' ><input type="text"  placeholder={`Buca por ${serachFilter}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} /></div>
                  <div className='pageListSearcClear' onClick={handleClear}><button>X</button></div>
                </div>
        </div>
        <div className='pagesContent'>
        {loading &&
            <div className="loading">
                <div className='pageListCLients'>Loading....</div>
            </div>
            
            }
            {proposals.filter((val)=>{
                       if(serachFilter === 'Nome'){
                            if(search === ''){
                              return val
                          } else if (val.data.proposalName.toLowerCase().includes(search.toLowerCase())){
                              return val
                          }
                        }
                        if(serachFilter === 'País de Contratação'){
                              if(search === ''){
                                return val
                            } else if (val.data.propoalHiringCountry.toLowerCase().includes(search.toLowerCase())){
                                return val
                            }
                        }
                        if(serachFilter === 'Cliente'){
                          if(search === ''){
                            return val
                        } else if (val.data.proposalClient.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'Comentários'){
                          if(search === ''){
                            return val
                        } else if (val.data.proposalComments.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }

                    }).map((item, index)=>(
            <div className='pageListCLients' key={index} >
                <div className='pageListCLientsA'>
                    <div className="clientFlex2">
                      <span>Nome:</span>
                      <p>{item.data.proposalName}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>País:</span>
                      <p>{item.data.propoalHiringCountry}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Cliente:</span>
                      <p>{item.data.proposalClient}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Data Cadastramento:</span>
                      <p>{new Date(item.data.proposalStartDate).toLocaleDateString('pt-br',{timeZone: 'UTC'}).slice(0,10)}</p>
                    </div>
                    
                    <div className='openClientContent'>
                      {open === item.id? 
                      <button className='closeClient' onClick={hancleClose}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                      :
                      <button className='openClient' onClick={()=>(handleOpen(item.id))}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                      }
                    </div>
                </div>
                <div className={open === item.id? 'pageListCLientsB listActive' : 'pageListCLientsB' }>
                   
                  <div className='pageListCLientsBLinhas'>
                    <div className='clientFlex1'>
                          <div className='clientFlexTitle'>Comentários</div>
                          <div className='clientFlexBox' style={{height:'100%'}}>
                            <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.proposalComments}</pre>
                          </div>
                    </div>
                  </div>
                  <Link to={"/projetos/proposta/"+item.id} className='seeClient'>VER Proposta</Link>
                  <button className='editValues' onClick={(e)=>{handleOpenProject(e, item.id, item.data.proposalName, item.data.propoalHiringCountry, item.data.proposalClient, item.data.proposalComments, item.data.proposalClientID, item.data.propoalClientBill, item.data.proposalClientBillInfo )}} >TRANSFORMAR EM PROJETO</button> 
    
                  <button className='editValues' onClick={handleOpenDelete}>APAGAR PROPOSTA</button>
                          {openDelete &&
                          <div className="popUpDelete">
                          <div className="deleteAviso">
                              <div>Você realmente quer apagar esse Proposta?</div>
                              <div className='deleteButtons'>
                                  <button onClick={handleCloseDelete} className='deleteNao'>❌ Não</button>
                                  <button onClick={(e)=>{handleDeletClient(e, item.id)}} className='deleteSim'>✔️ Sim</button>
                              </div>
                              {deleting &&
                              <div>Apagando Proposta</div>
                              }
                            </div>
                          
                        </div>
                          }                 
                </div>
                
              
            </div>
            ))}
        </div>
        {openProject &&
                <div className="popUp2">
                <form className="form900" onSubmit={(e)=>{handleAddProj(e, propId)}} >
                <div className="linhas">
                        <div className="projFit">
                          <p>Nº Projeto:</p>
                          <span>{projNumber}</span>
                      </div>
                      <div className='clientName'>
                          <p>Nome:</p>
                          <input type="text" onChange={(e)=>{setProjName(e.target.value)}} defaultValue={projName}/>
                      </div>
                      <div className="clientName">
                        <span>Cliente:</span>
                      <p>{projClient}</p>

                    </div>
                  </div>
                  <div className="linhas">
                    <div className="clientName">
                      <span>País de Contratação: </span>
                      <p>{projHiringCountry}</p>
        
                    </div>
                      <div className="clientName">
                      <p>Jurisdições:</p>
                      <input type="text" required   onChange={(e)=>{setProjJurisdictions(e.target.value.split(','))}} placeholder='Juridisções'/>
                    </div>
                </div>
                
                <div className="linhas">
                  <div className="projF1">
                    <p>Data Inicio:</p>
                    <input type="date" required  onChange={(e)=>{setProjStartDate(e.target.value)}} />
                  </div>
                  <div className="projF1">
                    <p>Data Entrega:</p>
                    <input type="date" required onChange={(e)=>{setProjDeadLine(e.target.value)}} />
                  </div>
                </div>
                <div className="linhas">
                  <div className="projF1">
                    <p>Data Intermediária:</p>
                    <input type="date" required={projIntermediateStatus==='Sem'? false:true}  onChange={(e)=>{setProjIntermediateDate(e.target.value)}} />
                  </div>
                  <div className="projF1">
                    <p>Status Intermediária:{projIntermediateStatus}</p>
                    <select required onChange={(e)=>{setProjIntermediateStatus(e.target.value)}}>
                    <option value="">Selecione</option>
                  <option value="Sem">Sem Entrega Intermediária</option>
                  <option value="Interna">Interna</option>
                  <option value="Externa">Externa</option>
                    </select>
                  </div>
                </div>
                <div className="linhas">
                  <div className="projF1" style={{width:'100%'}}>
                    <p>Comentários:</p>
                    <textarea id="" rows="6" style={{width:'100%'}} defaultValue={projComments} required onChange={(e)=>{setProjComments(e.target.value)}}></textarea>
                  </div>
                </div>
                  <input className='editValues'  type="submit" value="TRANFORMAR EM PROJETO" />
                  <button className='editValues' onClick={(e)=>{closeProject(e)}}>FECHAR</button>
            <div className="linhas">
              {uploading &&
                <span>uploading...</span>
              }
              {updated &&
                <span>O projeto foi atualizado com sucesso!</span>
              }
              {erros &&
                <span className='Erro'> Erro:{erro}<br/> tente novmente mais tarde</span>
              }
            </div>
      </form>
    
    </div>
  }
        
    </div>

  )
}

export default ListProposal