import React from 'react'
import './sidebar.css'
import TopLogo from '../Parcial/TopLogo'
import Clock from '../Parcial/Clock'
import Logout from '../Parcial/Logout'
import Navigtion from '../Parcial/Navigtion'
import Create from '../Parcial/Create'
import SibeBarCollabMob from '../Parcial/SibeBarCollabMob'
import Utils from '../Parcial/Utils'

function SideBarCollabs() {
  return (
    <>
    <div className='sideBar'>
      <div className="sideBar2">
        <TopLogo />
        <Navigtion />
        <Create />
        <Utils />
        <Clock />
        <Logout />
      </div>
    </div>
    <div className="sideBarMob">
      <SibeBarCollabMob />
    </div>
    </>
  )
}

export default SideBarCollabs