import { db } from "../libs/firebase"
import { collection} from "firebase/firestore"


export const projectsCollectionRef = collection(db, 'projects');

export const clientsCollectionRef = collection(db, 'clients');

export const collaboratorsCollectionRef = collection(db, 'collaborators');

export const ratesCollectionRef = collection(db, 'rates');

export const proposalsCollectionRef = collection(db, 'proposals')

export const prospectsCollectionRef = collection(db, 'prospects')



