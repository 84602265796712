import React, {useState, useEffect, useLayoutEffect} from 'react'
import {projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, query,where} from "firebase/firestore"
import '../pages.css'
import SideBarProjects from '../../Components/SideBar/SideBarProjects'
import { Link } from 'react-router-dom';

function AnalistasAPagar() {
    const [projects, setProjects]=useState([]);
    const [loading, setLoading]=useState(false);
    const [data1, setData1]=useState('')
    const [data2, setData2]=useState('')
    const [analistas, setAnalistas]=useState([])
    const [analistasP, setAnalistasP]=useState([])

    const handleClear2=()=>{
        setData1('')
        setData2('')
      }


    useEffect(()=>{
       
        const q = query(projectsCollectionRef,where('projStatus1', '!=', 'deletado'));
        setLoading(true);
        const change = onSnapshot(q, snapshot=>{
          setProjects(snapshot.docs.map(doc=>({
            data:doc.data(),
            id:doc.id
            })))
            setLoading(false);
        })
     
        return()=>{change()}
     
     },[]);
     useLayoutEffect(()=>{
        setLoading(true)
        let analistasAndamento =  projects.filter((val)=>{if(val.data.projAnalistas? val.data.projAnalistas:''){return val}}).map((i)=>i.data.projAnalistas.map((i)=>i.name))
            let analistasNomes = analistasAndamento.reduce((list, sub) => list.concat(sub), [])
          
            let analistasemDuplicados = analistasNomes.filter((v1, i1, s1) => {
              return s1.indexOf(v1) === i1;
            });
          
            let filtrarProjetos=(nome)=>{
                
              let listadeprojetos = projects.filter((val)=>{if(val.data.projAnalistas.map((item)=>item.name).includes(nome) && val.data.projAnalistas.map((i)=>i.paymentStatus).includes(false)){return val}})
    
                
                
                let filtrado = listadeprojetos.map((i)=>(
                  {
                    "numero": i.data.projNumber,
                    "projeto":i.data.projName,
                    "link":i.id,
                    "status":i.data.projStatus1,
                    "status2":i.data.projeStatus3,
                    "data":i.data.projStartDate,
                    "dataPagamneto":i.data.projPaymentDate,
                    "valor":i.data.projAnalistas.filter((val)=>{if(val.name.includes(nome)){return val}}).map((item)=>item.payment),
                    "moeda":i.data.projAnalistas.filter((val)=>{if(val.name.includes(nome)){return val}}).map((item)=>item.paymentCurrency),
                    "pagamento":i.data.projAnalistas.filter((val)=>{if(val.name.includes(nome)){return val}}).map((item)=>item.paymentStatus)
                  }
                ))
    
                let arrumados = filtrado.sort((e,f)=>{
                  if (e.numero > f.numero) {
                    return 1;
                  }
                  if (e.numero < f.numero) {
                    return -1;
                  }
                  return 0;
                  })
                
                return arrumados
            }
            
            setAnalistasP(
              analistasemDuplicados.map((item, index)=>(
            {
              "id": index,
              "nome":item,
            "dados":filtrarProjetos(item)
          
          }
          ))
          )
    
          setAnalistas(analistasP.sort((e,f)=>{
            if (e.nome > f.nome) {
              return 1;
            }
            if (e.nome < f.nome) {
              return -1;
            }
            return 0;
            }))
          setLoading(false)
    
       },[projects])

       const handleAnalistas =()=>{
        setLoading(true)
        let analistasAndamento =  projects.filter((val)=>{if(val.data.projAnalistas? val.data.projAnalistas:''){return val}}).map((i)=>i.data.projAnalistas.map((i)=>i.name))
            let analistasNomes = analistasAndamento.reduce((list, sub) => list.concat(sub), [])
          
            let analistasemDuplicados = analistasNomes.filter((v1, i1, s1) => {
              return s1.indexOf(v1) === i1;
            });
          
            let filtrarProjetos=(nome)=>{
                
                let listadeprojetos = projects.filter((val)=>{if(val.data.projAnalistas.map((item)=>item.name).includes(nome) && val.data.projAnalistas.map((i)=>i.paymentStatus).includes(false)){return val}})
    
                
                
                let filtrado = listadeprojetos.map((i)=>(
                  
                  {
                    "numero": i.data.projNumber,
                    "projeto":i.data.projName,
                    "link":i.id,
                    "status":i.data.projStatus1,
                    "status2":i.data.projeStatus3,
                    "data":i.data.projStartDate,
                    "dataPagamneto":i.data.projPaymentDate,
                    "valor":i.data.projAnalistas.filter((val)=>{if(val.name.includes(nome)){return val}}).map((item)=>item.payment),
                    "moeda":i.data.projAnalistas.filter((val)=>{if(val.name.includes(nome)){return val}}).map((item)=>item.paymentCurrency),
                      "pagamento":i.data.projAnalistas.filter((val)=>{if(val.name.includes(nome)){return val}}).map((item)=>item.paymentStatus)
                  }
                ))
    
                let arrumados = filtrado.sort((e,f)=>{
                  if (e.numero > f.numero) {
                    return 1;
                  }
                  if (e.numero < f.numero) {
                    return -1;
                  }
                  return 0;
                  })
                
                return arrumados
            }
            
            setAnalistasP(
              analistasemDuplicados.map((item, index)=>(
            {
              "id": index,
              "nome":item,
            "dados":filtrarProjetos(item)
          
          }
          ))
          )
    
          setAnalistas(analistasP.sort((e,f)=>{
            if (e.nome > f.nome) {
              return 1;
            }
            if (e.nome < f.nome) {
              return -1;
            }
            return 0;
            }))
          setLoading(false)
       }
  return (
    <div className='superContainer'>
        <SideBarProjects/>
        <div className='pageHeader'>
            <div className="pageTitleLeft" style={{width:'100%'}}>
                <div className="pageTitle">ANALISTAS A PAGAR</div>
                {!loading &&
            <div className="pageTitle">
            
                 <button className="seeClient" onClick={handleAnalistas}>Gerar Listagem</button>
            
            </div>
            }
            <div className='pageTitle' style={{width:'fit-content', justifyContent:'center', alignItems:'center'}}>
              <div>DE: <input type="date" value={data1}  onChange={(e)=>{setData1(e.target.value)}} /></div><div>ATÉ: <input type="date" value={data2}  onChange={(e)=>{setData2(e.target.value)}} /></div>
                    <div className='pageListSearcClear' onClick={handleClear2}><button>X</button></div>
            </div>
            </div>
            

            
        
        </div>
        <div className='pagesContent'>
                {loading &&
                <div className="loading">
                    <div className='pageListProjects'>Loading....</div>
                </div>
                
                }
                {!loading &&
                    analistas.map((item,index)=>(
                    <>
                    {item.dados.length > 0 ?
                    <div className='pageListProjects' key={index}>
                            <div className='pageListCLientsA'>
                                <div className="clientFlex1" style={{overflow:'hidden'}}>
                                    <span>Nome:</span>
                                    <p style={{whiteSpace:'nowrap'}}>{item.nome}</p>
                                </div>
                               
                            </div>
                            <div className={'pageListCLientsB listActive' }>
                                {item.dados.filter((val)=>{ if(data1 === '' || data2 === ''){
                                        return val 
                                    } else if (val.dataPagamneto >= data1 && val.dataPagamneto <= data2){
                                        return val
                                    }}).map((i)=>(
                                      i.pagamento.includes(false)? 
                                        <div className='pageListCLientsBLinhas'>
                                           
                                           
                                            <div className="clientFit">
                                                <span>Numero:</span>
                                                <p style={{textAlign:'center', width:'100%'}}>{i.numero}</p>
                                            </div>
                                            <div className="clientFlex1" style={{overflow:'hidden'}}>
                                                <span>Nome Projeto:</span>
                                                <p >{i.projeto}</p>
                                            </div>
                                            <div className="clientFlex1" style={{overflow:'hidden'}}>
                                                <span style={{whiteSpace:'nowrap'}}>Status Projeto:</span>
                                                <p style={{whiteSpace:'nowrap'}} >{i.status}</p>
                                            </div>
                                            <div className="clientFlex1"  style={{overflow:'hidden'}} >
                                                <span style={{whiteSpace:'nowrap'}}>Previsão Pagamento:</span>
                                                <p style={{whiteSpace:'nowrap'}}>{i.dataPagamneto? new Date(i.dataPagamneto).toLocaleString('pt-BR').slice(0, 10): 'Aguardando Entrega'}</p>
                                            </div>
                                            <div className="clientFit">
                                                <span>Moeda:</span>
                                                <p style={{textAlign:'center', width:'100%'}}>{i.moeda[0]}</p>
                                            </div>
                                            <div className="clientFlex1"  style={{overflow:'hidden'}} >
                                                <span style={{whiteSpace:'nowrap'}}>Valor:</span>
                                                <p style={{whiteSpace:'nowrap'}}>{Math.round(i.valor.reduce((accumulator,value) => accumulator + value,0))}</p>
                                            </div>
                                            <div className="clientFlex1"  style={{overflow:'hidden'}} >
                                                <span style={{whiteSpace:'nowrap'}}>Pagar:</span>
                                                <p style={{whiteSpace:'nowrap'}}><Link target="_blank"  to={"/projeto/pagamento/"+i.link} >PAGAR PROJETO</Link></p>
                                            </div>
                                          
                                          
                                        </div>
                                        
                                        
                               :"" ))
                                    
                                }
                                
                            </div>
                        </div>
                        : ""}
                        </>
                        
                    ))
                }
        </div>
    </div>
  )
}

export default AnalistasAPagar